import React, { Fragment, useEffect, useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import TextWinds from '../../../components/common/TextWinds';
import { LmsInfoTypes, LmsVoucherProps } from '../../../constants/types';
import TodoTicketStatus from './TodoTicketStatus';
import instance from '../../../api/axios/utils.ts/instance';
import { useAppDispatch } from '../../../store/hook';
import { openAlert, openModal } from '../../../store/features/modalSlice';
import ModalExtendLink from '../../../components/modal/ModalExtendLink';
import {
  setExtendBtnLink,
  setPurchaseTicketLink,
} from '../../../api/utils/lms/ExtendSetBtnLink';
import ModalAlert from '../../../components/modal/ModalAlert';
import DashboardCard, { DashboardCardItem } from './DashboardCard';
import HFbutton from '../../../components/common/input/HFbutton';
import {
  changeTimestamp,
  handleCopyClipBoard,
} from '../../../api/utils/util-func';

interface contentsProps {
  classId: string;
  lmsLists: LmsInfoTypes;
}
const HeaderContents = ({ classId, lmsLists }: contentsProps) => {
  const dispatch = useAppDispatch();

  const extendButtonHandler = ({ id, type }: { id: string; type: string }) => {
    const { checkType, purchaseLink } = setExtendBtnLink(id);
    dispatch(
      openModal({
        body: (
          <ModalExtendLink
            checkType={checkType}
            type={type}
            message="클래스 연장하기"
            purchaseLInk={purchaseLink}
          />
        ),
      })
    );
  };

  const purchaseTicket = (data: any) => {
    const targetUrl = setPurchaseTicketLink(data.type);
    if ('' !== targetUrl) {
      window.open(targetUrl, '_blank');
    } else {
      dispatch(
        openModal({
          body: (
            <ModalAlert message="이용권 구매를 할 수 없습니다. 관리자에게 문의하세요." />
          ),
        })
      );
    }
  };

  const [voucherLists, setVoucherLists] =
    useState<LmsVoucherProps[]>(undefined);
  useEffect(() => {
    if (classId === lmsLists.id) {
      const fetchTicketStatus = async () => {
        try {
          await instance
            .get(`/v1/lms/${classId}/todo/voucher`, {
              params: { courseId: lmsLists.course?.id },
            })
            .then((res) => {
              if (200 === res.status) {
                setVoucherLists(res.data.data);
              }
            });
        } catch (error) {
          setVoucherLists(undefined);
        }
      };
      fetchTicketStatus();
    }
  }, [lmsLists]);

  return (
    <Fragment>
      <div className="py-3 bg-gray1 xl:hidden">
        <TextWinds type="title_h3" className="mb-[2px]">
          {lmsLists.course?.name}
        </TextWinds>
        <TextWinds type="content_body4">
          {lmsLists && new Date(lmsLists.created_at).toLocaleDateString()} 개설
        </TextWinds>
      </div>
      <div className="my-[17px] px-3 py-[14px] bg-purple0 rounded xl:hidden">
        <TextWinds type="caption1" color="purple4">
          본 LMS는 PC에서 이용을 권장합니다. 다양한 LMS 기능들을 모바일 버전보다
          훨씬 편리하게 이용할 수 있어요.
        </TextWinds>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <DashboardCard title="LMS 이용 메뉴얼">
          <div
            className="flex items-center w-full mt-5 overflow-hidden cursor-pointer"
            onClick={() => {
              window.open(
                'https://jebs-notice.oopy.io/efc91373-7886-423c-8a68-76ee7de7d3de',
                '_blank'
              );
            }}
          >
            <img
              className="m-auto w-1/2 object-cover"
              src={process.env.PUBLIC_URL + '/images/lms/lms_manual.jpg'}
              alt="Calendar"
            />
          </div>
        </DashboardCard>

        {voucherLists ? (
          <DashboardCard title="할 일 이용권 현황">
            <TodoTicketStatus
              voucherLists={voucherLists}
              purchaseTicket={purchaseTicket}
            />
          </DashboardCard>
        ) : (
          <div></div>
        )}

        <DashboardCard title="코스 관리">
          <div className="h-[100px]">
            <DashboardCardItem title="코스명">
              {lmsLists?.course?.name}
            </DashboardCardItem>
            <DashboardCardItem title="클래스 코드명">
              <div className="text-red5">{classId}</div>
            </DashboardCardItem>
          </div>
          <HFbutton
            height={38}
            type="Outlined"
            onClick={() => {
              handleCopyClipBoard(classId).then((result) => {
                if (result) {
                  dispatch(
                    openAlert({ message: '클래스 코드가 복사되었습니다.' })
                  );
                }
              });
            }}
          >
            <div className="text-xs">코드 복사하기</div>
          </HFbutton>
          <div className="flex gap-2 mt-3 bg-gray1 p-2 rounded">
            <InformationCircleIcon className="h-5 w-5 text-gray6 shrink-0" />
            <div className="text-xs">
              ‘클래스 코드’란 선생님이 개설한 클래스의 고유 코드로, 선생님의
              클래스에 속하기 위해서는 클래스 코드가 학생 계정에 반드시
              기재되어야 합니다. 소속 클래스가 변경 되었다면 담당 선생님에게
              클래스 코드 문의 후 학생 계정에 입력/수정해 주세요. 클래스 코드는
              총 3개까지 입력 가능합니다.
            </div>
          </div>
        </DashboardCard>

        <DashboardCard title="연장 관리">
          <div className="h-[100px]">
            <DashboardCardItem title="이용 잔여일">
              <div className="text-red5">
                {Number(lmsLists.remain_days) > 0
                  ? `${lmsLists.remain_days}일 (${changeTimestamp(
                      lmsLists.end_date
                    )} 까지)`
                  : `${lmsLists.remain_days}일`}
              </div>
            </DashboardCardItem>
          </div>

          <HFbutton
            height={38}
            type="Outlined"
            onClick={() => extendButtonHandler(lmsLists.course)}
          >
            <div className="text-xs">이용 기간 연장하기</div>
          </HFbutton>
          <div className="flex gap-2 mt-3 bg-gray1 p-2 rounded">
            <InformationCircleIcon className="h-5 w-5 text-gray6 shrink-0" />
            <div className="text-xs">
              교사의 코스 이용 기간이 활성화되어 있을 때만 수업 운영 및 교재
              구매가 가능합니다. 이용 기간을 연장하여 클래스를 운영해 주세요.
            </div>
          </div>
        </DashboardCard>

        {/* <Fragment>
          <div className="pt-4 pb-6 shadow-card rounded-lg bg-white px-[32px] bg-[#FF373F40] overflow-x-auto">
            <div className="flex gap-1 justify-center text-center mb-4 pb-[18px] border-b border-gray9">
              <img src="/svg/icon/alert-triangle.svg"/><TextWinds type="title_h3">기존 학생 정보 가져오기</TextWinds>
            </div>
            <div className="flex w-fit m-auto pb-[3px] items-center justify-stretch text-sm text-center text-nowrap">
              jebs2.kr (구)사이트에서 학생 관리 기능을 이용하셨던 선생님을 위한 기능입니다.<br/>
              학생을 새롭게 등록하여 이용하고 싶으신 경우, 가져오지 않으셔도 됩니다.<br/>
              (가져오기 기능은 한시적으로만 운영됩니다.)
            </div>
            <div className="w-full max-w-[200px] py-3 border border-red5 rounded bg-white text-center text-red5 font-bold mx-auto mt-4 hover:bg-gray2 cursor-pointer">
              학생 정보 가져오기
            </div>
          </div>        
        </Fragment> */}
        {/* <CustomDialog
          open={true}
          handleClose={handleCloseDialog}
          content={dialogText}
          confirmText="확인"
          onConfirm={handleConfirmDialog}
        /> */}
      </div>
    </Fragment>
  );
};

export default HeaderContents;
