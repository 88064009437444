import { PhotoIcon } from '@heroicons/react/24/outline';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
} from '@material-tailwind/react';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import TextWinds from '../common/TextWinds';
import { CourseInfoType } from '../../constants/types';
import { ProductSubType } from '../../enum';

interface CourseCardProps {
  data: CourseInfoType;
  isMain?: boolean;
}
const CourseCard = ({ data, isMain }: CourseCardProps) => {
  const subType =
    data.product_sub_type === ProductSubType.HOMESCHOOL
      ? 'homeschool'
      : data.product_sub_type === ProductSubType.WORSHIP
      ? 'churchschool'
      : 'englishconversation';

  const getOptionVisible = () => {
    switch (data.product_sub_type) {
      case ProductSubType.HOMESCHOOL:
      case ProductSubType.WORSHIP:
      case ProductSubType.LEARN_ON_PHONE:
        return false;
      case ProductSubType.SMALL_GROUP:
        return true;
      default:
        return false;
    }
  };

  return (
    <Link
      to={`/courses/${subType}/details/${data.id}?option=${getOptionVisible()}`}
    >
      <Card
        className="w-full h-full shrink-0 xl:w-[270px] m-auto"
        // key={key}
      >
        <Button className="bg-white h-full p-0 flex flex-col">
          <CardHeader className="flex items-center justify-center w-full p-0 mx-0 my-0 bg-gray-100 rounded-sm shadow-none aspect-[210/120]">
            {/* <PhotoIcon className="w-[32px] h-[32px]" /> */}
            {data.thumbnail_url ? (
              <img
                className="w-full h-full rounded-t-lg object-contain"
                src={data.thumbnail_url}
                alt={data.name}
              />
            ) : (
              <PhotoIcon className="w-[32px] h-[32px]" />
            )}
          </CardHeader>
          <CardBody className="px-[10px] py-[16px] w-full">
            <TextWinds type="content_body4" className="font-semibold">
              {data.name}
            </TextWinds>
            <TextWinds type="content_body4" color="gray7" className="pt-[8px]">
              {data.description}
            </TextWinds>
          </CardBody>
        </Button>
      </Card>
    </Link>
  );
};

export default CourseCard;
