import React, { useEffect, useState } from 'react';
import { assignedLmsTodosByTodo } from '../../../constants/types/lms-type';
import { Badge, Table } from 'antd';
import { getTodoStateDetailByTodo } from '../../../api/utils/lms/lmsApi';
import { ColumnsType } from 'antd/es/table';

const columsRECITATION: ColumnsType<assignedLmsTodosByTodo> = [
  {
    title: '응시결과',
    render: (_, row) => getPassBadge(row),
    align: 'center',
  },
  {
    title: '진행현황',
    render: (_, row) => getProgressStateBadge(row.progress.progressState),
    align: 'center',
  },
];

const columsBasic: ColumnsType<assignedLmsTodosByTodo> = [
  {
    title: '진행률',
    render: (_, row) => row.progress.progress + '%',
    align: 'center',
  },
  {
    title: '상태',
    render: (_, row) => getProgressStateBadge(row.progress.progressState),
    align: 'center',
  },
];
const getColumns = (subType: string) => {
  return subType.toUpperCase() === 'RECITATION'
    ? columsRECITATION
    : columsBasic;
};

const columns = ({
  subType,
}: {
  subType: string;
}): ColumnsType<assignedLmsTodosByTodo> => [
  {
    title: 'No.',
    render: (_, __, index) => index + 1,
    align: 'center',
  },
  {
    title: '이름',
    render: (_, row) => row.student.name,
    align: 'center',
  },
  {
    title: '아이디',
    render: (_, row) => row.student.uid,
    align: 'center',
  },
  ...getColumns(subType),
];

const getProgressStateBadge = (progressState: string) => {
  switch (progressState) {
    case 'NOT_STARTED':
      return <Badge status="error" text="진행 전" />;
      {
        /* <Badge status="error" text="진행 전" />; */
        /* <Badge status="processing" text="진행 중" />; */
        /* <Badge status="success" text="마감" />; */
        /* <Badge status="warning" text="진행 전" /> */
      }

    case 'IN_PROGRESS':
      return <Badge status="processing" text="진행 중" />;
    case 'CLOSED':
    case 'COMPLETED':
      return <Badge status="success" text="마감" />;
    default:
      return;
  }
};

const getPassBadge = (data: assignedLmsTodosByTodo) => {
  let result: 'NOT_STARTED' | 'FAIL' | 'IN_PROGRESS' | 'PASS' = 'NOT_STARTED';
  let date = '';

  if (data.progress.completedDate) {
    result = 'PASS';
    date =
      '합격 : ' + new Date(data.progress.completedDate).toLocaleDateString();
  } else if (data.progress.failDate) {
    result = 'FAIL';
    date = '불합격 : ' + new Date(data.progress.failDate).toLocaleDateString();
  } else {
    result = 'NOT_STARTED';
    date = '미응시';
  }

  const getState = (result: string) => {
    switch (result) {
      case 'PASS':
        return 'success';
      case 'FAIL':
        return 'error';
      case 'IN_PROGRESS':
        return 'processing';
      case 'NOT_STARTED':
        return 'default';
      default:
        return 'warning';
    }
  };

  return <Badge status={getState(result)} text={date} />;
};

interface TodoExpandedRowByTodoProps {
  classId: string;
  params: {
    toDoType: string;
    toDoStatus: string;
    category: 'BY_TODO';
  };
  txId: string;
  subType: string;
}
const TodoExpandedRowByTodo = ({
  classId,
  params,
  txId,
  subType,
}: TodoExpandedRowByTodoProps) => {
  const [data, setData] = useState<assignedLmsTodosByTodo[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getTodoStateDetailByTodo(
      classId,
      params.toDoType,
      params.toDoStatus,
      txId
    ).then((data) => {
      setData(data.data);
      setLoading(false);
    });
  }, [classId, params.toDoType, params.toDoStatus, txId]);

  return (
    <Table
      dataSource={data}
      columns={columns({ subType })}
      size="small"
      rowKey={(row) => row.progress.id}
      pagination={false}
      loading={loading}
    />
  );
};

export default TodoExpandedRowByTodo;
