import { XMarkIcon } from '@heroicons/react/24/outline';
import { IconButton } from '@material-tailwind/react';
import React, { Fragment, useState } from 'react';
import TextWinds from '../../../../components/common/TextWinds';
import Buttons from '../../../../components/common/Buttons';
import ClassAssignList from './classAssignment/ClassAssignList';
import { classGroupType } from '../../../../constants/types/student-type';

interface ClassAssignmentProps {
  closeDrawerBottom: () => void;
  action: (selected: classGroupType | undefined) => void;
}
const ClassAssignment = ({
  closeDrawerBottom,
  action,
}: ClassAssignmentProps) => {
  const [selected, setSelected] = useState<classGroupType>();
  return (
    <Fragment>
      <div className="flex flex-col gap-2.5">
        <section>
          <div className="flex flex-row items-center justify-between py-2.5 px-0">
            <TextWinds type={'title_h3'}>반 배정하기</TextWinds>
            <IconButton
              variant="text"
              color="blue-gray"
              onClick={closeDrawerBottom}
            >
              <XMarkIcon className="w-[30px] h-[30px]" />
            </IconButton>
          </div>
        </section>

        <div className="md:w-full h-0 shrink-0 border-[1px] border-solid border-gray4"></div>

        <ClassAssignList setSelected={setSelected} />

        <section>
          <div className="flex flex-col items-start justify-start gap-[4px] p-[12px] bg-purple0 rounded-[4px]">
            <TextWinds type={'content_body4'} color="purple5">
              반 기능 안내
            </TextWinds>
            <ul className="list-inside list-disc pl-[8px]">
              <TextWinds type={'content_body4'} color="purple5">
                <li>등록된 학생들을 특정 반에 배정할 수 있습니다.</li>
                <li>
                  할 일(과제) 등록 및 관리 시 개별 학생이 아닌, 반 단위로 편하게
                  관리 가능합니다.
                </li>
                <li>반 기능은 수업 운영을 위한 필수 기능은 아닙니다.</li>
              </TextWinds>
            </ul>
          </div>
        </section>

        <section>
          <Buttons
            type={'filled'}
            color={'purple5'}
            onClick={() => {
              action(selected);
            }}
          >
            <TextWinds type={'button'} color="white">
              완료
            </TextWinds>
          </Buttons>
        </section>
      </div>
    </Fragment>
  );
};

export default ClassAssignment;
