import React, { useContext, useState } from 'react';
import { SignupContext } from './SignupContext';
import { Button, Input, Select, Option } from '@material-tailwind/react';
import { phoneFormat } from '../../../api/utils/format/numbers';
import TextWinds from '../../../components/common/TextWinds';
import Buttons from '../../../components/common/Buttons';
import { useAppDispatch } from '../../../store/hook';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { User } from '../../../constants/types';
import { UserSnsType } from '../../../enum';
import { studentGuardianType } from '../../../constants/types/student-type';
import { Controller, useForm } from 'react-hook-form';
import ErrorInput from '../../student/components/ErrorInput';
import instance from '../../../api/axios/utils.ts/instance';
import { openAlert } from '../../../store/features/modalSlice';
type UserDTO = Pick<
  User,
  'uid' | 'email' | 'password' | 'name' | 'phone' | 'birthday' | 'gender'
> & {
  password2: string;
  snsType: UserSnsType;
  snsCode?: string;
  countryCode: string;
  agreementServiceUsage: boolean;
  agreementPersonalInfo: boolean;
  agreementLocationalInfo: boolean;
  guardianInfo: studentGuardianType;
};
const InfoForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { phone, step, setStep } = useContext(SignupContext);
  const [checkDuplicate, setCheckDuplicate] = useState<boolean>(false);
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<UserDTO>();
  const password = watch('password');
  const uid = watch('uid');

  const sns_code = searchParams.get('sns_code');
  const sns_type = searchParams.get('sns_type');

  const onSubmit = (data: UserDTO) => {
    if (!checkDuplicate) {
      dispatch(openAlert({ message: '아이디 중복확인을 해주세요.' }));
      return;
    }
    const packet: UserDTO = {
      ...data,
      countryCode: '82',
      phone: phone,
      agreementLocationalInfo: true,
      agreementPersonalInfo: true,
      agreementServiceUsage: true,
    };

    if (sns_code && sns_type) {
      packet.snsCode = sns_code;
      packet.snsType = sns_type as UserSnsType;
    } else {
      packet.snsType = UserSnsType.DEFAULT;
    }

    postSignUp(packet);
  };

  const postSignUp = async (data: UserDTO) => {
    try {
      const response = await instance.post('/v1/auth/sign-up', data);
      const result = response.data;
      if (result.statusCode === 200) {
        // dispatch(openAlert({ message: '회원가입이 완료되었습니다.' }));
        setStep(step + 1);
      } else {
        dispatch(openAlert({ message: result.message }));
      }
    } catch (error) {}
  };

  // 아이디 중복확인
  const getIdCheck = async () => {
    try {
      const response = await instance.get('/v1/user/uid-check', {
        params: {
          uid: uid,
        },
      });
      const data = response.data;
      if (data.statusCode === 200) {
        if (data.data.existUser === false) {
          setCheckDuplicate(true);
          dispatch(openAlert({ message: '사용 가능한 아이디입니다.' }));
        } else {
          setCheckDuplicate(false);
          dispatch(openAlert({ message: '이미 사용중인 아이디입니다.' }));
        }
      } else {
      }
    } catch (error) {}
  };

  return (
    <form className="flex flex-col gap-4">
      <section>
        <TextWinds type="title_h2">가입 정보 입력</TextWinds>
      </section>
      <section className="flex flex-col gap-2">
        <TextWinds type="caption1" children="전화번호" />
        <Input label="전화번호" value={phoneFormat(phone)} disabled />

        <div className="relative flex w-full">
          <Input
            type="text"
            label="아이디"
            containerProps={{
              className: `min-w-0`,
            }}
            className={`pr-20`}
            error={!!errors.uid}
            name="uid"
            {...register('uid', {
              required: '아이디를 입력해주세요.',
            })}
          />
          <Button
            size="sm"
            className="!absolute right-1 top-1 rounded bg-purple5"
            onClick={() => getIdCheck()}
          >
            중복확인
          </Button>
        </div>
        {errors.uid && <ErrorInput text={errors.uid.message} />}

        <Input
          type="text"
          label="e-mail"
          className="pr-20"
          error={!!errors.email}
          name="email"
          {...register('email', {
            required: '이메일을 입력해주세요.',
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: '이메일 형식이 아닙니다.',
            },
          })}
        />
        {errors.email && <ErrorInput text={errors.email.message} />}

        <Input
          label="비밀번호"
          type="password"
          name="password"
          {...register('password', {
            required: '비밀번호를 입력해주세요.',
            pattern: {
              value:
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$/,
              message:
                '영문 대소문자, 숫자, 특수문자를 포함하여 8~20자로 입력해주세요. 특수문자는 !@#$%^&* 만 사용 가능합니다.',
            },
          })}
        />
        {errors.password && <ErrorInput text={errors.password.message} />}

        <Input
          label="비밀번호 확인"
          type="password"
          name="password2"
          {...register('password2', {
            required: '비밀번호 확인을 입력해주세요.',
            validate: (value) =>
              value === password || '비밀번호가 일치하지 않습니다.',
          })}
        />
        {errors.password2 && <ErrorInput text={errors.password2.message} />}

        <Input
          label="이름"
          type="text"
          name="name"
          {...register('name', {
            required: '이름을 입력해주세요.',
            pattern: {
              value: /^[가-힣]{2,20}$/,
              message: '한글 2 ~ 20 글자로 입력해주세요.',
            },
          })}
        />
        {errors.name && <ErrorInput text={errors.name.message} />}

        <Input
          label="생년월일"
          type="date"
          name="birthday"
          {...register('birthday', { required: '생년월일을 입력해주세요.' })}
        />
        {errors.birthday && <ErrorInput text={errors.birthday.message} />}

        <Controller
          name="gender"
          control={control}
          rules={{ required: '성별을 선택해주세요.' }}
          render={({ field }) => (
            <Select
              name="gender"
              label="성별"
              {...field}
              className={errors.gender && `border-red-500`}
            >
              <Option value="MALE">남</Option>
              <Option value="FEMALE">여</Option>
            </Select>
          )}
        />
        {errors.gender && <ErrorInput text={errors.gender.message} />}
      </section>
      <section className="flex flex-col gap-2">
        <Buttons
          type="filled"
          color={'purple5'}
          onClick={handleSubmit(onSubmit)}
        >
          가입하기
        </Buttons>
        <Buttons
          type="outlined"
          color={'purple5'}
          onClick={() => navigate('/signin')}
        >
          취소
        </Buttons>
      </section>
    </form>
  );
};

export default InfoForm;
