import React, { useEffect, useState } from 'react';
import instance from '../../../../api/axios/utils.ts/instance';
import { ApiResponse } from '../../../../constants/types';
import LearningChapter from './LearningChapter';
import { myLearningChapterType } from '../../../../constants/types/learning-type';

interface LearningLecturePageProps {
  learningId: string;
}
const LearningLecturePage = ({ learningId }: LearningLecturePageProps) => {
  const [chapterList, setChapterList] = useState<myLearningChapterType[]>();

  const getChapter = async () => {
    try {
      const response = await instance.get<
        ApiResponse<{ courseId: string; chapters: myLearningChapterType[] }>
      >(`/v1/learning/${learningId}/chapter-list`);
      const res = response.data;
      setChapterList(res.data.chapters);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getChapter();
  }, []);

  return (
    <>
      {chapterList?.map((item, index) => (
        <LearningChapter learningId={learningId} chapter={item} key={index} />
      ))}
    </>
  );
};

export default LearningLecturePage;
