import React, { Fragment, useEffect } from 'react';
import { LmsHeader } from './lmsheader';
import { Outlet, useParams } from 'react-router-dom';
import Sidebar from './lms/Sidebar';
import { useAppDispatch } from '../store/hook';
import { getLmsLists } from '../store/features/lmsSlice';
import ScrollToTop from './layout/ScrollToTop';

const LmsLayout = () => {
  const dispatch = useAppDispatch();
  const queryParams = useParams();

  const classId = queryParams.id ? queryParams.id : queryParams.classId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getLmsLists({ url: `/v1/class/${classId}` })).unwrap();
      } catch (error) {}
    };
    fetchData();
  }, []);
  return (
    <Fragment>
      <div className="fixed top-0 left-0 w-full mx-auto px-0 z-[10000]">
        <LmsHeader classId={classId} />
      </div>
      <main className="xl:w-full mt-[130px] xl:mt-[60px]">
        <div className="flex bg-gray0">
          <div className="hidden xl:block xl:min-w-[210px] h-[calc(100vh-60px)] overflow-x-hidden overflow-y-auto bg-gray2">
            <Sidebar classId={classId} />
          </div>
          <div className="w-full bg-gray1">
            <ScrollToTop>
              <Outlet />
            </ScrollToTop>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default LmsLayout;
