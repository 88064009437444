import React, { useEffect, useState } from 'react';
import ClassAssignItem from './ClassAssignItem';
import { classGroupType } from '../../../../../constants/types/student-type';
import { LmsClassGroupType } from '../../../../../constants/types/lms-type';
import { useParams } from 'react-router-dom';
import { getClassGroupList } from '../../../../../api/utils/lms/lmsApi';

interface ClassAssignListProps {
  setSelected: React.Dispatch<React.SetStateAction<classGroupType | undefined>>;
}
const ClassAssignList = ({ setSelected }: ClassAssignListProps) => {
  const { classId } = useParams();
  const [classList, setClassList] = useState<LmsClassGroupType[]>();

  useEffect(() => {
    getClassGroupList(classId).then((res) => {
      setClassList(res.result);
    });
  }, []);

  return (
    <div className="flex flex-col gap-2 p-0">
      {classList?.map((classItem) => (
        <ClassAssignItem
          key={classItem.id}
          onSelectedList={setSelected}
          classItem={classItem}
        />
      ))}
    </div>
  );
};

export default ClassAssignList;
