import React from 'react';
import LmsMigrationState from './LmsMigrationState';
import LmsMigrationList from './LmsMigrationList';
import TextWinds from '../../../components/common/TextWinds';
import { LmsMigrationStatus } from '../../../constants/types/lms-type';
import { useSearchParams } from 'react-router-dom';

const LmsMigrationPage = () => {
  const [params, setParams] = useSearchParams({
    status: LmsMigrationStatus.SUCCESS,
  });
  const status = params.get('status') as LmsMigrationStatus;

  const setState = (status: LmsMigrationStatus) => {
    params.set('status', status);
    setParams(params);
  };

  return (
    <div className="p-0 lg:px-10 lg:py-8">
      <div className="flex flex-col gap-4 p-5 lg:p-8 bg-white rounded-lg shadow-card">
        <TextWinds type="title_h3">구버전 학생 가져오기</TextWinds>
        {/* <LmsMigrationLoading /> */}
        <LmsMigrationState value={status} onChange={setState} />
        <LmsMigrationList status={status} />
      </div>
    </div>
  );
};

export default LmsMigrationPage;
