import React, { useState } from 'react';
import TextWinds from '../../../components/common/TextWinds';
import ModifyBasic from './ModifyBasic';
import ModifyPw from './ModifyPw';

const MypageModify = () => {
  const [selectedValue, setSelectedValue] = useState(null);

  const options = [
    { label: '+82', value: '+82' },
    { label: '+82', value: '+82' },
  ];

  return (
    <>
      <section className="px-5 pt-[18px] pb-8 lg:p-0">
        <div className="mb-[30px]">
          <TextWinds type="title_h2">회원 정보 수정</TextWinds>
        </div>
        <div className="flex flex-col gap-5">
          <ModifyPw isEdit={false} />
          <ModifyBasic isEdit={false} />
        </div>

        {/* <div className="hidden lg:block lg:flex lg:justify-center lg:mb-[25px]">
          <div className="relative rounded-full overflow-hidden">
            <Avatar
              variant="circular"
              src={process.env.PUBLIC_URL + '/svg/icon-profile-big.svg'}
              className="w-18 h-18 bg-gray4"
              alt="myname"
            />
            <div className="absolute bottom-0 w-full">
              <Buttons
                variant="text"
                color="purple1"
                type="bg"
                className="pt-[1px] pb-1 bg-purple1"
              >
                <TextWinds type="button2" color="purple5" className="w-[100px]">
                  변경
                </TextWinds>
              </Buttons>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-4 mb-[22px] lg:mb-8">
          <div className="flex flex-col gap-y-2">
            <TextWinds type="content_body3">닉네임</TextWinds>
            <ComInputButton
              type="text"
              placeholder="10자 이내"
              onChange={(e) => {}}
              buttonText="중복확인"
              variant="filled"
            />
          </div>
          <div className="flex flex-col gap-y-2">
            <TextWinds type="content_body3">이름</TextWinds>
            <ComInput type="text" placeholder="이름" onChange={(e) => {}} />
          </div>
          <div className="flex flex-col gap-y-2">
            <TextWinds type="content_body3">영문명 (수료증 기재용)</TextWinds>
            <ComInput
              type="text"
              placeholder="대문자로 작성해 주세요. (20자 제한)"
              onChange={(e) => {}}
              maxLength={20}
            />
          </div>
          <div className="flex flex-col gap-y-2">
            <TextWinds type="content_body3">현재 비밀번호</TextWinds>
            <ComInput
              type="password"
              placeholder="비밀번호"
              onChange={(e) => {}}
            />
          </div>
          <div className="flex flex-col gap-y-2">
            <TextWinds type="content_body3">새 비밀번호</TextWinds>
            <ComInput
              type="password"
              placeholder="비밀번호"
              onChange={(e) => {}}
            />
          </div>
          <div className="flex flex-col gap-y-2">
            <TextWinds type="content_body3">새 비밀번호 확인</TextWinds>
            <ComInput
              type="password"
              placeholder="비밀번호"
              onChange={(e) => {}}
            />
          </div>
          <div className="flex flex-col gap-y-2">
            <TextWinds type="content_body3">이메일 주소</TextWinds>
            <ComInputButton
              type="text"
              placeholder="email@email.com"
              onChange={(e) => {}}
              buttonText="수정하기"
              variant="outlined"
            />
          </div>
          <div className="flex flex-col gap-y-2">
            <TextWinds type="content_body3">휴대전화번호</TextWinds>
            <div className="flex gap-x-[6px]">
              <div className="border border-gray-500 rounded modify-drodown">
                <Dropdown
                  value={selectedValue}
                  options={options}
                  onChange={(e) => setSelectedValue(e.value)}
                  className="w-full h-[50px]"
                  placeholder="+82"
                />
              </div>
              <ComInputButton
                type="number"
                placeholder="- 없이 숫자만 입력"
                onChange={(e) => {}}
                buttonText="인증번호"
                variant="filled"
              />
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <TextWinds type="content_body3">인증번호</TextWinds>
            <ComInputButton
              type="number"
              placeholder="인증번호 입력"
              onChange={(e) => {}}
              buttonText="인증확인"
              variant="filled"
            />
          </div>
        </div>
        <div className="lg:w-[476px] lg:mx-auto">
          <Buttons variant="filled" color="purple5">
            <TextWinds type="button" color="white">
              완료
            </TextWinds>
          </Buttons>
        </div> */}
      </section>
    </>
  );
};

export default MypageModify;
