import React from 'react';
import LmsMigrationItem from './LmsMigrationItem';
import {
  LmsMigrationStatus,
  LmsMigrationStudent,
  studentType,
} from '../../../constants/types/lms-type';
import instance from '../../../api/axios/utils.ts/instance';
import { ApiResponse } from '../../../constants/types';
import { StudentCol } from '../studentmgmt/StudentCol';
import { useParams } from 'react-router-dom';

interface LmsMigrationListProps {
  status: LmsMigrationStatus;
}
const LmsMigrationList = ({ status }: LmsMigrationListProps) => {
  const { classId } = useParams();
  const [list, setList] = React.useState<LmsMigrationStudent[]>([]);

  React.useEffect(() => {
    getStudentList(status);
  }, [status]);

  const getStudentList = async (status: LmsMigrationStatus) => {
    try {
      const params = {
        isSuccess: status === LmsMigrationStatus.SUCCESS,
      };

      const res = await instance.get<ApiResponse<LmsMigrationStudent[]>>(
        `/v1/lms/${classId}/migration/legacy-student`,
        { params }
      );
      if (200 === res.status) {
        setList(res.data.data);
      }
    } catch (error) {
      console.error(error);
      setList([]);
    }
  };

  return (
    <div className="w-full overflow-x-auto">
      <div className="w-full min-w-[520px] bg-white">
        <div className="flex items-center text-gray7 border-y border-gray4 bg-gray0 h-[50px] font-bold">
          <StudentCol>아이디</StudentCol>
          <StudentCol>이름</StudentCol>
          <StudentCol>영문명</StudentCol>
          <StudentCol>생년월일</StudentCol>
          {status === LmsMigrationStatus.FAIL && (
            <>
              <StudentCol width="20" shrink="0">
                등록
              </StudentCol>
              {/* <StudentCol width="20" shrink="0">
                삭제
              </StudentCol> */}
            </>
          )}
        </div>
        {list && list.length > 0 ? (
          list.map((student) => (
            <LmsMigrationItem
              key={student.id}
              student={student}
              classId={classId}
              status={status}
              setList={setList}
            />
          ))
        ) : (
          <div className="w-full text-center p-5 border-b">
            데이터가 없습니다.
          </div>
        )}
      </div>
    </div>
  );
};

export default LmsMigrationList;
