import React, { useEffect } from 'react';
import { useAppDispatch } from '../../store/hook';
import { closeModal } from '../../store/features/modalSlice';
import HFbutton from '../common/input/HFbutton';
import ChannelService from '../../api/ChannelService';
import { calcDateClone, toDay } from '../../api/utils/util-func';
import { ModalPopupType } from '../../constants/popupData';

const ModalPopup = ({ img, link, type = '_blank' }: ModalPopupType) => {
  const POPUP_BLOCK = 'popupBlockDay';
  const dispatch = useAppDispatch();

  useEffect(() => {
    ChannelService.hideChannelButton();

    const dayBlock = localStorage.getItem(POPUP_BLOCK);
    const yesterDay = calcDateClone(toDay(), { day: -1 });
    const day = new Date(dayBlock);

    if (day && day > yesterDay) {
      closeHandler();
    }

    return () => {
      ChannelService.showChannelButton();
    };
  }, []);

  const closeHandler = () => {
    dispatch(closeModal());
  };

  const dayBlockHandler = () => {
    localStorage.setItem(POPUP_BLOCK, new Date().toLocaleDateString());
    closeHandler();
  };

  const clickHandler = () => {
    window.open(link, type);
  };
  return (
    <div className="flex flex-col gap-5 max-w-[480px] w-full bg-white p-5 rounded">
      <img
        className="w-full h-auto cursor-pointer"
        src={img}
        onClick={clickHandler}
      />
      <div className="flex gap-3">
        <HFbutton type="Outlined" onClick={dayBlockHandler}>
          하루간 보지 않기
        </HFbutton>
        <HFbutton onClick={closeHandler}>닫기</HFbutton>
      </div>
    </div>
  );
};

export default ModalPopup;
