import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Collapse, IconButton } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NavList from './navlist';
import { getDatas } from '../../../api/storage/localStorage';
import Profile from '../../profile';
import TextWinds from '../TextWinds';
import { decodeToken, isExpired } from 'react-jwt';
import StudentNavList from './StudentNavList';
import { useOutsideClick } from '../../../controllers/useOutsideClick';

const MegaMenu = () => {
  const isStudent = localStorage.getItem('type') === 'STUDENT' ? true : false;
  const [openNav, setOpenNav] = useState(false);
  const ref = useOutsideClick(() => {
    setOpenNav(false);
  });

  const toggleOpenNav = () => setOpenNav((cur) => !cur);

  const userToken = localStorage.getItem('accessToken');
  decodeToken(userToken);
  isExpired(userToken);
  useEffect(() => {
    window.addEventListener(
      'resize',
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  return (
    <header className="fixed top-0 left-0 w-full bg-white shadow-card z-[30]">
      <div className="w-full xl:mx-auto">
        <div className="flex justify-between items-center h-[58px] xl:h-[60px] px-5">
          <Link to={'/'}>
            <img
              src={process.env.PUBLIC_URL + '/logo.png'}
              alt="JEBS 로고"
              className="w-[40px] h-[40px]"
            />
          </Link>

          <div className="hidden mr-auto lg:block">
            {isStudent ? <StudentNavList /> : <NavList />}
          </div>

          <IconButton
            size="sm"
            color="blue-gray"
            variant="text"
            className="ml-auto mr-2 lg:hidden"
            onClick={toggleOpenNav}
          >
            {openNav ? (
              <XMarkIcon className="w-6 h-6" strokeWidth={2} />
            ) : (
              <Bars3Icon className="w-6 h-6" strokeWidth={2} />
            )}
          </IconButton>

          <div className="lg:flex lg:items-center lg:gap-x-1">
            {getDatas() ? (
              <div className="flex items-center gap-x-1">
                {!isStudent && (
                  <Link to={'/cart'}>
                    <div className="w-[35px] h-[35px]">
                      <img
                        src={
                          process.env.PUBLIC_URL + '/svg/icon/shopping-cart.svg'
                        }
                        alt="장바구니"
                        className="w-full h-full"
                      />
                    </div>
                  </Link>
                )}
                <Profile isStudent={isStudent} />
              </div>
            ) : (
              <Link to={'/signin'}>
                <TextWinds type={'title_h5'} color="purple5">
                  로그인
                </TextWinds>
              </Link>
            )}
          </div>
        </div>
        <div ref={ref}>
          <Collapse open={openNav}>
            {isStudent ? <StudentNavList /> : <NavList />}
          </Collapse>
        </div>
      </div>
    </header>
  );
};

export default MegaMenu;
