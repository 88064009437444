import { Input, Select } from 'antd';
import React, { useEffect, useMemo } from 'react';
import HFbutton from '../../common/input/HFbutton';

export type TodoStatusSearchObjType = {
  searchOption: string;
  searchValue: string;
  orderBy: string;
};
interface TodoStatusSearchProps {
  category: string;
  subType: string;
  searchObj: TodoStatusSearchObjType;
  activeTab: string;
  onChange: (searchObj: TodoStatusSearchObjType) => void;
  onSubmit: (searchObj: TodoStatusSearchObjType) => void;
}
const TodoStatusSearch = ({
  category,
  subType,
  searchObj,
  activeTab,
  onChange,
  onSubmit,
}: TodoStatusSearchProps) => {
  const searchOption = useMemo((): {
    label: string;
    value: string;
  }[] => {
    if (category === 'BY_TODO') {
      switch (subType.toUpperCase()) {
        case 'RECITATION':
          return [
            { label: '레벨', value: 'book' },
            { label: '북', value: 'title' },
          ];
        case 'JEBSON':
          return [
            { label: '북/챕터', value: 'book' },
            { label: '레슨', value: 'lesson' },
            { label: '제목', value: 'title' },
          ];
        case 'JEBSPL':
          return [
            { label: '북/챕터', value: 'book' },
            { label: '제목', value: 'title' },
          ];

        default:
          return [];
      }
    } else {
      return [
        { label: '학생명', value: 'name' },
        { label: '아이디', value: 'uid' },
      ];
    }
  }, [category, subType]);

  useEffect(() => {
    onChange({
      ...searchObj,
      searchOption: searchOption.length > 0 ? searchOption[0].value : undefined,
      searchValue: undefined,
    });
  }, [category, activeTab]);

  return (
    <div className="flex flex-col gap-5 py-3 px-5 md:flex-row md:px-0">
      <div className="flex gap-2 w-full max-w-[600px] h-8">
        <Select
          placeholder="검색조건"
          value={searchObj.searchOption}
          onChange={(value) =>
            onChange({ ...searchObj, searchOption: value as string })
          }
          className="w-[120px]"
        >
          {searchOption.map((item) => (
            <Select.Option value={item.value} key={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
        <Input
          value={searchObj?.searchValue}
          onChange={(e) =>
            onChange({ ...searchObj, searchValue: e.target.value })
          }
          placeholder="검색어를 입력하세요"
          disabled={!searchObj?.searchOption}
          onPressEnter={() => onSubmit(searchObj)}
        />
        <div className="w-[140px]">
          <HFbutton height={34} onClick={() => onSubmit(searchObj)}>
            SEARCH
          </HFbutton>
        </div>
      </div>

      {/* <Select
        placeholder="정렬순서"
        onChange={(value) => {
          const search = { ...searchObj, orderBy: value as string };
          onChange(search);
          onSubmit(search);
        }}
        value={searchObj?.orderBy}
      >
        <Select.Option value="ASC">오름차순</Select.Option>
        <Select.Option value="DESC">내림차순</Select.Option>
      </Select> */}
    </div>
  );
};

export default TodoStatusSearch;
