import React from 'react';
import {
  LearningStatusEnum,
  myLearningLessonInfoType,
} from '../../../../constants/types/learning-type';
import ChannelService from '../../../../api/ChannelService';
import { openContentsView } from '../../../../api/utils/util-func';
import { putRecentlyLearning } from '../../../../api/utils/api/learningApi';

interface LearningLessonItemProps {
  learningId: string;
  lesson: myLearningLessonInfoType;
  onComplete: () => void;
}
const LearningLessonItem = ({
  learningId,
  lesson,
  onComplete,
}: LearningLessonItemProps) => {
  const setContentView = React.useState<Window>(undefined)[1];

  const clickHandler = async () => {
    await putRecentlyLearning(learningId, lesson.id);

    openContentsView(
      {
        type: 'learningView',
        contents: {
          learningId,
          lessonId: lesson.id,
        },
      },
      setContentView
    );

    onComplete();
    ChannelService.hideChannelButton();
  };

  const acsessibleType = ['Vimeo', 'HTML'];

  if (lesson && !acsessibleType.includes(lesson.type))
    console.error('지원하지 않는 콘텐츠 타입입니다. Vimeo, HTML만 지원합니다.');

  const getStatusName = (status: LearningStatusEnum) => {
    switch (status) {
      case LearningStatusEnum.NOT_STARTED:
        return '수강 전';
      case LearningStatusEnum.IN_PROGRESS:
        return '수강 중';
      case LearningStatusEnum.COMPLETED:
        return '수강 완료';
      default:
        return '';
    }
  };

  return (
    lesson && (
      <div
        className="flex justify-between gap-2 md:gap-4 h-25 p-2 rounded cursor-pointer bg-gray0 hover:bg-gray2"
        onClick={clickHandler}
      >
        <img
          src={process.env.PUBLIC_URL + lesson.thumbnail}
          alt={lesson.name}
          className="w-16 md:w-20 object-cover rounded grow-0 shrink"
        />
        {/* 재생버튼, 콘텐츠 종류가 많아질 경우 사용 */}
        <div className="w-5 flex items-center relative right-0  grow-0 shrink-0">
          <img
            src={process.env.PUBLIC_URL + '/svg/icon/play-circle.svg'}
            alt={lesson.name}
            className="w-5 h-5 object-cover"
          />
        </div>

        <div className="flex flex-col justify-center grow">
          <div className="text-sm md:text-lg text-black">
            {lesson.lessonName}
          </div>
          <div className="text-md md:text-xl text-purple5 font-bold">
            {lesson.name}
          </div>
          {/* {lesson.type === 'Vimeo' ? 'Video' : lesson.type} */}
        </div>
        <div className="w-16 my-auto grow-0 shrink-0 font-bold">
          {getStatusName(lesson.status)}
        </div>
      </div>
    )
  );
};

export default LearningLessonItem;
