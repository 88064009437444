import React from 'react';
import { useAppDispatch } from '../../../store/hook';
import HFbutton from '../../../components/common/input/HFbutton';
import { closeModal, openAlert } from '../../../store/features/modalSlice';
import instance from '../../../api/axios/utils.ts/instance';
import { ApiResponse } from '../../../constants/types';
import HFinput from '../../../components/common/input/HFinput';
import { useForm } from 'react-hook-form';
import TextWinds from '../../../components/common/TextWinds';
import ErrorInput from '../../student/components/ErrorInput';

interface StudentMigrationModalProps {
  classId: string;
  onComplete: () => void;
}
const StudentMigrationModal = ({
  classId,
  onComplete,
}: StudentMigrationModalProps) => {
  const dispatch = useAppDispatch();
  const [error, setError] = React.useState<string>('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ u_id: string; pw: string }>();

  const closeHandler = () => {
    dispatch(closeModal());
  };

  const postLegacySignIn = async (u_id: string, pw: string) => {
    try {
      const params = {
        u_id: u_id,
        u_pw: pw,
      };
      // postLegacyStudent('misun9841@hanmail.net');
      const res = await instance.post<ApiResponse<{ success: boolean }>>(
        '/v1/auth/legacy-sign-in',
        params
      );

      if (res.data.statusCode === 200) {
        return res.data.data.success;
      } else {
        setError('아이디와 비밀번호를 확인해주세요.');
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  };

  const postLegacyStudent = async (uid: string) => {
    try {
      const res = await instance.post(
        `/v1/lms/${classId}/migration/legacy-student`,
        { uid }
      );
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = (data: { u_id: string; pw: string }) => {
    postLegacySignIn(data.u_id, data.pw).then((res) => {
      if (!res) {
        setError('아이디와 비밀번호를 확인해주세요.');
        return;
      }

      // 비동기 마이그레이션 시작
      postLegacyStudent(data.u_id);
      dispatch(
        openAlert({
          message: '연동 요청이 완료되었습니다.',
        })
      );

      //페이지 이동
      onComplete();
    });
  };

  // 구젭스의 아이디 비밀번호 찾기 페이지로 이동
  const findIdHandler = () => {
    const url = 'https://jebs2.kr/join/?find=id';
    window.open(url, '_blank');
  };

  return (
    <form className="p-5 bg-white rounded-lg max-w-[480px] w-full">
      <TextWinds type={'title_h2'} className="pb-3">
        구버전 연동하기
      </TextWinds>
      <TextWinds type={'caption1'} className="pb-3">
        학생 정보를 가져올 구 사이트의 교사 계정 정보를 입력해주세요.
      </TextWinds>
      <div className="text-xs">
        <div>
          <span className="text-blue-800">연동 가능</span>
          <span> - 학생 이름, 학생 아이디, 인증제 합격 이력, 인증제 뱃지</span>
        </div>
        <div>
          <span className="text-red-700">연동 불가</span>
          <span> - 젭스온/젭스플 학습 이력, 반 정보</span>
        </div>
      </div>
      <div className="mt-3">
        <HFinput
          type="text"
          label="구 사이트 교사 아이디"
          name="u_id"
          register={register('u_id', { required: '아이디를 입력해주세요' })}
          error={errors.u_id?.message}
        />
        <HFinput
          type="password"
          label="구 사이트 교사 비밀번호"
          name="pw"
          register={register('pw', { required: '비밀번호를 입력해주세요' })}
          error={errors.pw?.message}
        />
        {error && <ErrorInput text={error} />}
      </div>

      <div className="flex flex-col gap-2">
        <div
          className="text-right w-full cursor-pointer hover:text-blue-600"
          onClick={() => findIdHandler()}
        >
          ID/PW 찾기
        </div>
        <HFbutton onClick={handleSubmit(onSubmit)}>
          구 사이트 학생 가져오기
        </HFbutton>

        <HFbutton onClick={closeHandler}>닫기</HFbutton>
      </div>
    </form>
  );
};

export default StudentMigrationModal;
