import e from 'express';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../store/hook';
import { openAlert } from '../store/features/modalSlice';
import { useState } from 'react';

interface NavigationType {
  img: string;
  imgActive: string;
  title: string;
  path: string;
}
const LmsBottomNavigation = ({ classId }: { classId: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [path, setPath] = useState(NavigationList[0].path);

  const handleNavigation = (path: string, classId: string) => {
    const fullPath = path + '/' + classId;
    if (location.pathname === path) return;

    if (path === '/lms/class-setting') {
      dispatch(openAlert({ message: '준비 중인 기능입니다.' }));
      return;
    }

    setPath(path);
    navigate(fullPath);
  };

  return (
    <div className="fixed top-[20xp] left-0 z-10 w-full h-[84px] bg-white shadow-card">
      <div className="grid grid-cols-4 mt-[4px] inline-flex w-full justify-around">
        {NavigationList.map((navigation, idx) => (
          // <Link to={navigation.path + "/" + classId} key={idx}>
          <div
            className="flex flex-col items-center"
            onClick={() => handleNavigation(navigation.path, classId)}
            key={idx}
          >
            <img
              src={
                process.env.PUBLIC_URL + path === navigation.path
                  ? navigation.imgActive
                  : navigation.img
              }
              width={'40px'}
              height={'40px'}
              alt={navigation.title}
            />
            <div
              className={`text-sm font-normal ${
                path === navigation.path ? 'text-purple5' : 'text-gray7'
              }`}
            >
              {navigation.title}
            </div>
          </div>
          // </Link>
        ))}
      </div>
    </div>
  );
};

export default LmsBottomNavigation;

const NavigationList: NavigationType[] = [
  {
    img: '/svg/icon/classHome-gray.svg',
    imgActive: '/svg/icon/classHome-purple.svg',
    title: '클래스홈',
    path: `/lms/dashboard`,
  },
  {
    img: '/svg/icon/manageClass-gray.svg',
    imgActive: '/svg/icon/manageClass-purple.svg',
    title: '수업하기',
    path: `/lms/take-class`,
  },
  {
    img: '/svg/icon/manageStudent-gray.svg',
    imgActive: '/svg/icon/manageStudent-purple.svg',
    title: '학생관리',
    path: `/lms/signup-management`,
  },
  // { img: "/svg/footer/jebtalk.svg", title: "TALK", path: `/lms/notice-management` },
  {
    img: '/svg/icon/plus-gray.svg',
    imgActive: '/svg/icon/plus-purple.svg',
    title: '클래스설정',
    path: `/lms/class-setting`,
  },
];
