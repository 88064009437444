import React, { Fragment, useEffect, useState } from 'react';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Button, IconButton } from '@material-tailwind/react';
import Buttons from '../../../../components/common/Buttons';
import TextWinds from '../../../../components/common/TextWinds';
import instance from '../../../../api/axios/utils.ts/instance';
import { studentType } from '../../../../constants/types/lms-type';
import { useParams, useSearchParams } from 'react-router-dom';
import IconCopy from './IconCopy';
import IconEye from './IconEye';
import {
  getErrorMessages,
  handleCopyClipBoard,
} from '../../../../api/utils/util-func';
import { studentStatusType } from '../../../../constants/types/student-type';
import { useFieldArray, useForm } from 'react-hook-form';
import HFinput from '../../../../components/common/input/HFinput';
import HFinputPhone from '../../../../components/common/input/HFinputPhone';
import { Address, useDaumPostcodePopup } from 'react-daum-postcode';
import { phoneFormat, regPhone } from '../../../../api/utils/format/numbers';
import { Radio } from 'antd';

interface ModuleProps {
  title: string;
  open: boolean;
  reloadStudentList: () => void;
  closeDrawerBottom?: () => void;
}
const RegistDetail = ({
  title,
  open,
  reloadStudentList,
  closeDrawerBottom,
}: ModuleProps) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm<studentType>({
    defaultValues: {
      name: '',
      uid: '',
      parents: [
        {
          name: '',
          phone: '',
          parentType: '',
        },
      ],
    },
  });
  const [searchParam, setSearchParam] = useSearchParams();
  const uid = watch('uid');
  const name = watch('name');
  const classId = useParams().classId;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'parents',
  });

  const [detailTab, setDetailTab] = useState(false);
  const [parentTab, setParentTab] = useState(false);
  const [complete, setComplete] = useState(false);

  const [password, setPassword] = useState<string>('');
  const [pwhide, setPwhide] = useState<boolean>(true);
  const [idDuplicateCheck, setIdDuplicateCheck] = useState<boolean>(false);

  const daumPostHook = useDaumPostcodePopup();
  const daumPostHandleComplete = (data: Address) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setValue('address', fullAddress);
    setValue('zipCode', data.zonecode);
  };
  const daumPostHandleClick = () => {
    daumPostHook({ onComplete: daumPostHandleComplete });
  };

  const changeStatus = (status: studentStatusType) => {
    searchParam.delete('status');
    searchParam.set('status', status);
    setSearchParam(searchParam);
  };

  useEffect(() => {
    // 실질적인 최초 로그인
    if (!open) {
      if (complete) {
        changeStatus(studentStatusType.PENDING);
        setComplete(false);
      }
      reloadStudentList();
      return;
    }

    setComplete(false);

    // 훅폼 초기화
    reset();
  }, [open]);

  useEffect(() => {
    setIdDuplicateCheck(false);
  }, [watch('uid')]);

  const postStudent = async (params: studentType) => {
    const result = await instance.post(`/v1/lms/${classId}/student`, params);
    return result.data;
  };

  const getUid_check = async (params: { uid: string }) => {
    const result = await instance.get(`v1/user/uid-check?uid=${params.uid}`);
    return result.data.data as { existUser: boolean };
  };

  const submitHandler = (data: studentType) => {
    if (idDuplicateCheck === false) {
      alert('아이디 중복확인을 해주세요.');
      return;
    }

    if (data.parents?.length > 0) {
      // 추가 정보를 입력한 경우 각 필드의 유효성 검사.
      const { name, phone, parentType } = data.parents[0];
      if (name || phone || parentType) {
        // 입력이 있는 경우 유효성 검사
        if (!name) {
          alert('보호자명을 입력해주세요.');
          return;
        } else {
          //한글 2글자 이상 정규식
          const regName = /^[가-힣]{2,}$/;
          if (!regName.test(name)) {
            alert('보호자명을 정확히 입력해주세요.');
            return;
          }
        }
        if (!phone) {
          alert('보호자 연락처를 입력해주세요.');
          return;
        } else {
          // 보호자 연락처 유효성 검사
          if (!regPhone.test(phone.replace(/-/g, ''))) {
            alert('보호자 연락처를 정확히 입력해주세요.');
            return;
          }
        }
        if (!parentType) {
          alert('보호자 성별을 선택해주세요.');
          return;
        }
      } else {
        delete data.parents;
      }
    } else {
      delete data.parents;
    }

    if (data.phone || data.email || data.address || data.zipCode) {
      if (!data.phone) {
        alert('학생 연락처를 입력해주세요.');
        return;
      } else {
        // 학생 연락처 유효성 검사 [ - 제거 후 사용 ]
        if (!regPhone.test(data.phone.replace(/-/g, ''))) {
          alert('학생 연락처를 정확히 입력해주세요.');
          return;
        }
      }

      if (!data.email) {
        alert('이메일을 입력해주세요.');
        return;
      } else {
        // 이메일 정규식
        const regEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!regEmail.test(data.email)) {
          alert('이메일 형식을 정확히 입력해주세요.');
          return;
        }
      }

      if (!data.address) {
        alert('주소를 입력해주세요.');
        return;
      }
    }

    // console.log(data);
    postStudent(data)
      .then((data) => {
        setPassword(data.data.password);
        setComplete(true);
      })
      .catch((error) => {
        alert(getErrorMessages(error));
      });
  };

  const checkDuplicate = () => {
    if (!uid) {
      alert('아이디를 입력해주세요.');
      return;
    }

    getUid_check({ uid: uid }).then((data) => {
      if (data.existUser) {
        alert('중복된 아이디입니다.');
        setIdDuplicateCheck(false);
      } else {
        alert('사용 가능한 아이디입니다.');
        setIdDuplicateCheck(true);
      }
    });
  };

  const copyClipboard = () => {
    const text = `아이디 : ${uid}\n패스워드 : ${password}`;
    handleCopyClipBoard(text).then((result) => {
      if (result) {
        alert('복사되었습니다.');
      }
    });
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="flex flex-col gap-y-2.5"
    >
      <div className="flex justify-between items-center pt-2.5 pb-5 border-b border-gray4">
        <TextWinds type="title_h3">{title}</TextWinds>
        <IconButton
          variant="text"
          onClick={closeDrawerBottom}
          className="w-[30px] max-w-[30px] h-[30px] max-h-[30px]"
        >
          <XMarkIcon className="w-[30px] h-[30px]" />
        </IconButton>
      </div>
      <form>
        {complete ? (
          <div className="flex flex-col items-center gap-y-2.5 pb-2.5 border-b border-gray4">
            <TextWinds type="title_h4">
              {name} 학생이 등록 완료 되었습니다!
            </TextWinds>
            <div
              className="flex cursor-pointer"
              onClick={() => {
                copyClipboard();
              }}
            >
              <TextWinds type="sub_title01">복사하기</TextWinds>
              <IconCopy />
            </div>
            <div className="flex justify-center w-full">
              <TextWinds type="title_h1" color="purple5">
                아이디 : {uid}
              </TextWinds>
            </div>
            <div className="flex justify-center w-full">
              <TextWinds type="title_h1" color="purple5">
                패스워드 : {pwhide ? '*'.repeat(password.length) : password}
              </TextWinds>
              <TextWinds
                type="content"
                color="purple5"
                onClick={() => {
                  setPwhide(!pwhide);
                }}
                className="cursor-pointer"
              >
                <IconEye />
              </TextWinds>
            </div>
          </div>
        ) : (
          <div className="h-[345px] overflow-y-scroll">
            <div className="flex mt-4 items-start gap-x-2.5">
              <TextWinds type="sub_title01" color="purple5">
                필수 항목
              </TextWinds>
              <TextWinds type="content_body4" color="grayy">
                *필수 입력항목이에요.
              </TextWinds>
            </div>
            <div className="flex flex-col gap-y-2">
              <TextWinds type="content_body3" color="purple5">
                *학생명
              </TextWinds>
              <HFinput
                name="name"
                register={register('name', {
                  required: { value: true, message: '학생명을 입력해주세요.' },
                })}
                error={errors.name?.message}
                type="text"
                placeholder="학생명 입력"
              />
            </div>
            <div className="flex flex-col gap-y-2">
              <TextWinds
                type="content_body3"
                color="gray9"
                className="min-w-[92px]"
              >
                아이디
              </TextWinds>
              <div className="relative flex w-full">
                <HFinput
                  name="uid"
                  register={register('uid', {
                    required: {
                      value: true,
                      message: '아이디를 입력해주세요.',
                    },
                  })}
                  error={errors.uid?.message}
                  placeholder="12자리 이내의 영문 / 숫자"
                />
                <Button
                  size="sm"
                  onClick={checkDuplicate}
                  className="!absolute h-[41px] right-1 top-1 rounded bg-purple5 text-white hover:bg-purple6"
                >
                  중복확인
                </Button>
              </div>
            </div>
            <div className="border-y border-gray4">
              <div className="border-b border-gray4">
                <div className="flex items-center justify-between py-2.5">
                  <div className="flex items-start gap-x-2.5">
                    <TextWinds type="sub_title01">상세정보</TextWinds>
                    <TextWinds type="content_body4" color="gray7">
                      추후 별도 입력이 가능해요.
                    </TextWinds>
                  </div>
                  <IconButton
                    variant="text"
                    onClick={() => {
                      setDetailTab(!detailTab);
                    }}
                    className="w-6 max-w-6 h-6 max-h-6 text-gray8 hover:bg-transparent"
                  >
                    <ChevronDownIcon
                      className={`w-6 h-6 transition-transform ${
                        detailTab ? 'rotate-180' : ''
                      }`}
                      strokeWidth={2}
                    />
                  </IconButton>
                </div>
                {detailTab && (
                  <div className="flex flex-col gap-y-4 pb-2.5">
                    <div className="flex gap-x-2">
                      <div className="flex flex-col gap-y-2 w-full">
                        <HFinputPhone
                          label="학생 연락처"
                          placeholder="- 없이 번호만 입력"
                          name="phone"
                          register={register('phone')}
                        />
                      </div>
                      <div className="flex flex-col gap-y-2  w-full">
                        <HFinput
                          label="이메일"
                          placeholder="이메일 주소 입력"
                          name="email"
                          register={register('email')}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-2 w-full">
                      <TextWinds type="content_body3" color="gray9">
                        주소
                      </TextWinds>
                      <div className="relative flex w-full">
                        <HFinput
                          placeholder="주소 입력"
                          name="address"
                          readOnly
                          onClick={daumPostHandleClick}
                          register={register('address')}
                        />
                        <Button
                          size="sm"
                          className="!absolute h-[41px] right-1 top-1 rounded bg-purple5 text-white hover:bg-purple6"
                          onClick={daumPostHandleClick}
                        >
                          주소 검색
                        </Button>
                      </div>
                      <HFinput
                        placeholder="우편번호"
                        readOnly
                        name="zipCode"
                        register={register('zipCode')}
                      />
                    </div>
                    <div className="flex gap-x-2">
                      <div className="flex flex-col gap-y-2 w-full">
                        <HFinput
                          name="schoolName"
                          label="학교명"
                          placeholder="학교명 입력"
                          register={register('schoolName')}
                        />
                      </div>
                      <div className="flex flex-col gap-y-2  w-full">
                        <HFinput
                          name="schoolGroup"
                          label="반"
                          placeholder="반 입력"
                          register={register('schoolGroup')}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <Fragment>
                <div className="flex items-center justify-between py-2.5">
                  <div className="flex items-start gap-x-2.5">
                    <TextWinds type="sub_title01">보호자 정보</TextWinds>
                    <TextWinds type="content_body4" color="gray7">
                      추후 별도 입력이 가능해요.
                    </TextWinds>
                  </div>
                  <IconButton
                    variant="text"
                    onClick={() => {
                      setParentTab(!parentTab);
                    }}
                    className="w-6 max-w-6 h-6 max-h-6 text-gray8 hover:bg-transparent"
                  >
                    <ChevronDownIcon
                      className={`w-6 h-6 transition-transform ${
                        parentTab ? 'rotate-180' : ''
                      }`}
                      strokeWidth={2}
                    />
                  </IconButton>
                </div>
                {parentTab && (
                  <div className="flex flex-col gap-y-2.5">
                    {fields.map((item, index) => (
                      <div key={index}>
                        <div className="flex gap-x-2">
                          <div className="flex flex-col gap-y-2 w-full">
                            <HFinput
                              label="보호자명"
                              name={`parents.${index}.name`}
                              placeholder="보호자 성함 입력"
                              register={register(`parents.${index}.name`)}
                              // defaultValue={item.name}
                            />
                          </div>
                          <div className="flex flex-col gap-y-2  w-full">
                            <HFinputPhone
                              label="보호자 연락처"
                              placeholder="- 없이 번호만 입력"
                              name="parents[0].phone"
                              register={register(`parents.${index}.phone`)}
                              defaultValue={item.phone}
                            />
                          </div>
                        </div>
                        <div className="flex gap-3 items-center mb-5">
                          <TextWinds type="content_body3">성별</TextWinds>
                          <Radio.Group
                            defaultValue={undefined}
                            {...register(`parents.${index}.parentType`)}
                          >
                            <Radio.Button value="MALE">남</Radio.Button>
                            <Radio.Button value="FEMALE">여</Radio.Button>
                          </Radio.Group>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Fragment>
            </div>
          </div>
        )}
      </form>
      <div className="p-3 bg-purple0">
        <TextWinds type="content_body3" color="purple5" className="mb-1">
          학생 가입 안내
        </TextWinds>
        <ul className="list-disc space-y-2 text-purple5 ml-4">
          <li className="text-xs font-normal">
            일반 회원과 달리 교사가 직접 간편하게 학생을 가입시킬 수 있습니다.
          </li>
          <li className="text-xs font-normal">
            로그인 시 최초 1회 학생 개인 정보를 등록해야 합니다. (필요 시 보호자
            번호 인증 포함)
          </li>
        </ul>
      </div>
      <Buttons
        type="filled"
        color="purple5"
        onClick={() => {
          if (!complete) {
            handleSubmit(submitHandler)();
          } else {
            closeDrawerBottom();
          }
        }}
      >
        <TextWinds type="button" color="white">
          {complete ? '완료' : '학생 등록'}
        </TextWinds>
      </Buttons>
    </div>
  );
};

export default RegistDetail;
