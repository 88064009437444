import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Drawer, Typography } from '@material-tailwind/react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductContent, ChosenVariantDto } from '../../constants/types';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { getShopInfo, selectShopInfo } from '../../store/features/shopSlice';
import { ProductContentType } from '../../enum';
import { Options } from '../options/options';
import FullWidthPage from '../layout/FullWidthPage';
import TextWinds from '../common/TextWinds';
import Buttons from '../common/Buttons';
import { addCart } from '../../store/features/cartSlice';
import { getOrderItem } from '../../api/utils/common-purchase';
import { resetPurchaseInfo } from '../../store/features/purchaseSlice';
import { excuteAxiosPost } from '../../api/axios/call/testAxiosCall';
import useUserHook from '../../controllers/useUserHook';
import { openAlert } from '../../store/features/modalSlice';
import { INTEREST_FREE_INSTALLMENT } from '../../data/statics-datas';

const ShopDetails = () => {
  const { checkWithCallback } = useUserHook();
  const { shopId } = useParams();
  const path = '/v1/product/' + shopId;
  const dispatch = useAppDispatch();
  const shopInfo = useAppSelector(selectShopInfo);

  useEffect(() => {
    dispatch(getShopInfo({ url: path }));
  }, [path]);

  const [openBottom, setOpenBottom] = useState(false);
  const openDrawerBottom = () => {
    setOpenBottom(true);
  };
  const closeDrawerBottom = () => {
    setOpenBottom(false);
  };

  const isLoading = (): boolean => {
    if (!shopInfo) return true;
    return shopInfo.id != shopId;
  };

  const infoBtnClick = () => {
    dispatch(openAlert({ message: INTEREST_FREE_INSTALLMENT }));
  };

  // OPTION
  const navigate = useNavigate();
  // 1. 구매하기
  const onPurchaseHandle = async (
    childId: string,
    selectOptionData: ChosenVariantDto[]
  ) => {
    const action = async () => {
      const path = '/v1/order/template/from-prod';
      const params = {
        order_item: getOrderItem({
          product_id: childId,
          variants: selectOptionData,
          options: [],
          enrolls: [],
        }),
      };
      await Promise.all([excuteAxiosPost(path, params)]).then((res) => {
        if (res) {
          dispatch(resetPurchaseInfo());
          navigate(
            { pathname: '/purchase' },
            { state: { purchaseId: res[0].data.id } }
          );
        }
      });
    };
    checkWithCallback(action);
  };

  // 2. 장바구니
  const onCartHandle = useCallback(
    async (childId: string, selectItem: ChosenVariantDto[]) => {
      const action = async () => {
        if (selectItem.length > 0) {
          try {
            const res = await dispatch(
              addCart({
                url: '/v1/cart',
                data: {
                  order_item: getOrderItem({
                    product_id: shopInfo.id,
                    variants: selectItem,
                    options: [],
                    enrolls: [],
                  }),
                },
              })
            ).unwrap();

            if (res) {
              navigate('/cart');
            }
          } catch (e) {
            console.error(
              '일시적인 장애로 상품을 구매할 수 없습니다. 나중에 다시 시도해주세요.'
            );
          }
        }
      };
      checkWithCallback(action);
    },
    [shopInfo]
  );

  return isLoading() ? null : (
    <FullWidthPage>
      {shopInfo && (
        <div className="flex flex-col pt-10 gap-y-6">
          <TextWinds type="title_h2" xlType="title_h1" className="px-4">
            {shopInfo.name}
          </TextWinds>
          <div className="flex flex-col lg:pt-[24px] gap-[32px] lg:flex-row">
            <div className="flex w-full shrink-0 lg:w-[275px]">
              <div className="bg-gray0 p-[10px] sticky top-20 h-fit w-full">
                <div className="relative w-full ">
                  <TextWinds type="title_h6" color="gray7">
                    설명
                  </TextWinds>
                  <TextWinds type="content_rel" className="pt-[4px]">
                    {shopInfo.description}
                  </TextWinds>
                </div>
                <div className="bg-white mt-[16px] p-[10px]">
                  <div
                    className="flex items-center gap-[10px] p-[10px] cursor-pointer"
                    onClick={infoBtnClick}
                  >
                    <InformationCircleIcon className="relative w-[24px] h-[24px]" />
                    <TextWinds type="content_rel">무이자 할부 안내</TextWinds>
                  </div>
                  <div className="flex items-center flex-col justify-center w-full pt-[13px] gap-[13px]">
                    {/* <Buttons type="filled" disabled color={"purple5"}>
                      이용중 ~22.09.10 까지
                    </Buttons> */}
                    <Buttons
                      type="filled"
                      onClick={openDrawerBottom}
                      color={'purple5'}
                    >
                      구매하기
                    </Buttons>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col basis-full gap-y-6">
              <div className="relative h-full bg-gray-300">
                {shopInfo.contents.map((item: ProductContent) => {
                  switch (item.type) {
                    case ProductContentType.VIDEO:
                      return (
                        <iframe
                          key={item.id}
                          src={item.video_url}
                          className="w-full"
                        />
                      );
                    case ProductContentType.IMAGE:
                      return (
                        <img
                          key={item.id}
                          src={item.file.access_url}
                          className="w-full"
                        />
                      );
                    case ProductContentType.URL:
                      return (
                        <Typography
                          key={item.id}
                          variant="h6"
                          className="font-bold text-center leading-loose whitespace-nowrap"
                        >
                          {item.url_link ? item.url_link : 'No Data.'}
                        </Typography>
                      );
                    default:
                      return null;
                  }
                })}
              </div>
            </div>
            <div
              className={`w-full p-4 fixed bottom-0 left-0 ${
                openBottom ? 'block' : 'hidden'
              }`}
            >
              <Drawer
                placement="bottom"
                open={openBottom}
                onClose={closeDrawerBottom}
                overlay={false}
                size={500}
                // className="items-center w-1/2 px-5 py-4 left-1/4 right-1/4 h-fit"
                className="items-center w-full m-auto px-5 py-4 right-1/4 drop-shadow-xl rounded-2xl relative lg:w-1/2"
              >
                <Options
                  gubun="shop"
                  shopInfo={shopInfo}
                  chosenVariants={shopInfo.option}
                  closeDrawerBottom={closeDrawerBottom}
                  onCartHandle={onCartHandle}
                  onPurchaseHandle={onPurchaseHandle}
                />
              </Drawer>
            </div>
          </div>
        </div>
      )}
    </FullWidthPage>
  );
};

export default ShopDetails;
