import React, { useState } from 'react';
import TextWinds from '../../../../components/common/TextWinds';
import { myLearningTodoType } from '../../../../constants/types/student-type';
import HFbutton from '../../../../components/common/input/HFbutton';
import instance from '../../../../api/axios/utils.ts/instance';
import { useParams } from 'react-router-dom';
import { ToDoStatusEnum } from '../../../../enum';
import { useAppDispatch } from '../../../../store/hook';
import { openConfirm } from '../../../../store/features/modalSlice';
import { openContentsView } from '../../../../api/utils/util-func';

interface StudentHomeworkItemProps {
  data: myLearningTodoType;
  setTodoView: (value: React.SetStateAction<Window>) => void;
  lastItemRef?: (node: HTMLDivElement) => void;
}
const StudentHomeworkItem = ({
  data,
  setTodoView,
  lastItemRef,
}: StudentHomeworkItemProps) => {
  const dispatch = useAppDispatch();
  const { classId } = useParams();
  const [todo, setTodo] = React.useState<myLearningTodoType>(data);

  const patchTodoStatus = async (status: string) => {
    try {
      const result = await instance.patch(
        `/v1/student/class/${classId}/todo/${todo.id}`,
        { status: status }
      );
    } catch (e) {
      console.error(e);
    }
  };

  const startAction = () => {
    const dataToSend = {
      type: 'todoView',
      contents: {
        url: todo.link,
      },
    };
    openContentsView(dataToSend, setTodoView);

    // 인증제만 마감처리 하던 코드
    // if (todo.type === 'RECITATION') {
    //   patchTodoStatus('CLOSED');
    //   setTodo((prev) => ({ ...prev, status: ToDoStatusEnum.CLOSED }));
    // } else {
    // patchTodoStatus('IN_PROGRESS');
    // setTodo((prev) => ({ ...prev, status: ToDoStatusEnum.IN_PROGRESS }));
    // }

    patchTodoStatus('IN_PROGRESS');
    setTodo((prev) => ({ ...prev, status: ToDoStatusEnum.IN_PROGRESS }));
  };

  const startHandler = () => {
    if (todo.type === 'RECITATION') {
      dispatch(
        openConfirm({
          message:
            '시작과 동시에 응시 시도 횟수가 차감됩니다.\n바로 시작하시겠어요?\nㅤ\n※ Chrome, Explore 에서 진행 바랍니다.',
          onConfirm: () => {
            startAction();
          },
        })
      );
    } else {
      startAction();
    }
  };

  const getButtonLabel = (status: ToDoStatusEnum) => {
    switch (status) {
      case 'IN_PROGRESS':
        return '진행 중';
      case 'COMPLETED':
        return '완료';
      case 'CLOSED':
        return '마감';
      default:
        return '할 일 시작';
    }
  };

  return (
    <div
      className="w-full bg-white rounded-lg shadow-card p-5 flex justify-between items-center"
      ref={lastItemRef}
    >
      <div>
        <Chip color="skyblue" text={`${todo.type}`} />
        <div>
          <TextWinds type={'title_h3'} children={todo.name} className="mt-2" />
        </div>
        <div>
          <TextWinds type={'content'} className="mt-2">
            {`${todo.remainDays}일 남음
            (${new Date(todo.toDate).toLocaleDateString()}까지)`}
          </TextWinds>
        </div>
      </div>
      <div>
        <HFbutton
          onClick={startHandler}
          height={40}
          disabled={todo.status === 'CLOSED' || todo.status === 'COMPLETED'}
        >
          {getButtonLabel(todo.status)}
        </HFbutton>
      </div>
    </div>
  );
};

interface ChipProps {
  color: 'tangerine5' | 'skyblue' | string;
  text: string;
}
const Chip = ({ color, text }: ChipProps) => {
  return (
    <div
      className={`text-${color} border border-${color} rounded-md text-center w-fit p-1 text-sm `}
    >
      {text}
    </div>
  );
};

export default StudentHomeworkItem;
