import instance from '../../../api/axios/utils.ts/instance';
import { ApiResponse } from '../../../constants/types';
import { studentType } from '../../../constants/types/lms-type';

export const putClass = async (
  classId: string,
  params: {
    classGroupId: number;
    studentIds: number[];
  }
) => {
  const result = await instance.put(
    `/v1/lms/${classId}/student/assign-class-group`,
    params
  );
  return result.data;
};

export const getStudentDetail = async (classId: string, studentId: number) => {
  try {
    const result = await instance.get<ApiResponse<studentType>>(
      `/v1/lms/${classId}/student/${studentId}`
    );
    if (result.data.statusCode === 200) {
      return result.data.data;
    } else {
      console.error(result.data);
      return undefined;
    }
  } catch (e) {
    console.error(e);
  }
};

export const putStudentDetail = async (
  classId: string,
  studentId: number,
  student: Omit<studentType, 'name' | 'uid'>
) => {
  const params: Omit<studentType, 'name' | 'uid'> & { id: number } = {
    id: studentId,
    birthday: student.birthday,
    email: student.email,
    phone: student.phone,
    address: student.address,
    detailAddress: student.detailAddress,
    zipCode: student.zipCode,
    schoolName: student.schoolName,
    schoolGroup: student.schoolGroup,
    parents: [...student.parents],
  };
  const result = await instance.put(`/v1/lms/${classId}/student`, params);
  return result.data;
};

export const putStudentPassword = async (
  classId: string,
  studentId: number
) => {
  try {
    const result = await instance.put<
      ApiResponse<{ uid: string; password: string }>
    >(`/v1/lms/${classId}/student/reset-password`, { id: studentId });
    return result.data.data;
  } catch (e) {
    console.error(e);
  }
};
