import { Pagination, Table } from 'antd';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  columnNoLesson,
  columnsBase,
  columnsFull,
  columnsRECITATION,
} from './columns';
import {
  getTodoBookLists,
  getTodoLessonLists,
  getTodoLists,
} from '../../../../api/utils/lms/lmsApi';
import { ToDoMgmtSubType } from '../../../../enum';
import LmsSelect, {
  LmsSelectOption,
  toLmsSelectOptions,
} from '../../../../components/lms/LmsSelect';
import { TODO_SUBTYPE, TODO_TYPE } from '../../../../data/statics-datas';
import { selectLmsLists } from '../../../../store/features/lmsSlice';
import { useAppSelector } from '../../../../store/hook';
import HFbutton from '../../../../components/common/input/HFbutton';
import CheckBoxes from '../../../../components/common/CheckBoxes';
import TextWinds from '../../../../components/common/TextWinds';
import { openContentsView } from '../../../../api/utils/util-func';

const RegisterTodoPage = () => {
  const nav = useNavigate();
  const { classId, subType } = useParams();
  const lmsLists = useAppSelector(selectLmsLists);
  const [pageInfo, setPageInfo] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
    lastPage: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  // 첫번째 셀렉트 : 타입 디테일 [ WMW, JS1, JS2, HF, HJ, RECITATION, JEBSON ]
  const [typeList, setTypeList] = useState([]);
  // 두번째 셀렉트 : 북/챕터
  const [todoBookLists, setTodoBookLists] = useState<string[]>();
  // 세번째 셀렉트 : 레슨
  const [todoLessonLists, setTodoLessonLists] = useState<string[]>();

  // 선택된 타입 디테일
  const [type, setType] = useState<TODO_TYPE>();
  // 선택된 북/챕터
  const [book, setBook] = useState<string>();
  // 선택된 레슨
  const [lesson, setLesson] = useState(undefined);

  // 화면에 뿌려지는 데이터
  const [todoLists, setTodoLists] = useState(undefined);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  // 할일 미리보기 창
  const setTodoView = useState<Window>(undefined)[1];

  // 정리 필요. 서브타입에 따라 타입 디테일을 가져오는 함수
  const getType = (subType: string) => {
    if (ToDoMgmtSubType.JEBSPL === subType.toUpperCase()) {
      if ('VY6Yl4kxQsb3sYJZ' === lmsLists.course?.id) {
        // WMW
        return TODO_SUBTYPE.find(
          (item) => item.courseId === lmsLists.course?.id
        );
      } else {
        // HFHJ
        return TODO_SUBTYPE.find(
          (item) =>
            item.courseId === '' && item.subType === subType.toUpperCase()
        );
      }
    } else {
      // JEBSON, JEBSPL
      return TODO_SUBTYPE.find(
        (item) => item.subType === subType.toUpperCase()
      );
    }
  };

  const contentsOpenHandler = (url: string) => {
    const dataToSend = {
      type: 'todoView',
      contents: {
        url,
      },
    };
    openContentsView(dataToSend, setTodoView);
  };

  const columns = useMemo(() => {
    switch (type) {
      case TODO_TYPE.RECITATION:
        return columnsRECITATION(contentsOpenHandler);
      case TODO_TYPE.WMW:
      case TODO_TYPE.JS1:
      case TODO_TYPE.JS2:
        return columnNoLesson(contentsOpenHandler);
      case TODO_TYPE.HF:
      case TODO_TYPE.HJ:
      case TODO_TYPE.JEBSON:
        return columnsFull(contentsOpenHandler);
      default:
        return columnsBase(contentsOpenHandler);
    }
  }, [type]);

  useEffect(() => {
    const types = getType(subType).types;
    setTypeList(types);
    if (types.length > 0) {
      setType(types[0].key);
    } else {
      console.error('types is empty');
      throw new Error('types is empty');
    }
  }, [subType]);

  useEffect(() => {
    if (!type) return;

    // 타입 디테일에 따른 북/챕터 가져오기
    getTodoBookLists(classId, type).then((todoBookLists) => {
      setTodoBookLists(todoBookLists);
      setBook(undefined);
      setLesson(undefined);
    });

    loadData(type);
  }, [type]);

  useEffect(() => {
    if (type === TODO_TYPE.JEBSON) {
      getTodoLessonLists(classId, type, book).then((todoLessonLists) => {
        setTodoLessonLists(todoLessonLists);
        setLesson(undefined);
      });
    }

    loadData(type, book);
  }, [book]);

  useEffect(() => {
    if (type === TODO_TYPE.JEBSON) {
      loadData(type, book, lesson);
    }

    // if (!lesson) return;
  }, [lesson]);

  useEffect(() => {
    loadData(type, book, lesson);
  }, [pageInfo.page, pageInfo.pageSize]);

  const loadData = (type: string, book?: string, lesson?: string) => {
    if (!type) return;

    setIsLoading(true);

    getTodoLists(
      classId,
      type,
      pageInfo.page,
      pageInfo.pageSize,
      book,
      lesson
    ).then((todoLists) => {
      const page = todoLists.page;
      setPageInfo({
        ...pageInfo,
        total: page.total,
        lastPage: page.lastPage,
      });
      setTodoLists(todoLists.result);
      setIsLoading(false);
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    columnWidth: 120,
    columnTitle: (originNode: ReactNode) => (
      <>
        <CheckBoxes
          type="full"
          id={`all-999`}
          value={'전체 선택'}
          labelValue={
            <TextWinds type="content_bold" className="px-[6px] py-1">
              전체 선택
            </TextWinds>
          }
          checked={selectedRowKeys?.length === todoLists?.length}
          // borderWidth={2}
          onChange={(e) => {
            if (!e.target.checked) {
              setSelectedRowKeys([]);
            } else {
              setSelectedRowKeys(todoLists.map((item: any) => item.id));
            }
          }}
        />
      </>
    ),
    renderCell(
      checked: boolean,
      record: any,
      index: number,
      originNode: ReactNode
    ) {
      return (
        <CheckBoxes
          type="full"
          checked={selectedRowKeys.includes(record.id)}
          // borderWidth={2}
          onChange={(e) => {
            const rows = selectedRowKeys.includes(record.id)
              ? selectedRowKeys.filter((key) => key !== record.id)
              : [...selectedRowKeys, record.id];
            setSelectedRowKeys(rows);
          }}
        />
      );
    },
  };

  const registAll = () => {
    nav(`?stage=regist&type=${type}&ids=${selectedRowKeys}`);
  };

  return (
    <div className="flex flex-col gap-3 p-3">
      <div className="flex gap-3 flex-col md:flex-row justify-between">
        <div className="flex flex-col md:flex-row gap-2">
          {typeList.length > 0 && (
            <LmsSelect
              label={'자료유형'}
              options={toLmsSelectOptions(typeList, 'key', 'key', 'name')}
              onChange={(v) => {
                setType(v as TODO_TYPE);
              }}
              value={type}
              clearVisible={false}
              className="w-full md:w-[200px]"
            />
          )}
          <LmsSelect
            label={'북/챕터'}
            options={
              todoBookLists
                ? todoBookLists.reduce<LmsSelectOption[]>(
                    (acc, cur) => [
                      ...acc,
                      { key: cur, value: cur, label: cur },
                    ],
                    []
                  )
                : []
            }
            value={book}
            onChange={(v) => {
              setBook(v);
              setPageInfo({ ...pageInfo, page: 1 });
            }}
            className="w-full md:w-[200px]"
          />
          {type === TODO_TYPE.JEBSON && (
            <LmsSelect
              label={'레슨'}
              options={
                todoLessonLists
                  ? todoLessonLists.reduce<LmsSelectOption[]>(
                      (acc, cur) => [
                        ...acc,
                        { key: cur, value: cur, label: cur },
                      ],
                      []
                    )
                  : []
              }
              value={lesson}
              onChange={(v) => {
                setLesson(v);
              }}
              notFoundContent="북/챕터를 선택해주세요."
              className="w-full md:w-[200px]"
            />
          )}
        </div>
        <div className="w-full flex flex-row md:w-[170px] items-center">
          <div className="w-[60px]">{selectedRowKeys.length} 개</div>
          <HFbutton
            height={34}
            type="orange-Filled"
            onClick={() => registAll()}
          >
            선택 일괄 등록
          </HFbutton>
        </div>
      </div>
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        dataSource={todoLists}
        rowKey={(row) => row.id}
        scroll={{ x: 'max-content' }}
        pagination={false}
        size="small"
        loading={isLoading}
      />
      <Pagination
        current={pageInfo.page}
        total={pageInfo.total}
        pageSize={pageInfo.pageSize}
        showSizeChanger
        onChange={(page, pageSize) => {
          setSelectedRowKeys([]);
          setPageInfo({ ...pageInfo, page, pageSize });
          // setSearchParams('page', page.toString());
        }}
        className="justify-between"
      />
    </div>
  );
};

export default RegisterTodoPage;
