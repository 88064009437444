import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import {
  Button,
  Card,
  IconButton,
  List,
  ListItem,
  ListItemPrefix,
  Radio,
  Typography,
} from '@material-tailwind/react';
import { Fragment, useState } from 'react';
import {
  ChosenVariantDto,
  CourseInfoType,
  ProductCourse,
} from '../../constants/types';
import { numberWithCommas } from '../../api/utils/format/numbers';

interface PlansProps {
  data: CourseInfoType;
  closeDrawerBottom: any;
  optionVisible: boolean;
  handleNext: any;
  handleSubmit: (childId: string, selectOptionData: ChosenVariantDto[]) => void;
}
const Plans = ({
  data,
  closeDrawerBottom,
  optionVisible,
  handleNext,
  handleSubmit,
}: PlansProps) => {
  const [radioItem, setRadioItem] = useState<ProductCourse>();
  const onChangeDataHandler = (id: string) => {
    const plan = data.courses.find((plan) => plan.course.id === id);
    setRadioItem(plan);
  };

  const findFitCourse = () => {
    return (
      data.courses.find((course) => course.course.type === 'TEACHING') ||
      data.courses.find((course) => course.course.type === 'LEARNING') ||
      data.courses.find((course) => course.course.type === 'TRAINING')
    );
  };

  const fitCourse = findFitCourse();

  const dayExpression = (day: number) => {
    if (day % 365 === 0) return `${day / 365} 년`;
    else return `${day} 일`;
  };

  const PlansDetailComp = ({ plan }: { plan: ProductCourse }) => {
    return (
      <label htmlFor={plan.course.id}>
        <ListItem className="flex w-full cursor-pointer items-center gap-3.5 py-3">
          <ListItemPrefix>
            <Radio
              id={plan.course.id}
              name="vertical-list"
              ripple={false}
              icon={
                <CheckIcon
                  stroke="white"
                  strokeWidth={2.5}
                  className="w-4 h-4"
                />
              }
              className="w-6 h-6 border-0 opacity-50 checked:opacity-100 hover:before:opacity-0 bg-purple5"
              containerProps={{
                className: 'p-0',
              }}
              defaultChecked={plan.course.id === radioItem?.course.id}
              onChange={() => {
                onChangeDataHandler(plan.course.id);
              }}
            />
          </ListItemPrefix>
          <div className="flex flex-col sm:flex-row w-full gap-1.5">
            <div className="flex flex-col w-full mr-auto gap-1.5">
              <Typography
                variant="h5"
                className="font-medium leading-6 whitespace-wrap text-md sm:text-lg"
              >
                {/* {`${plan.name} [ ${dateResize(plan.period)} ]`} */}
                {/* {`${plan.name} [ ${dateResize(enrolls.period)} ]`} */}
                {`${fitCourse.course.name} [ ${dayExpression(
                  fitCourse.enroll_options[0].period
                )} ]`}
              </Typography>
            </div>
            <Typography
              variant="h5"
              className="font-semibold leading-7 whitespace-nowrap checked:text-text-purple5 text-md sm:text-lg"
            >
              {numberWithCommas(data.current_price)} 원
            </Typography>
          </div>
        </ListItem>
      </label>
    );
  };

  return (
    <Fragment>
      <section>
        <div className="flex flex-row items-center gap-8 mb-2.5">
          <div className="flex flex-col items-center w-full">
            <Typography variant="h5" className="font-bold self-start py-2.5">
              플랜 선택
            </Typography>
            {/* <Typography variant="h6" className="font-normal self-start my-2.5">
                        플랜에 대한 설명 텍스트 입니다.
                        </Typography> */}
          </div>
          <div className="flex items-center ml-auto">
            <IconButton
              variant="text"
              color="blue-gray"
              onClick={() => closeDrawerBottom()}
            >
              <XMarkIcon className="!w-[30px] !h-[30px]" />
            </IconButton>
          </div>
        </div>
      </section>

      <section className="h-2/4 overflow-y-auto">
        <List className="flex flex-col gap-2.5">
          <Card className="mb-2.5" key={fitCourse.course.id}>
            <PlansDetailComp plan={fitCourse} />
          </Card>
        </List>
      </section>

      <section>
        {/* <div className="flex flex-col justify-center w-full bg-gray-100 rounded-[2px] overflow-hidden px-3 py-5 mb-2.5">
          <Typography variant="h6">[유의 사항 영역]</Typography>
          <Typography variant="h6">
            포인트는 적립 일로부터 3년간 유효합니다.
          </Typography>
        </div> */}
        <div className="flex w-full justify-center rounded-md bg-purple5">
          <Button
            disabled={!radioItem}
            variant="text"
            color="white"
            className="w-full px-2.5 py-4 font-bold text-base text-center leading-6 whitespace-nowrap"
            onClick={() =>
              optionVisible ? handleNext(radioItem) : handleSubmit(data.id, [])
            }
          >
            {optionVisible ? '다음' : '구매하기'}
          </Button>
        </div>
      </section>
    </Fragment>
  );
};

export default Plans;
