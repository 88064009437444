import { useCallback, useEffect, useState } from 'react';
import CartLeft from '../../components/cart/cartleft';
import NormalPage from '../../components/layout/NormalPage';
import FullWidthPage from '../../components/layout/FullWidthPage';
import Buttons from '../../components/common/Buttons';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import {
  addTemplateFromCart,
  cartCheckedProps,
  getCartLists,
  selectCartLists,
  singleRemoveItem,
  updateCartItemQuantity,
} from '../../store/features/cartSlice';
import { OrderItem } from '../../constants/types';
import { groupBy } from '../../api/utils/util-func';
import { useNavigate } from 'react-router-dom';
import CartRight from '../../components/cart/cartright';
import {
  getDiscountAmount,
  getOrderItemShippingFee,
  getOrderItemTotalAmount,
} from '../../api/utils/order-item-total-amount';
import { excuteAxiosPost } from '../../api/axios/call/testAxiosCall';

const Cart = () => {
  const dispatch = useAppDispatch();
  const cartLists = useAppSelector(selectCartLists);
  const [selectedProducts, setSelectedProducts] =
    useState<cartCheckedProps[]>(undefined);

  useEffect(() => {
    dispatch(getCartLists({ url: '/v1/cart' }))
      .unwrap()
      .then((lists) => {
        let initSelectedCheckItems: cartCheckedProps[] =
          lists &&
          lists.map((cart) => ({
            id: cart.id,
            isChecked: false,
          }));
        setSelectedProducts(initSelectedCheckItems);
      });
  }, []);

  //개별 선택
  const handleCheckboxChange = (target: cartCheckedProps[]) => {
    // setSelectedProducts(target.filter((x) => x.isChecked))
    setSelectedProducts(target);

    const isAllChecked = target.every((item) => item.isChecked);
    if (isAllChecked) {
      // 전체체크 true
      allCheckedOn();
    } else {
      // 전체체크 false
      allCheckedOff();
    }
  };

  //전체 선택
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const allCheckedOn = () => setSelectAll(true);
  const allCheckedOff = () => setSelectAll(false);
  const handleSelectAllChange = () => {
    if (!selectAll) {
      setSelectedProducts(
        selectedProducts.map((item) => ({ ...item, isChecked: true }))
      );
      allCheckedOn();
    } else {
      setSelectedProducts(
        selectedProducts.map((item) => ({ ...item, isChecked: false }))
      );
      allCheckedOff();
    }
  };

  //아이템 개수 조절
  const onDecrease = useCallback(
    async (
      type: string,
      variantId: string,
      orderItemId: string,
      value: number
    ) => {
      try {
        const res = await dispatch(
          updateCartItemQuantity({
            url: '/v1/cart/order-item/quantity',
            data: {
              order_item_id: orderItemId,
              variant_id: variantId,
              quantity: value,
            },
          })
        ).unwrap();

        if (res && res.success) {
          dispatch(getCartLists({ url: '/v1/cart' }));
        }
      } catch (e) {
        console.error(
          '일시적인 장애로 상품정보를 수정할 수 없습니다. 나중에 다시 시도해주세요.'
        );
      }
    },
    []
  );
  const onIncrease = useCallback(
    async (
      type: string,
      variantId: string,
      orderItemId: string,
      value: number
    ) => {
      try {
        const res = await dispatch(
          updateCartItemQuantity({
            url: '/v1/cart/order-item/quantity',
            data: {
              order_item_id: orderItemId,
              variant_id: variantId,
              quantity: value,
            },
          })
        ).unwrap();
        if (res && res.success) {
          dispatch(getCartLists({ url: '/v1/cart' }));
        }
      } catch (e) {
        console.error(
          '일시적인 장애로 상품정보를 수정할 수 없습니다. 나중에 다시 시도해주세요.'
        );
      }
    },
    []
  );

  // 선택된 아이템 갯수
  const selectCnt: number = selectedProducts
    ? selectedProducts.filter((el) => el.isChecked).length
    : 0;

  const navigate = useNavigate();
  const handleBuySingleItem = useCallback(async (orderItem: OrderItem) => {
    const optionGrouped = groupBy(
      orderItem.chosen_options,
      (op) => op.product.id
    );
    const result = {
      chosen_variants: orderItem.chosen_variants.map(
        ({ quantity, variant }) => ({
          quantity,
          variant_id: variant.id,
        })
      ),
      chosen_options: Object.keys(optionGrouped).map((prodId) => {
        const list = optionGrouped[prodId];
        return {
          product_id: prodId,
          chosen_variants: list.map((x) => ({
            quantity: x.chosen_variants[0].quantity,
            variant_id: x.chosen_variants[0].variant_id,
          })),
        };
      }),
      enroll_options: orderItem.enroll_options.map((x) => ({
        enroll_option_id: x.enroll_option.id,
      })),
      product_id: orderItem.product_id,
    };

    await Promise.all([
      excuteAxiosPost('/v1/order/template/from-prod', { order_item: result }),
    ]).then((res) => {
      if (res) {
        navigate(
          { pathname: '/purchase' },
          { state: { purchaseId: res[0].data.id } }
        );
      }
    });
  }, []);

  const handleSelectByItems = useCallback(async () => {
    const purchaseLists: string[] = [
      ...selectedProducts.filter((x) => x.isChecked === true).map((y) => y.id),
    ];
    try {
      const res = await dispatch(
        addTemplateFromCart({
          url: '/v1/order/template/from-cart',
          params: {
            cart_item_ids: purchaseLists,
          },
        })
      ).unwrap();
      navigate({ pathname: '/purchase' }, { state: { purchaseId: res.id } });
    } catch (e) {
      console.error(
        '일시적인 장애로 상품을 구매할 수 없습니다. 나중에 다시 시도해주세요.'
      );
    }
  }, [selectedProducts, cartLists]);

  const onSingleRemoveItem = useCallback(async (id: string) => {
    const path: string = '/v1/cart/' + id;
    try {
      const res = await dispatch(singleRemoveItem({ url: path })).unwrap();
      if (res) {
        dispatch(getCartLists({ url: '/v1/cart' }))
          .unwrap()
          .then((lists) => {
            let initSelectedCheckItems: cartCheckedProps[] =
              lists &&
              lists.map((cart) => ({
                id: cart.id,
                isChecked: false,
              }));
            setSelectedProducts(initSelectedCheckItems);
          });
      }
    } catch (e) {
      console.error(
        '일시적인 장애로 상품정보를 삭제할 수 없습니다. 나중에 다시 시도해주세요.'
      );
    }
  }, []);

  const onRemoveAllItems = useCallback(async (ids: string[]) => {
    const path: string = '/v1/cart/';
    try {
      await Promise.all(
        [...ids].map((id) => {
          dispatch(singleRemoveItem({ url: path.concat(id) }));
        })
      );
      dispatch(getCartLists({ url: '/v1/cart' }))
        .unwrap()
        .then((lists) => {
          let initSelectedCheckItems: cartCheckedProps[] =
            lists &&
            lists.map((cart) => ({
              id: cart.id,
              isChecked: false,
            }));
          setSelectedProducts(initSelectedCheckItems);
        });
    } catch (e) {
      console.error(
        '일시적인 장애로 장바구니 상품을 삭제할 수 없습니다. 나중에 다시 시도해주세요.'
      );
    }
  }, []);

  // 주문금액
  const [allOrderPrice, setAllOrderPrice] = useState(0);
  // 상품금액
  const [allProdPrice, setAllProdPrice] = useState(0);
  // 할인금액
  const [allSalePrice, setAllSalePrice] = useState(0);
  // 배송비
  const [allDeliveryPrice, setAllDeliveryPrice] = useState(0);
  // 결제금액
  const [allPurchasePrice, setAllPurchasePrice] = useState(0);

  //총 상품금액 변경 함수
  const onChangePrices = () => {
    let prdPrice = 0,
      salePrice = 0,
      deliveryPrice = 0;
    const buyTargets = selectedProducts?.filter((x) => x.isChecked);
    if (buyTargets && buyTargets.length > 0) {
      buyTargets.map(({ id }) => {
        const targets = cartLists.find((product) => product.id === id);

        // 1. 총 상품금액 계산
        prdPrice += getOrderItemTotalAmount(targets.order_item);
        // 2. 총 할인금액 계산
        salePrice += getDiscountAmount('cart', targets.order_item);
        // 3. 총 배송비 계산
        // deliveryPrice += getOrderItemShippingFee(targets.order_item)
        // deliveryPrice = 3000
      });
      if (prdPrice === 0) {
        deliveryPrice = 0;
      } else {
        if (prdPrice >= 40000) {
          deliveryPrice = 0;
        } else {
          deliveryPrice = getOrderItemShippingFee(
            buyTargets.map((x) => x.id),
            cartLists
          );
        }
      }
    }
    // deliveryPrice = prdPrice === 0 ? 0 : deliveryPrice
    // let calcDeliveryPrice = prdPrice < 40000 ? deliveryPrice : 0
    setAllOrderPrice(prdPrice + deliveryPrice);
    setAllProdPrice(prdPrice);
    setAllSalePrice(salePrice);
    setAllDeliveryPrice(deliveryPrice);
    // 4. 총 결제금액 계산
    setAllPurchasePrice(prdPrice + deliveryPrice - salePrice);
  };

  useEffect(() => {
    onChangePrices();
  }, [selectedProducts, cartLists]);

  return (
    <NormalPage bgColor="white" xlBgColor="gray0" paddingX={0}>
      <FullWidthPage bgColor="transparent">
        <div className="flex flex-col gap-[24px] pt-[25px] xl:flex-row">
          <section className="flex flex-row justify-center w-full px-[20px] xl:basis-3/4">
            {cartLists && selectedProducts && (
              <CartLeft
                selectAll={selectAll}
                cartLists={cartLists}
                selectedProducts={selectedProducts}
                handleSelectAllChange={handleSelectAllChange}
                handleCheckboxChange={handleCheckboxChange}
                onDecrease={onDecrease}
                onIncrease={onIncrease}
                handleBuySingleItem={handleBuySingleItem}
                onSingleRemoveItem={onSingleRemoveItem}
                onRemoveAllItems={onRemoveAllItems}
              />
            )}
          </section>
          <section className="w-full xl:basis-1/4">
            <CartRight
              selectedProductsCnt={
                selectedProducts
                  ? selectedProducts.filter((x) => x.isChecked).length
                  : 0
              }
              allOrderPrice={allOrderPrice}
              allProdPrice={allProdPrice}
              allSalePrice={allSalePrice}
              deliveryPrice={allDeliveryPrice}
              allPurchasePrice={allPurchasePrice}
            />
            <div className="p-[16px] bg-white">
              <Buttons
                type="filled"
                color="purple5"
                disabled={selectCnt > 0 ? false : true}
                onClick={() => handleSelectByItems()}
              >
                총 {selectCnt}개 구매하기
              </Buttons>
            </div>
          </section>
        </div>
      </FullWidthPage>
    </NormalPage>
  );
};

export default Cart;
