import React, { MouseEventHandler, useEffect } from 'react';
import TextWinds from '../common/TextWinds';
import { useAppDispatch } from '../../store/hook';
import { closeModal } from '../../store/features/modalSlice';
export interface ModalAlertProps {
  message: string;
}
const ModalAlert = ({ message }: ModalAlertProps) => {
  const dispatch = useAppDispatch();
  const onClose = () => {
    dispatch(closeModal());
  };
  useEffect(() => {
    document.addEventListener('keydown', keyboardHandler);
    return () => {
      document.removeEventListener('keydown', keyboardHandler);
    };
  }, []);

  const keyboardHandler = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };
  return (
    <div className="w-[335px] p-4 bg-white shadow-card mb-40 rounded-md mountAnimation">
      <div>
        {/* {message} */}
        {message.split('\n').map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </div>
      <div className="text-right">
        <TextWinds
          type="button2"
          color="purple5"
          className="cursor-pointer hover:bg-gray2 p-2 rounded-md"
          onClick={onClose}
        >
          확인
        </TextWinds>
      </div>
    </div>
  );
};

export default ModalAlert;
