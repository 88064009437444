import React, { useEffect, useState } from 'react';
import ChannelService from '../api/ChannelService';
import { useAppSelector } from '../store/hook';
import { User } from '../constants/types';
import { deleteCookie } from '../api/utils/util-func';

const ChannelTalk = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  // ChannelService.loadScript();

  const userInfo = useAppSelector((state) => state.my);
  useEffect(() => {
    if (isLoaded) return;

    // 고객 정보가 있을 경우 채널톡에 연동
    if (userInfo.status === 'fulfilled' && userInfo.myInfo) {
      setIsLoaded(true);

      connectChannelTalk(userInfo.myInfo.id);

      ChannelService.updateUser(
        {
          tag: userInfo.myInfo.type,
          memberId: userInfo.myInfo.id,
          profile: {
            email: userInfo.myInfo.email,
            mobileNumber: userInfo.myInfo.phone,
            name: userInfo.myInfo.name,
          },
        },
        function (error: any, user: any) {
          if (error) {
            console.error(error);
          } else {
            // 연동 성공시 user 리턴
          }
        }
      );
    } else {
      connectChannelTalk();
    }
  }, [userInfo.myInfo]);

  return <></>;
};

export default ChannelTalk;

export const connectChannelTalk = (memberId?: string) => {
  ChannelService.boot({
    pluginKey: 'cd6447bf-bcac-40a3-981d-00e46fc876b6',
    language: 'ko',
    memberId,
  });
};

const deleteChannelTalkCookie = () => {
  const channelSessionCookieNumber = document.cookie.match(/ch-session-(\d+)=/);
  /** ch-seession 쿠기가 존재한다면 삭제!! */
  if (channelSessionCookieNumber?.length && channelSessionCookieNumber[1]) {
    deleteCookie(`ch-session-${channelSessionCookieNumber[1]}`, '/');
    // removeCookie(`ch-session-${channelSessionCookieNumber[1]}`, { domain: currentDomain, path: '/' });
  }
};

export const shutdownChannelTalk = () => {
  deleteChannelTalkCookie();
  ChannelService.shutdown();
};
