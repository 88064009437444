import React, { useState } from 'react';
import {
  classGroupType,
  studentStatusType,
  studentType,
} from '../../../constants/types/student-type';
import Buttons from '../../../components/common/Buttons2';
import TextWinds from '../../../components/common/TextWinds';
import { useAppDispatch } from '../../../store/hook';
import { openConfirm, openModal } from '../../../store/features/modalSlice';
import ModalAlert from '../../../components/modal/ModalAlert';
import { useParams } from 'react-router-dom';
import { Drawer } from '@material-tailwind/react';
import ClassAssignment from './extend/classAssignment';
import { putClass } from './func';
import { deleteStudent, putStudentStatus } from '../../../api/utils/lms/lmsApi';

interface StudentStateControlProps {
  status: studentStatusType;
  studentList: studentType[];
  reloadTable: () => void;
}
const StudentStateControl = ({
  status,
  reloadTable,
  studentList,
}: StudentStateControlProps) => {
  const { classId } = useParams();
  const dispatch = useAppDispatch();
  const [openAssignment, setOpenAssignment] = useState(false);

  const ids = studentList.map((item) => item.id);

  const confirmCheckedCount = (): boolean => {
    if (studentList.length === 0) {
      dispatch(
        openModal({ body: <ModalAlert message="학생을 선택해주세요." /> })
      );
      return false;
    }
    return true;
  };

  const makeMsg = (type: '정지' | '삭제' | '수강등록') => {
    const count = studentList.length;

    if (count === 1) {
      return `${studentList[0].name} 학생을 ${type} 하시겠습니까?`;
    } else {
      return `총 ${count}명의 학생을 ${type} 하시겠습니까?`;
    }
  };

  // 학생 상태 변경
  const changeStatus = (status: studentStatusType) => {
    putStudentStatus(classId, ids, status).then(() => {
      reloadTable();
    });
  };

  const stopButtonHandler = () => {
    if (!confirmCheckedCount()) return;

    const msg = makeMsg('정지');

    dispatch(
      openConfirm({
        message: msg,
        onConfirm: () => changeStatus(studentStatusType.INACTIVE),
      })
    );
  };

  const registButtonHandler = () => {
    if (!confirmCheckedCount()) return;

    const msg = makeMsg('수강등록');

    dispatch(
      openConfirm({
        message: msg,
        onConfirm: () => changeStatus(studentStatusType.ACTIVE),
      })
    );
  };

  const deleteButtonHandler = () => {
    if (!confirmCheckedCount()) return;

    const msg = makeMsg('삭제');

    const deleteAction = () => {
      deleteStudent(classId, ids).then(() => reloadTable());
    };

    const checkAgain = () => {
      dispatch(
        openConfirm({
          message:
            '학생 삭제 시 학생의 모든 학습 이력 및 데이터가 삭제되며, 추후 복구가 불가합니다.\n(학생이 속한 모든 클래스에서 일괄 삭제됩니다.)\nㅤ\n정말 삭제하시겠습니까?',
          onConfirm: deleteAction,
        })
      );
    };

    dispatch(
      openConfirm({
        message: msg,
        onConfirm: checkAgain,
      })
    );
  };

  //#endregion

  const registClass = (classNum: classGroupType) => {
    putClass(classId, {
      classGroupId: classNum.id,
      studentIds: ids,
    }).then(() => {
      reloadTable();
    });
  };

  return (
    <div>
      <div className="flex items-center gap-x-[22px] mx-5 py-2 xl:justify-end	 xl:mx-0">
        <div className="flex items-center gap-x-3">
          <TextWinds type="content_body4" color="gray7" className="w-[68px]">
            {studentList.length}명 선택됨
          </TextWinds>
        </div>
        <div className="flex gap-x-2">
          {status === studentStatusType.ACTIVE && (
            <Buttons
              variant="filled"
              color="orange4"
              type="secondary"
              className="whitespace-nowrap text-sm"
              onClick={() => stopButtonHandler()}
            >
              정지하기
            </Buttons>
          )}
          {status === studentStatusType.ACTIVE && (
            <Buttons
              variant="outlined"
              color="orange4"
              type="secondary"
              className="whitespace-nowrap text-sm"
              onClick={() => {
                if (!confirmCheckedCount()) return;
                setOpenAssignment(true);
              }}
            >
              반 배정
            </Buttons>
          )}
          {(status === studentStatusType.INACTIVE ||
            status === studentStatusType.PENDING) && (
            <Buttons
              variant="filled"
              color="purple5"
              type="secondary"
              className="whitespace-nowrap text-sm"
              onClick={() => registButtonHandler()}
            >
              수강등록
            </Buttons>
          )}
          {(status === studentStatusType.INACTIVE ||
            status === studentStatusType.PENDING) && (
            <Buttons
              variant="outlined"
              color="red5"
              type="secondary"
              className="whitespace-nowrap text-sm"
              onClick={() => deleteButtonHandler()}
            >
              삭제하기
            </Buttons>
          )}
        </div>
      </div>
      <Drawer
        placement="bottom"
        open={openAssignment}
        onClose={() => setOpenAssignment(false)}
        overlay={true}
        size={485}
        className="w-full lg:w-1/2 items-center left-0 lg:left-1/4 right-1/4 px-5 py-2.5 rounded-[8px] overflow-y-auto max-h-[100%]"
      >
        <ClassAssignment
          closeDrawerBottom={() => setOpenAssignment(false)}
          action={registClass}
        />
      </Drawer>
    </div>
  );
};

export default StudentStateControl;
