import React from 'react';

const IconAward = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_20282_20940)">
        <path
          d="M9.99984 12.4997C13.2215 12.4997 15.8332 9.888 15.8332 6.66634C15.8332 3.44468 13.2215 0.833008 9.99984 0.833008C6.77818 0.833008 4.1665 3.44468 4.1665 6.66634C4.1665 9.888 6.77818 12.4997 9.99984 12.4997Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.84183 11.5747L5.8335 19.1664L10.0002 16.6664L14.1668 19.1664L13.1585 11.5664"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_20282_20940">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconAward;
