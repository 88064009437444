import React, { useEffect, useState } from 'react';
import TextWinds from '../../../../components/common/TextWinds';
import { Table } from 'antd';
import DatePicker from 'react-datepicker';
import HFbutton from '../../../../components/common/input/HFbutton';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  getClassGroupFilter,
  getLmsStudnet,
  getTodoVoucher,
  postTodoAssign,
} from '../../../../api/utils/lms/lmsApi';
import { columnsStudent } from './columns';
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import { calcDateClone, toDay } from '../../../../api/utils/util-func';
import { openAlert, openModal } from '../../../../store/features/modalSlice';
import { ToDoMgmtSubType } from '../../../../enum';
import ModalTicketConfirm from '../../../../components/modal/ModalTicketConfirm';
import { setPurchaseTicketLink } from '../../../../api/utils/lms/ExtendSetBtnLink';
import { selectLmsLists } from '../../../../store/features/lmsSlice';
import LmsSelect, {
  LmsMultiSelect,
  toLmsSelectOptions,
} from '../../../../components/lms/LmsSelect';
import { LmsClassGroupType } from '../../../../constants/types/lms-type';
import { LmsStudentProps } from '../../../../constants/types';

const RegisterTodoDetail = () => {
  const dispatch = useAppDispatch();
  const nav = useNavigate();

  const { classId } = useParams();
  const lmsLists = useAppSelector(selectLmsLists);
  const subType = useParams().subType.toUpperCase();
  const [params, setParams] = useSearchParams();
  const idParams = params.get('ids');
  const ids = idParams?.split(',').map((id) => +id);
  const type: string = params.get('type');

  const [classGroup, setClassGroup] = useState<LmsClassGroupType[]>([]);
  const [selectedClassGroup, setSelectedClassGroup] = useState<string[]>([]);
  const [students, setStudents] = useState<LmsStudentProps[]>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    calcDateClone(new Date(), { month: 1 })
  );

  useEffect(() => {
    getLmsStudnet(classId).then((res) => {
      setStudents(res);
    });
    getClassGroupFilter(classId).then((res) => {
      setClassGroup(res);
    });
  }, []);

  // 시작 날짜 변경 핸들러
  const startDateHandler = (date: Date) => {
    // 과거에서 시작 불가 처리
    if (date < toDay()) {
      dispatch(openAlert({ message: '지나간 날짜는 선택할 수 없습니다.' }));
      return;
    }

    // 종료일 이후로 시작일 설정 불가 처리
    if (date > endDate) {
      dispatch(
        openAlert({ message: '시작일은 종료일 이전으로 설정해주세요.' })
      );
      return;
    }
    setStartDate(date);
  };

  // 종료 날짜 변경 핸들러
  const endDateHandler = (date: Date) => {
    const calcDate = calcDateClone(toDay(date), { day: +1, sec: -1 });

    if (startDate > calcDate) {
      dispatch(openAlert({ message: '종료일은 시작일 이후로 설정해주세요.' }));
      return;
    }
    // 등록일 기준 1년 이내로 설정
    const afterYear = calcDateClone(toDay(), { year: +1 });

    if (afterYear < calcDate) {
      dispatch(
        openAlert({
          message:
            '학생에게 부여한 할 일은 등록일로부터 최대 1년간 유효합니다.',
        })
      );
      return;
    }

    setEndDate(calcDate);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const submitHandler = () => {
    if (selectedRowKeys.length === 0) {
      dispatch(openAlert({ message: '학생을 선택해주세요.' }));
      return;
    }

    todoAddEventHandle(
      ids,
      startDate,
      endDate,
      selectedRowKeys.map((x) => +x)
    );
  };

  const todoAddEventHandle = async (
    todoIds: number[],
    fromDate: any,
    toDate: any,
    studentIds: number[]
  ) => {
    // 1. 과제이용권 필요한 과제 등록 시 (이용권있을때)
    // 2. 과제이용권 필요한 과제 등록 시 (이용권없을때)
    // 3. 과제이용권 필요한 과제 등록 시 (이용권있으나 부족할때)
    if (
      ToDoMgmtSubType.RECITATION === subType ||
      ToDoMgmtSubType.JEBSPL === subType
    ) {
      try {
        getTodoVoucher(lmsLists.course?.id).then((res) => {
          let voucherLists = res;
          const deductionVoucher = todoIds.length * studentIds.length;
          const remainingTickets = voucherLists.find(
            (x) => x.type === type
          ).voucherCount;
          let message;
          let confirmText;
          let status: boolean = true;
          if (remainingTickets === 0) {
            message = `과제 배포를 위한 이용권이\n없습니다.\n지금 이용권을 구매하세요.`;
            confirmText = `구매하기`;
            status = false;
          } else if (remainingTickets < deductionVoucher) {
            message = `과제 배포를 위한 이용권이 ${deductionVoucher}개 차감됩니다.\n(현재 이용권 ${remainingTickets}개)\n부족한 이용권을 지금 구매하세요.`;
            confirmText = `구매하기`;
            status = false;
          } else {
            message = `과제 배포를 위한 이용권이 ${deductionVoucher}개 차감됩니다.\n(현재 이용권 ${remainingTickets}개)\n할 일을 등록하시겠습니까?`;
            confirmText = `등록하기`;
          }
          dispatch(
            openModal({
              body: (
                <ModalTicketConfirm
                  title="확인"
                  message={message}
                  cancelText="취소"
                  confirmText={confirmText}
                  onConfirm={() =>
                    buttonAction(todoIds, fromDate, toDate, studentIds, status)
                  }
                />
              ),
            })
          );
        });
      } catch (error) {}
    } else {
      buttonAction(todoIds, fromDate, toDate, studentIds, true);
    }
  };

  const buttonAction = async (
    todoIds: number[],
    fromDate: any,
    toDate: any,
    studentIds: number[],
    status: boolean
  ) => {
    if (!status) {
      const targetUrl = setPurchaseTicketLink(type);
      window.open(targetUrl, '_blank');
    } else {
      const params = {
        toDoIds: todoIds,
        studentIds: studentIds,
        fromDate: fromDate,
        toDate: toDate,
      };
      postTodoAssign(classId, params).then((res) => {
        if (res.statusCode === 200) {
          dispatch(openAlert({ message: '할 일이 등록되었습니다.' }));
          nav('?stage=todo');
        } else {
          dispatch(openAlert({ message: '할 일 등록에 실패했습니다.' }));
          console.error(res);
        }
      });
    }
  };

  return (
    <div className="bg-white rounded px-[20px] py-[16px] h-full overflow-hidden">
      <section className="max-h-max mb-2.5">
        <div className="flex flex-row items-center justify-between py-2.5 px-0">
          <TextWinds type="title_h3">할 일 등록</TextWinds>
        </div>
      </section>
      <section className="xl:h-3/4 mb-2.5 h-full overflow-hidden">
        <div className="gap-2.5 h-full overflow-hidden">
          <div className="flex-1 flex flex-col lg:flex-row justify-between py-2.5 px-0">
            <div className="flex flex-row items-start gap-2.5">
              <TextWinds type="title_h4" color="purple5">
                1. 학생 선택
              </TextWinds>
              <TextWinds type="title_h5">
                진행 할 학생을 등록해주세요.
              </TextWinds>
            </div>
            <div className="flex flex-row items-center gap-3">
              <LmsMultiSelect
                label={'반 선택'}
                options={toLmsSelectOptions(classGroup, 'id', 'name', 'name')}
                onChange={(list) => setSelectedClassGroup(list)}
                className="w-[200px]"
              />
              <TextWinds type="content_bold" color="gray7">
                {selectedRowKeys.length}명 선택됨
              </TextWinds>
            </div>
          </div>

          <Table
            columns={columnsStudent}
            dataSource={
              selectedClassGroup.length > 0
                ? students.filter((x) => {
                    const StudentGroupList = x.classInfos.map(
                      (x) => x.classGroup?.name
                    );

                    return StudentGroupList.every((x) =>
                      selectedClassGroup.includes(x)
                    );
                  })
                : students
            }
            rowKey={(record) => record.id}
            rowSelection={{ type: 'checkbox', ...rowSelection }}
            pagination={false}
            scroll={{ y: 400 }}
            size="small"
          />

          <div className="flex flex-row items-center justify-between py-2.5 px-0">
            <TextWinds type="title_h4" color="purple5">
              2.기간 설정
            </TextWinds>
          </div>

          <div className="self-stretch flex flex-row items-start justify-start gap-[12px] px-5">
            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
              <TextWinds type="content_body5">시작일 입력</TextWinds>
              <DatePicker
                selected={startDate}
                onChange={startDateHandler}
                className="bg-gray0 text-purple5"
                dateFormat={'yyyy-MM-dd'}
              />
            </div>
            <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
              <TextWinds type="content_body5">종료일 입력</TextWinds>
              <DatePicker
                selected={endDate}
                onChange={endDateHandler}
                className="bg-gray0 text-purple5"
                dateFormat={'YYYY-MM-dd'}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="max-h-max w-full">
        <div className="text-center mb-3">
          ※ 학생에게 부여한 할 일은 등록일로부터 최대 1년간 유효합니다.
        </div>

        <div className="flex gap-2">
          <HFbutton type="Outlined" onClick={() => nav('?stage=todo')}>
            취소
          </HFbutton>
          <HFbutton onClick={() => submitHandler()}>등록하기</HFbutton>
        </div>
      </section>
    </div>
  );
};

export default RegisterTodoDetail;
