import { createAsyncThunk, createSlice, isRejectedWithValue, PayloadAction } from "@reduxjs/toolkit"
import { ChosenVariant, CommonResProps, CourseInfoType, CourseOptionType, CoursePlanInfoType, EnrollOption, OptionConstraint, OptionProduct, OptionVariant, Product, ProductContent, ProductCourse } from "../../constants/types"
import instance from "../../api/axios/utils.ts/instance"
import { RootState } from ".."
import { AxiosParamType } from "."
import { legacyCourseListType, legacyCourseType } from "../../constants/legacyTypes"
import { CourseType, ProductContentType } from "../../enum"
import { stringify } from "querystring"

interface stateObj {
    status: string,
    corseLists: CommonResProps<CourseInfoType | CourseInfoType[]>,
    courseInfo: CourseInfoType,
}
const initialState: stateObj = {
    status: '',
    corseLists: {
        page: null,
        result: null,
    },
    courseInfo: null,
}

const DataParser = (data: legacyCourseType): CourseInfoType => {

    // const courseType = (():string=>{
    //     switch(data.product_sub_type){
    //         case 'HOMESCHOOL':
    //             return CourseType.TRAINING
    //             // return CourseType.TEACHING
    //         case 'WORSHIP':
    //             // if(data.id ==="aFZmALebAXzkTkng")
    //             //     return CourseType.LEARNING
    //             // else if(data.id ==="Gs-jeDnUx93geTce") // Hello, Father! Hello, Jesus! [유치부] 코스 보기(★코스 구매시 AD학습법 도서 증정★)
    //             //     return CourseType.TRAINING
    //             // else if(data.id ==="7HpbQCjmNkf266hV") // Who Made the World? Series [초등부] 코스 보기(★코스 구매시 AD학습법 도서 증정★)
    //             //     return CourseType.TRAINING
    //             // else if(data.id ==="nO8efugcVGoFZWQb") // Hello, Father! Hello, Jesus! [유치부] 코스 연장
    //             //     return CourseType.TEACHING
    //             return data.courses[0].course.type
    //         case 'LEARN_ON_PHONE':
    //             return CourseType.LEARNING
    //     }
    // })()
    
    const courseType = data?.courses?.length > 1 ? CourseType.TRAINING : data.courses[0].course.type
    // const courseType = CourseType.LEARNING;

    const contents: ProductContent[] = data.contents.map((v) => (
        {
            id: v.id,
            order_idx: v.order_idx,
            type: v.type,
            file_id: v.file_id,
            video_url: v.video_url,
            url_link: v.url_link,
            url_label: v.url_label,
            product_id: data.id,
            file: {
                access_url: v.file?.access_url
            }
        }
    ))

    const courses: ProductCourse[] =
    data.courses.filter((v1) => v1.course.type === courseType).map((v2) => ({
        course_model_id: v2.course_model_id,
        training_course_id: v2.training_course_id,
        course_id: v2.course_id,
        course: {
            id: v2.course.id,
            name: v2.course.name,
            type: v2.course.type,
            required_completion_percent: v2.course.required_completion_percent,
        },
    }))

    // const plans: OptionConstraint[] =
    // data.option_products.filter((v1) => (
    //     v1.option_constraints[0].constraint_course_id === courses[0].course.id
    // )).map((v2) => ({
    //     id: v2.id,
    //     name: courses.filter((x) => x.course?.id === v2.option_constraints[0].constraint_course_id)[0].course?.name,
    //     description: '',
    //     price: data.courses.filter((v1) => v1.course?.type === courseType).filter((x) => x.course?.id === v2.option_constraints[0].constraint_course_id)[0].enroll_options[0].price,
    //     period: data.courses.filter((v1) => v1.course?.type === courseType).filter((x) => x.course?.id === v2.option_constraints[0].constraint_course_id)[0].enroll_options[0].period,
    //     option_product_id: v2.option_product_id,
    //     constraint_course_id: v2.option_constraints[0].constraint_course_id,
    //     constraint_period: v2.option_constraints[0].constraint_period,
    //     min_quantity: v2.option_constraints[0].min_quantity
    // }))
    
    // course.type === 'TRAINING'
    // 1. data.id === courses[0].product_id === option_products.parent_product_id
    // 2. option_products.id === option_products.option_constraints[0].option_product_id
    // 3. courses(course.type === 'TRAINING').course.id === option_products.option_constraints[0].constraint_course_id
    // 3. option_products.option_product_id = option_products.option_product.id
    // 4. option_products
    // option_constraints option_product_id는 option_products 배열 객체의 id와 같은 값을 찾아야한다.

    const optionProduct: OptionProduct[] = data.option_products.filter((v1) => v1.option_constraints[0].constraint_course_id === courses[0].course.id);
    
    // const options: ChosenVariant[] = 
    // data.option_products.filter((v1) => v1.id === plans[0].id)?.option_product.option_variants
    // .map((v2) => ({
    //         id: v2.id,
    //         product_id: v2.product_id,
    //         name: v2.name,
    //         additional_price: v2.additional_price,
    //         inventory_code: v2.inventory_code,
    //         is_active: v2.is_active,
    //         jebspl_subscription_period: v2.jebspl_subscription_period
    //     })
    // )

    const filtered = data.option_products.filter((v1) => v1.option_constraints[0].constraint_course_id === courses[0]?.course.id)
    const options:ChosenVariant[] = filtered
        .map((v2)=>{
            const v3 = v2.option_product.option_variants[0]
            return {
                id: v3.id,
                product_id: v3.product_id,
                name: v3.name,
                additional_price: v3.additional_price,
                inventory_code: v3.inventory_code,
                is_active: v3.is_active,
                jebspl_subscription_period: v3.jebspl_subscription_period
            }
        })

    const enrolls: EnrollOption[] = 
    data.courses.find((v1) => v1.course.type === courseType).enroll_options
    .map((v2) => ({
        id: v2.id,
        product_course_id: v2.product_course_id,
        period: v2.period,
        price: v2.price,
        is_active: v2.is_active
    }))

    const rtn: CourseInfoType = {
        id: data.id,
        name: data.name,
        description: data.description,
        thumbnail_url: data.thumbnail.access_url,
        current_price: data.current_price,
        origin_price: data.origin_price,
        base_quantity: data.base_quantity,
        courses: data.courses,
        contents: contents,
        option_products: optionProduct,
        option: options,
        detail_imgs: data.contents.map((v) => v.file?.access_url),
        // plans: data.option_products.filter((v) => (v.parent_product_id === data.id))
        //     .reduce<OptionConstraint[]>((acc, cur) => {
        //         acc.push({
        //             id: cur.option_constraints[0].id,
        //             name: cur.option_product.name,
        //             description: cur.option_product.description,
        //             price: data.courses.find((x) => x.course_id === cur.option_constraints[0].constraint_course_id).enroll_options[0].price,
        //             period: data.courses.find((x) => x.course_id === cur.option_constraints[0].constraint_course_id).enroll_options[0].period,
        //             option_product_id: cur.option_constraints[0].option_product_id,
        //             constraint_course_id: cur.option_constraints[0].constraint_course_id,
        //             constraint_period: cur.option_constraints[0].constraint_period,
        //             min_quantity: cur.option_constraints[0].min_quantity,
        //         })
        //         return acc
        //     }, []),
        // plans: plans,
        enrolls: enrolls,
        product_type: data.product_type,
        product_sub_type: data.product_sub_type,
    }
    /**
    const rtn: CourseInfoType ={
        id: data.id,
        name: data.name,
        description:data.description,
        product_sub_type: data.product_sub_type,
        thumbnail_url: data.thumbnail.access_url,
        detail_imgs: data.contents?data.contents.map((v)=>v.file?.access_url):[],
        current_price: data.current_price,
        plans: plans,
        origin_price: data.origin_price,
        base_quantity:data.base_quantity,
        contents: [],
        option: data.option_products.filter((v)=>v.option_constraints[0].constraint_course_id===id)
            .reduce<ChosenVariant[]>((acc, cur) => {
                acc.push({
                    id: cur.option_product.id,
                    name: cur.option_product.name,
                    product_id: cur.id,
                    additional_price: cur.option_product.current_price,
                    inventory_code: cur.option_product.option_variants[0].inventory_code,
                    is_active: cur.option_product.option_variants[0].is_active,
                })
                return acc
            },[]),
    }
    */
    return rtn;
}

const DataListParser = (list: legacyCourseListType[]):CourseInfoType[] => {
    return list.map((data) => {
        const rtn: CourseInfoType ={
            id: data.id,
            name: data.name,
            description: data.description,
            // product_sub_type: data.product_sub_type,
            thumbnail_url: data.thumbnail.access_url,
            current_price: data.current_price,
            origin_price: data.origin_price,
            base_quantity: 0,
            // plans: [], // 삭제 가능 ShopInfoProps > plans 제거
            contents: [],
            option: [],
            detail_imgs: [],
            courses: [],
            enrolls: [],
            product_type: data.product_type,
            product_sub_type: data.product_sub_type
        }
        return rtn;
    })
}

export const getCourseLists = createAsyncThunk<CommonResProps<CourseInfoType[]>, AxiosParamType>(
    'asyncThunk/getCourseLists',
    async ( { url, params } ) => {
    try {
        const response = await instance.get(url, {params});
        response.data.result = DataListParser(response.data.result)
        return response.data;
    } catch(error) {
        console.error('[axios] getCourseLists : ', error)
        return isRejectedWithValue(error)
    }
});
export const getCourseInfo = createAsyncThunk<CourseInfoType, AxiosParamType>(
    'asyncThunk/getCourseInfo',
    async ({ url }: AxiosParamType) => {
        try {
        const response = await instance.get(url);
        response.data = DataParser(response.data)
        return response.data;
    } catch(error) {
        console.error('[axios] getCourseInfo : ', error)
        return isRejectedWithValue(error)
    }
});

export const courseSlice = createSlice({
    name:'course',
    initialState,
    reducers:{
        reset(state){
            state.corseLists = {
                page: null,
                result: null,
            }
        },
    },
    extraReducers: (builder) => {
        builder
        // 코스 목록 조회
        .addCase(getCourseLists.pending, (state) => {
            state.status = 'pending';
        })
        .addCase(getCourseLists.fulfilled, (state, action: PayloadAction<CommonResProps<CourseInfoType[]>>) => {
            state.status = 'fullfilled';
            state.corseLists = action.payload;
        })
        .addCase(getCourseLists.rejected, (state) => {
            state.status = 'rejected';
        })
        // 코스 상세 조회
        .addCase(getCourseInfo.pending, (state) => {
            state.status = 'pending';
        })
        .addCase(getCourseInfo.fulfilled, (state, action: PayloadAction<CourseInfoType>) => {
            state.status = 'fullfilled';
            state.courseInfo = action.payload;
        })
        .addCase(getCourseInfo.rejected, (state) => {
            state.status = 'rejected';
        })
    }
});


export const { reset } = courseSlice.actions
export const selectCourseLists = (state: RootState) => state.course.corseLists
export const selectCourseInfo = (state: RootState) => state.course.courseInfo
export default courseSlice.reducer