export type ModalPopupType = {
  img: string;
  link: string;
  type?: '_blank';
};

export const popupData: ModalPopupType = {
  img: process.env.PUBLIC_URL + 'images/home/popup/20240716.jpg',
  link: 'https://jebs-notice.oopy.io/3445e325-9c88-40d5-a77f-a862a8e46af5',
};
