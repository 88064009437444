import React, { useContext } from 'react';
import TextWinds from '../../../components/common/TextWinds';
import { studentBadgeType } from '../../../constants/types/student-type';
import { StudentProfileContext } from '../profile/StudentProfileContext';
import { useAppDispatch } from '../../../store/hook';
import { closeModal, openModal } from '../../../store/features/modalSlice';
import StudentCertificateImg from './StudentCertificateImg';
import HFbutton from '../../../components/common/input/HFbutton';
interface StudentBadgeItemProps {
  badge: studentBadgeType;
}
const StudentBadgeItem = ({ badge }: StudentBadgeItemProps) => {
  // const dispatch = useAppDispatch();
  const { studentProfile } = useContext(StudentProfileContext);

  const openCertification = () => {
    alert('준비중인 기능입니다.');
    return;
    // dispatch(
    //   openModal({
    //     body: (
    //       <StudentCeertificateModal
    //         englishName={studentProfile?.englishName}
    //         level={Number(badge.level)}
    //         date={new Date(badge.passDate).toLocaleDateString()}
    //       />
    //     ),
    //   })
    // );
  };

  return (
    <div
      className="flex flex-col items-center"
      // onClick={() => openCertification()}
    >
      <img src={badge.imagePath} className="w-[70px]" />
      <TextWinds type={'title_h3'} children={badge.name} />
      <TextWinds
        type={'caption1'}
        children={new Date(badge.achieveDate).toLocaleDateString()}
      />
    </div>
  );
};

const StudentCeertificateModal = ({
  englishName,
  level,
  date,
}: {
  englishName: string;
  level: number;
  date: string;
}) => {
  const dispatch = useAppDispatch();
  return (
    <div className="bg-white p-4">
      <StudentCertificateImg name={englishName} level={level} date={date} />
      <HFbutton onClick={() => dispatch(closeModal())}>닫기</HFbutton>
    </div>
  );
};

export default StudentBadgeItem;
