import React, { useEffect } from 'react';
import CourseCard from './CourseCard';
import { CourseInfoType } from '../../constants/types';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import {
  getCourseLists,
  selectCourseLists,
} from '../../store/features/courseSlice';
import { AppProductListOrderBy, ProductSubType, ProductType } from '../../enum';

// const CourseList = ({ datas }: { datas: CourseInfoType[] }) => {
const CourseList = ({ productSubType,max }: { productSubType: ProductSubType,max?:number }) => {
  const dispatch = useAppDispatch();
  const courseLists = useAppSelector(selectCourseLists);
  const params = {
    // 전체 : producttype => ProductType.STANDARD
    // 일반도서 : producttype => ProductType.STANDARD, subtype => ProductSubType.TEXTBOOK + ProductSubType.Book
    // 굿즈 : producttype => ProductType.STANDARD, subtype => ProductSubType.GOODS
    product_type: ProductType.COURSE,
    product_sub_type: productSubType,
    order_by: AppProductListOrderBy.RECOMMENDED,
  };
  useEffect(() => {
    dispatch(getCourseLists({ url: '/v1/product', params: params }));
  }, []);
  return (
    courseLists && courseLists.result &&
    <div className="grid grid-cols-1 gap-8 mb-8 w-full flex-wrap p-4 sm:grid-cols-2 lg:grid-cols-4 lg:p-0">
      {(courseLists.result as CourseInfoType[])
        .filter((data) => data.product_sub_type == productSubType)
        .slice(0,max?max:undefined)
        .map((data) => (
          <CourseCard data={data} key={data.id} />
        ))}
    </div>
  );
};

export default CourseList;
