import React from 'react';
import FullWidthPage from '../../../components/layout/FullWidthPage';
import { useNavigate } from 'react-router-dom';
import Image1 from './staticDetailPage/images/review1-b.png';
import Image2 from './staticDetailPage/images/review2-b.png';
import Image3 from './staticDetailPage/images/review3-a.png';
import Image4 from './staticDetailPage/images/review4-a.png';
import Image5 from './staticDetailPage/images/review5-a.png';
import Image6 from './staticDetailPage/images/review6-a.png';
import Image7 from './staticDetailPage/images/review7-a.png';
import Image8 from './staticDetailPage/images/review8-d.png';

type ReviewItemType = {
  num: number;
  title: string;
  img: string;
};
const ReviewMain = () => {
  const DUMMY: ReviewItemType[] = [
    {
      num: 1,
      title: '하나님과 동행하는 지도자 되기 프로젝트는 계속 될 것입니다.',
      img: Image1,
    },
    {
      num: 2,
      title: '수요일에도 교회에 모여 영어로 말씀을 묵상해요!',
      img: Image2,
    },
    {
      num: 3,
      title: '인도에서도 울려 퍼지는 아이들의 말씀 암송!',
      img: Image3,
    },
    {
      num: 4,
      title: '느리지만 말씀과 영어로 바른 길을 걸어요!',
      img: Image4,
    },
    {
      num: 5,
      title: '영어로 성경을 가르치니 선생님인 저에게도 지혜가 샘솟아요!',
      img: Image5,
    },
    {
      num: 6,
      title: '기도의 삼박자로 은혜가 배가 되는 젭스홈스쿨!',
      img: Image6,
    },
    {
      num: 7,
      title: '젭스홈스쿨을 통해 영어로 말씀을 공부하며 비전을 품는 대안학교',
      img: Image7,
    },
    {
      num: 8,
      title: '엄마 손 잡고 첫 수업하던 아이의 확실한 비포&애프터',
      img: Image8,
    },
  ];
  return (
    <>
      <section
        className="h-40 md:h-[261px] bg-gradient-to-r from-purple6  to-spotViolet text-white
          text-[28px] font-bold leading-9 flex flex-col"
      >
        <div className="w-full max-w-[1176px] m-auto pl-5 text-xl md:text-2xl">
          <div>크리스천 학부모, 선생님들은</div>
          <div>젭스 프로그램을 이렇게 사용하고 계세요!</div>
        </div>
      </section>
      <FullWidthPage>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-16  p-4 gap-1 gap-y-[58px]">
          {DUMMY.map((data, index) => (
            <ReviewItem
              key={index}
              num={data.num}
              title={data.title}
              img={data.img}
            />
          ))}
        </div>
      </FullWidthPage>
    </>
  );
};

const ReviewItem = ({
  num,
  title,
  img,
}: {
  num: number;
  title: string;
  img: string;
}) => {
  const nav = useNavigate();
  return (
    <div
      onClick={() => nav('/review/details/' + num)}
      className="flex flex-col gap-[10px] border border-gray2 rounded cursor-pointer hover:shadow-card"
    >
      <div className="w-full h-auto">
        <img
          src={img}
          alt={`review${num}`}
          className="object-cover rounded aspect-video "
        />
      </div>
      <div className="p-2">{title}</div>
    </div>
  );
};

export default ReviewMain;
