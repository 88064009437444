import React from 'react';
import HFbutton from './HFbutton';
import { useNavigate } from 'react-router-dom';

interface HFLinkProps {
  to: string;
  body: React.ReactNode;
  height?: number;
}
const HFLink = ({ to, body, height }: HFLinkProps) => {
  const nav = useNavigate();
  return (
    <HFbutton height={height} onClick={() => nav(to)}>
      {body}
    </HFbutton>
  );
};

export default HFLink;
