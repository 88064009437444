import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import BoostUpDetail from './dashboard/boostup/boostupdetail';
import VimeoContentView from './classmgmt/childpages/VimeoContentsView';
import LmsLayout from '../../components/lmslayout';
import LmsDashboard from './lmsdashboard';
import CourseMgmt from './classmgmt/coursemgmt/CourseMgmt';
import TakeClass from './classmgmt/takeclass/takeclass';
import TodoMgmt from './classmgmt/todomgmt/todomgmt';
import ReferenceRoom from './classmgmt/referenceroom/referenceroom';
import NoticeDetail from './noticemgmt/noticedetail';
import NoticeEditor from './noticemgmt/noticeeditor';
import Signupmgmt from './studentmgmt/signupmgmt';
import LmsMigrationPage from './migration/LmsMigrationPage';
import StudentDetail from './studentmgmt/StudentDetail';
import ClassSetting from './classsetting/ClassSetting';
import ChannelService from '../../api/ChannelService';
import RegisterTodoDetail from './classmgmt/todomgmt/RegisterTodoDetail';

const RouteLms = () => {
  useEffect(() => {
    ChannelService.hideChannelButton();
    return () => {
      ChannelService.showChannelButton();
    };
  }, []);

  return (
    <Routes>
      {/* base "/lms" */}

      {/* 부스트업 상세페이지 */}
      <Route
        path="/boostup/detail/:classId/:boostupId"
        element={<BoostUpDetail />}
      />

      {/* Vimeo Content View */}
      <Route
        path="/take-class/vimeo/:contentId"
        element={<VimeoContentView />}
      />

      {/* LMS */}
      <Route element={<LmsLayout />}>
        {/* 대쉬보드 */}
        <Route index path="/dashboard/:classId" element={<LmsDashboard />} />

        {/* 코스관리 */}
        <Route path="/course-management/:classId" element={<CourseMgmt />} />

        {/* 수업하기 */}
        <Route path="/take-class/:classId" element={<TakeClass />} />

        {/* 할일 관리 */}
        <Route
          path="/todo-management/:subType/:classId"
          element={<TodoMgmt />}
        />

        {/* 할일 등록 */}
        <Route
          path="/todo/:subType/:classId/regist/"
          element={<RegisterTodoDetail />}
        />

        {/* 자료실 */}
        <Route path="/reference-room/:classId" element={<ReferenceRoom />} />

        {/* 공지 상세 */}
        <Route path="/notice/detail/:detailId" element={<NoticeDetail />} />

        {/* 게시물 작성 */}
        <Route path="/notice/editor" element={<NoticeEditor />} />

        {/* 학생 관리 */}
        <Route path="/signup-management/:classId" element={<Signupmgmt />} />

        {/* 학생 상세 */}
        <Route
          path="/signup-management/:classId/:studentId"
          element={<StudentDetail />}
        />

        {/* 학생 가져오기 [ 구젭스 ] */}
        <Route path="/migration/:classId" element={<LmsMigrationPage />} />

        {/* 클래스 설정 */}
        <Route path="/class-setting/:classId" element={<ClassSetting />} />
      </Route>
    </Routes>
  );
};

export default RouteLms;
