import TextWinds from '../../../components/common/TextWinds';
import '../../../styles/mypage/mypage.css';
import { orderDetailType } from '../../../constants/types/mypage-type';
import { numberWithCommas } from '../../../api/utils/format/numbers';
import { getDiscountAmount } from '../../../api/utils/order-item-total-amount';

const OrderInfo = ({ orderDetail }: { orderDetail: orderDetailType }) => {
  // const location = useLocation();
  // const orderDetail = location.state;
  const discountAmount = getDiscountAmount(
    'mypage',
    orderDetail.template.order_items
  );
  const pointUsed = orderDetail.point_used ? orderDetail.point_used : 0;
  return (
    <div className="flex flex-col gap-y-4 px-3 py-[14px] bg-gray0">
      <TextWinds type="sub_title01">주문 정보</TextWinds>
      <div>
        <div className="flex justify-between items-center mb-[10px]">
          <TextWinds type="content_body4" color="gray7">
            주문번호
          </TextWinds>
          <TextWinds type="content_body4">
            {orderDetail.template.order_reference_id}
          </TextWinds>
        </div>
        <div className="flex justify-between items-center">
          <TextWinds type="content_body4" color="gray7">
            주문일시
          </TextWinds>
          <TextWinds type="content_body4">
            {new Date(orderDetail.created_at).toLocaleString()}
          </TextWinds>
        </div>
      </div>
      <div className="flex flex-col gap-y-[10px]">
        <div className="flex flex-col gap-y-[10px] pb-[10px] border-b border-gray-500">
          <div className="flex justify-between items-center">
            <TextWinds type="content_body4">주문금액</TextWinds>
            <TextWinds type="content_body4">
              {numberWithCommas(
                orderDetail.template.total_price + discountAmount
              )}{' '}
              원
            </TextWinds>
          </div>
          <div className="flex justify-between items-center detail-dot">
            <TextWinds type="content_body4" color="gray7">
              상품금액
            </TextWinds>
            <TextWinds type="content_body4">
              {numberWithCommas(
                orderDetail.template.total_price -
                  orderDetail.template.shipping_fee +
                  discountAmount
              )}{' '}
              원
            </TextWinds>
          </div>
          <div className="flex justify-between items-center detail-dot">
            <TextWinds type="content_body4" color="gray7">
              배송비
            </TextWinds>
            <TextWinds type="content_body4">
              {numberWithCommas(orderDetail.template.shipping_fee)} 원
            </TextWinds>
          </div>
        </div>
        <div className="flex flex-col gap-y-[10px] pb-[10px] border-b border-gray-500">
          <div className="flex justify-between items-center">
            <TextWinds type="content_body4">할인금액</TextWinds>
            <TextWinds type="content_body4">
              {numberWithCommas(discountAmount + pointUsed)} 원
            </TextWinds>
          </div>
          <div className="flex justify-between items-center detail-dot">
            <TextWinds type="content_body4" color="gray7">
              제품 할인
            </TextWinds>
            <TextWinds type="content_body4">
              {numberWithCommas(discountAmount)} 원
            </TextWinds>
          </div>
          <div className="flex justify-between items-center detail-dot">
            <TextWinds type="content_body4" color="gray7">
              포인트 사용
            </TextWinds>
            <TextWinds type="content_body4">
              {numberWithCommas(pointUsed)} 원
            </TextWinds>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <TextWinds type="content_body3" color="gray7">
          총 결제 금액
        </TextWinds>
        <TextWinds type="content_body3" color="purple5">
          {numberWithCommas(orderDetail.template.total_price - pointUsed)} 원
        </TextWinds>
      </div>
    </div>
  );
};

export default OrderInfo;
