import { Progress } from '@material-tailwind/react';
import { setExtendBtnLink } from '../../../../api/utils/lms/ExtendSetBtnLink';
import { calcRemainingPeriod } from '../../../../api/utils/util-func';
import TextWinds from '../../../../components/common/TextWinds';
import ModalExtendLink from '../../../../components/modal/ModalExtendLink';
import { openModal } from '../../../../store/features/modalSlice';
import { useAppDispatch } from '../../../../store/hook';

interface CourseInfoBoxType {
  courseName: string;
  startDate: Date;
  endDate: Date;
  remainDays: number;
  CourseId: string;
  CourseType: string;
}
const CourseInfoBox = ({
  courseName,
  endDate,
  startDate,
  remainDays,
  CourseId,
  CourseType,
}: CourseInfoBoxType) => {
  const dispatch = useAppDispatch();
  const _startDate = new Date(startDate);
  const _endDate = new Date(endDate);
  const _totalPeriod =
    (_endDate.getTime() - _startDate.getTime()) / (1000 * 60 * 60 * 24);
  // const _remainingPeriod = calcRemainingPeriod(_endDate);
  const _endDateStr = _endDate.toISOString().split('T')[0];

  // 연장 플래그
  const isExtend = remainDays <= 30 ? true : false;

  const { checkType, purchaseLink } = setExtendBtnLink(CourseId);

  const handleExtend = () => {
    dispatch(
      openModal({
        body: (
          <ModalExtendLink
            checkType={checkType}
            type={CourseType}
            message="클래스 연장하기"
            purchaseLInk={purchaseLink}
          />
        ),
      })
    );
  };

  return (
    <div className="border-b border-solid border-gray04 pb-[30px] xl:border-0 xl:top-[32px] xl:left-0 p-8">
      <TextWinds type={'title_h4'} className="pb-[11px]">
        {courseName}
      </TextWinds>
      <dl className="">
        <dt>
          <TextWinds type="title_h6" color="gray7">
            기간
          </TextWinds>
        </dt>
        <dd>
          <TextWinds type="content_body3" color={isExtend ? 'red5' : 'black'}>
            {remainDays}일 남음 ({_endDateStr}까지)
          </TextWinds>
          <Progress
            value={(remainDays / _totalPeriod) * 100}
            color={isExtend ? 'red' : 'blue'}
          />
        </dd>
        {isExtend && (
          <dd
            className="text-red5 text-center border border-red5 p-3 rounded mt-2 cursor-pointer hover:bg-gray2"
            onClick={handleExtend}
          >
            기간 연장하기
          </dd>
        )}
      </dl>
    </div>
  );
};

export default CourseInfoBox;
