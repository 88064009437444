import React, { ReactNode, useEffect } from 'react';
import { useAppDispatch } from '../../../store/hook';
import {
  studentStatusType,
  studentType,
} from '../../../constants/types/student-type';
import StudentStateControl from './StudentStateControl';
import { Pagination, Table } from 'antd';
import columns from './columns';
import { useParams } from 'react-router-dom';
import { openConfirm, openModal } from '../../../store/features/modalSlice';
import { putStudentPassword } from './func';
import TextWinds from '../../../components/common/TextWinds';
import CheckBoxes from '../../../components/common/CheckBoxes';
import { PaginationProps } from '../../../constants/types';
import NewPasswordModal from './popup/NewPasswordModal';

interface StudentListProps {
  status: studentStatusType;
  data: studentType[];
  pageInfo: PaginationProps;
  isLoading: boolean;
  reloadStudentList: () => void;
  changePageInfo: (size: number, page: number) => void;
}
const StudentList = ({
  status,
  data,
  pageInfo,
  isLoading,
  reloadStudentList,
  changePageInfo,
}: StudentListProps) => {
  const dispatch = useAppDispatch();
  const { classId } = useParams<{ classId: string }>();

  const [selectedStudents, setSelectedStudents] = React.useState<studentType[]>(
    []
  );

  useEffect(() => {
    setSelectedStudents([]);
  }, [data]);

  const resetPassword = (id: number) => {
    dispatch(
      openConfirm({
        message: '비밀번호를 초기화 하시겠습니까?',
        onConfirm: () => {
          putStudentPassword(classId, id).then(({ uid, password }) => {
            openNewPasswordModal(uid, password);
          });
        },
      })
    );
  };

  const openNewPasswordModal = (uid: string, pw: string) => {
    dispatch(openModal({ body: <NewPasswordModal uid={uid} pw={pw} /> }));
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedStudents(
        data.filter((item) => selectedRowKeys.includes(item.id))
      );
    },
    columnWidth: 120,
    columnTitle: (originNode: ReactNode) => (
      <>
        <CheckBoxes
          type="full"
          id={`all-999`}
          value={'전체 선택'}
          labelValue={
            <TextWinds type="content_bold" className="px-[6px] py-1">
              전체 선택
            </TextWinds>
          }
          checked={
            selectedStudents.length > 0
              ? selectedStudents.length === data.length
              : false
          }
          // borderWidth={2}
          onChange={(e) => {
            if (!e.target.checked) {
              setSelectedStudents([]);
            } else {
              setSelectedStudents(data.map((item: studentType) => item));
            }
          }}
        />
      </>
    ),
    renderCell(
      checked: boolean,
      record: any,
      index: number,
      originNode: ReactNode
    ) {
      return (
        <CheckBoxes
          type="full"
          checked={selectedStudents.includes(record)}
          // borderWidth={2}
          onChange={(e) => {
            const rows = selectedStudents.includes(record)
              ? selectedStudents.filter((key) => key !== record)
              : [...selectedStudents, record];
            setSelectedStudents(rows);
          }}
        />
      );
    },
  };

  return (
    <div>
      {/* 상태별 컨트롤 버튼 */}
      <StudentStateControl
        status={status}
        reloadTable={reloadStudentList}
        studentList={selectedStudents}
      />

      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns(classId, pageInfo, resetPassword)}
        dataSource={data}
        rowKey={(record) => record.id}
        scroll={{ x: 'max-content' }}
        pagination={false}
        size="middle"
        loading={isLoading}
      />

      <Pagination
        current={pageInfo.page}
        total={pageInfo.total}
        pageSize={pageInfo.pageSize}
        showSizeChanger
        onChange={(page, pageSize) => {
          changePageInfo(pageSize, page);
        }}
        className="mt-5"
      />
    </div>
  );
};

export default StudentList;
