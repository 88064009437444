import React, { useEffect } from 'react';
import ChannelService from '../api/ChannelService';
import HFbutton from '../components/common/input/HFbutton';
import { useAppDispatch } from '../store/hook';
import { setContentInfo } from '../store/features/takeclassSice';
import { getContentsDatas } from '../api/utils/lms/lmsApi';
import { getContentView } from '../api/utils/api/learningApi';

type contentsData = {
  contentsUrl: string;
  contentsType: string;
};

const ContentsView = () => {
  const dispatch = useAppDispatch();
  const [contentsData, setContentsData] = React.useState<contentsData>();
  const [posibleFullScreen, setPosibleFullScreen] = React.useState(false);
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const doc = document.documentElement;

  useEffect(() => {
    if (doc.requestFullscreen) {
      setPosibleFullScreen(true);
    }

    //채널톡 숨기기
    ChannelService.hideChannelButton();
    document.addEventListener('fullscreenchange', onFullscreenChange);

    window.addEventListener('message', (event) => {
      if (event.data.type === 'contentsView') {
        const { classId, contentId } = event.data.contents;
        getTakeclassData(classId, contentId);
      } else if (event.data.type === 'todoView') {
        const { url } = event.data.contents;
        setContentsData({
          contentsUrl: url,
          contentsType: '',
        });
      } else if (event.data.type === 'learningView') {
        const { learningId, lessonId } = event.data.contents;
        getLearningView(learningId, lessonId);
      }
    });

    return () => {
      ChannelService.showChannelButton();
    };
  }, []);

  // 콘텐츠 타입에 따라 링크 가져오기
  const getTakeclassUrl = (contents: any) => {
    switch (contents.type) {
      case 'Vimeo':
        // 비메오 뷰어 페이지로 데이터 전달할 때 리덕스를 사용함.
        dispatch(setContentInfo(contents));
        return `/lms/take-class/vimeo/${contents.id}`;
      case 'HTML':
        return `https://content.jebs.kr/${contents.resourcePath}`;
      case 'Jebspl':
        return contents.jebsplUrl;
    }
  };

  const getLearningView = async (learningId: string, lessonId: string) => {
    const data = await getContentView(learningId, lessonId);

    setContentsData({
      contentsUrl: getLearningViewUrl(data),
      contentsType: data.type,
    });
  };

  const getLearningViewUrl = (data: any) => {
    if ('Vimeo' === data.type) {
      dispatch(
        setContentInfo({
          id: data.id,
          type: 'Vimeo',
          vimeoKey: data.vimeo_key,
        })
      );
      return `/lms/take-class/vimeo/${data.id}`;
    } else if ('HTML' === data.type) {
      return `https://content.jebs.kr/${data.exe_file.resource_path}`;
    } else {
      console.error(`'${data.type}'타입 : 연결 데이터 필요`);
    }
  };

  // 수업하기 id를 통해 콘텐츠 데이터 셋팅
  const getTakeclassData = async (classId: string, contentId: string) => {
    const contents: any = await getContentsDatas(classId, contentId);

    setContentsData({
      contentsUrl: getTakeclassUrl(contents),
      contentsType: contents.type,
    });
  };

  // 전체화면 onoff 이벤트
  const onFullscreenChange = () => {
    setIsFullScreen(!!document.fullscreenElement);
  };

  // 전체화면 설정
  function openFullScreenMode() {
    doc.requestFullscreen();
  }

  if (contentsData === undefined) {
    return <></>;
  }

  const visibleFullScreenBtn =
    posibleFullScreen && !isFullScreen && contentsData.contentsType !== 'Vimeo';

  return (
    <div className="w-screen h-screen">
      {contentsData.contentsUrl === undefined ? (
        <div>비정상적인 접근입니다.</div>
      ) : (
        <>
          <iframe
            src={contentsData.contentsUrl}
            width={'100%'}
            height={'100%'}
            allowFullScreen
            allow="microphone"
          />
          {visibleFullScreenBtn && (
            <div className="fixed top-1 right-1 w-20 h-10">
              <HFbutton height={36} onClick={openFullScreenMode}>
                전체화면
              </HFbutton>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ContentsView;
