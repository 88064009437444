import React from 'react';
import HFinput from '../../../components/common/input/HFinput';
import HFinputPhone from '../../../components/common/input/HFinputPhone';
import HFbutton from '../../../components/common/input/HFbutton';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../store/hook';
import instance from '../../../api/axios/utils.ts/instance';
import {
  closeModal,
  openAlert,
  openModal,
} from '../../../store/features/modalSlice';
import { ApiResponse } from '../../../constants/types';
import { useNavigate } from 'react-router-dom';
import TextWinds from '../../../components/common/TextWinds';
import { handleCopyClipBoard } from '../../../api/utils/util-func';
import { timeLeftCheck, timeLeftToString } from '../../../controllers/useTimer';
import useSmsHook from '../../../controllers/useSmsHook';

const LoginIdPhone = () => {
  type findIdbyphoneDTO = {
    name: string;
    verifySmsId: string;
    phone: string;
    verifyType: string;
  };
  type verifyInfoType = {
    code: string;
    createdAt: Date;
    id: number;
    expiresAt: Date;
  };
  const dispatch = useAppDispatch();
  const navigete = useNavigate();
  const [verifyNumber, setVerifyNumber] = React.useState<string>('');
  const [isConfirm, setIsConfirm] = React.useState<boolean>(false);
  const { sendVerifyNumberWithName, checkVerifyNumber, timeLeft } =
    useSmsHook();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<findIdbyphoneDTO>();

  const name = watch('name');

  const Alert = (msg: string) => {
    dispatch(openAlert({ message: msg }));
  };

  const onSubmit = (data: findIdbyphoneDTO) => {
    const params: findIdbyphoneDTO = {
      name: data.name,
      phone: data.phone.replace(/-/g, ''),
      verifyType: 'PHONE',
      verifySmsId: data.verifySmsId,
    };
    postUserId(params);
  };

  // 문자 발송
  const sendSmsHandler = () => {
    sendVerifyNumberWithName(watch('phone'), name).then((msg) => {
      Alert(msg);
    });
  };

  // 문자 확인
  const confirmSmsHandler = () => {
    checkVerifyNumber(watch('phone'), verifyNumber).then((res) => {
      if (res.confirm) {
        setIsConfirm(true);
        setValue('verifySmsId', res.confirm.toString());
      }
      Alert(res.msg);
    });
  };

  const postUserId = async (params: findIdbyphoneDTO) => {
    try {
      const res = await instance.post<ApiResponse<{ uid: string }[]>>(
        '/v1/mypage/user/find-uid',
        params
      );
      dispatch(
        openModal({
          body: (
            <ViewIdModal
              ids={res.data.data.map((item) => item.uid)}
              linkTo={(link) => navigete(link)}
            />
          ),
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form>
      <TextWinds
        type="caption1"
        className="mb-5 text-center bg-gray2 p-2 rounded"
      >
        학생 아이디는 클래스 선생님의 LMS를 통해 찾으실 수 있습니다.
      </TextWinds>
      <HFinput
        label="이름"
        name="name"
        register={register('name', {
          required: { value: true, message: '이름을 입력해주세요.' },
        })}
        placeholder="이름을 입력해주세요."
        disabled={isConfirm}
      />
      <div className="relative">
        <HFinputPhone
          label="휴대폰 번호"
          placeholder="휴대폰 번호를 입력해주세요."
          name="phone"
          register={register('phone', {
            required: { value: true, message: '휴대폰 번호를 입력해주세요.' },
          })}
          disabled={isConfirm}
        />
        <div className="absolute top-8 right-1">
          <HFbutton height={40} onClick={sendSmsHandler} disabled={isConfirm}>
            {timeLeftCheck(timeLeft) ? timeLeftToString(timeLeft) : '인증번호'}
          </HFbutton>
        </div>
      </div>
      <div className="relative">
        <HFinput
          label="인증번호"
          type="text"
          placeholder="인증번호를 입력해주세요."
          value={verifyNumber}
          onChange={(e) => setVerifyNumber(e.target.value)}
          disabled={isConfirm}
        />
        <div className="absolute top-8 right-1">
          <HFbutton
            height={40}
            onClick={confirmSmsHandler}
            disabled={isConfirm}
          >
            {isConfirm ? '인증완료' : '인증확인'}
          </HFbutton>
        </div>
      </div>
      <div className="flex gap-3">
        <HFbutton type="Outlined" onClick={() => navigete('/signin')}>
          취소
        </HFbutton>
        <HFbutton onClick={handleSubmit(onSubmit)}>다음</HFbutton>
      </div>
    </form>
  );
};

const ViewIdModal = ({
  ids,
  linkTo,
}: {
  ids: string[];
  linkTo: (link: string) => void;
}) => {
  const dispatch = useAppDispatch();
  const closeHandler = () => {
    dispatch(closeModal());
    linkTo('/signin');
  };

  const movePageHandler = () => {
    dispatch(closeModal());
    linkTo(`/signin/password`);
  };

  const copyHandler = (id: string) => {
    handleCopyClipBoard(id).then(() => {
      alert('복사되었습니다.');
    });
  };

  return (
    <div className="max-w-[480px] w-full bg-white rounded p-5">
      <TextWinds type="title_h1" className="text-center">
        아이디 찾기 완료
      </TextWinds>
      <div className="flex flex-col gap-2 p-5">
        {ids.map((item, index) => (
          <div
            className="flex gap-2 items-center text-center text-lg shadow-card p-3 relative rounded"
            key={index}
          >
            <div className="right-1 top-1 absolute">
              <HFbutton height={40} onClick={() => copyHandler(item)}>
                복사하기
              </HFbutton>
            </div>
            <div>{item}</div>
          </div>
        ))}
      </div>
      <div className="flex gap-3">
        <HFbutton type="Outlined" onClick={closeHandler}>
          닫기
        </HFbutton>
        <HFbutton onClick={movePageHandler}>비밀번호 찾기</HFbutton>
      </div>
    </div>
  );
};
export default LoginIdPhone;
