import { Tab, Tabs, TabsHeader } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';
import TextWinds from '../../../../components/common/TextWinds';
import Buttons from '../../../../components/common/Buttons';
import { Pagination, Table } from 'antd';
import {
  LmsTodoStatusByIdType,
  LmsTodoStatusByTodoType,
} from '../../../../constants/types/lms-type';
import { stateColumnsById, stateColumnsByTodo } from './columns';
import { useParams } from 'react-router-dom';
import { PaginationProps } from '../../../../constants/types';
import {
  getTodoStateById,
  getTodoStateByTodo,
} from '../../../../api/utils/lms/lmsApi';
import TodoExpandedRowByTodo from '../../../../components/lms/todo/TodoExpandedRowByTodo';
import TodoExpandedRowById from '../../../../components/lms/todo/TodoExpandedRowById';
import TodoStatusSearch, {
  TodoStatusSearchObjType,
} from '../../../../components/lms/todo/TodoStatusSearch';
import { openContentsView } from '../../../../api/utils/util-func';
import { useAppDispatch } from '../../../../store/hook';
import { openModal } from '../../../../store/features/modalSlice';
import TodoEditDateModal from '../../../../components/lms/todo/TodoEditDateModal';

const TodoStatusPage = () => {
  type TodoStatusCatetory = 'BY_ID' | 'BY_TODO';
  type TodoStatusTab = 'NOT_STARTED' | 'IN_PROGRESS' | 'CLOSED';
  type TodoStatusOrder = 'ASC' | 'DESC';

  const TABS_MENU: { label: string; value: TodoStatusTab }[] = [
    { label: '진행 전', value: 'NOT_STARTED' },
    { label: '진행 중', value: 'IN_PROGRESS' },
    { label: '마감', value: 'CLOSED' },
  ];

  const dispatch = useAppDispatch();

  const { classId, subType } = useParams();

  // RECITATION, JEBSON, JEBSPL
  const toDoType = subType.toUpperCase();

  // 진행전 NOT_STARTED, 진행중 IN_PROGRESS, 마감 CLOSED
  const [activeTab, setActiveTab] = useState<TodoStatusTab>('NOT_STARTED');

  // 할일 별 보기 BY_TODO, 학생 별 보기 BY_ID
  const [category, setCategory] = useState<TodoStatusCatetory>('BY_TODO');

  // 검색 옵션, 검색어, 정렬[ 고정 ASC]
  const [searchObj, setSearchObj] = React.useState<TodoStatusSearchObjType>({
    searchOption: undefined,
    searchValue: undefined,
    orderBy: 'ASC',
  });

  const [pageInfo, setPageInfo] = useState<PaginationProps>({
    page: 1,
    pageSize: 10,
    total: 0,
    lastPage: 0,
  });

  // 할일 미리보기 창
  const [todoView, setTodoView] = useState(undefined);

  type DataListByIdType = LmsTodoStatusByIdType & PaginationProps;
  const [dataListById, setDataListById] = useState<DataListByIdType[]>([]);

  type DataListByTodoType = LmsTodoStatusByTodoType & PaginationProps;
  const [data, setData] = useState<LmsTodoStatusByTodoType[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const getData = (
    searchObj?: TodoStatusSearchObjType,
    pageParam?: PaginationProps
  ) => {
    setIsLoading(true);
    if (category === 'BY_TODO') {
      getTodoStateByTodo(
        classId,
        pageParam ? pageParam.page : 1,
        pageParam ? pageParam.pageSize : 10,
        activeTab,
        toDoType,
        'DESC',
        searchObj?.searchOption,
        searchObj?.searchValue
      ).then((data) => {
        // const dataList: DataListByTodoType[] = data?.result.map((item) => {
        //   return {
        //     ...item,
        //     ...pageInfo,
        //   };
        // });
        // setDataListByTodo(dataList);
        setData(data.result);
        setPageInfo((prev) => ({
          page: data?.page.page,
          pageSize: data?.page.pageSize,
          total: data?.page.total,
          lastPage: data?.page.lastPage,
        }));
        setIsLoading(false);
      });
    } else {
      getTodoStateById(
        classId,
        pageParam ? pageParam.page : 1,
        pageParam ? pageParam.pageSize : 10,
        activeTab,
        toDoType,
        'DESC',
        searchObj?.searchOption,
        searchObj?.searchValue
      ).then((data) => {
        const dataList: DataListByIdType[] = data?.result.map((item) => {
          return {
            ...item,
            ...pageInfo,
          };
        });
        setDataListById(dataList);
        setPageInfo((prev) => ({
          page: data?.page.page,
          pageSize: data?.page.pageSize,
          total: data?.page.total,
          lastPage: data?.page.lastPage,
        }));
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    getData(searchObj, pageInfo);
  }, [pageInfo.page, pageInfo.pageSize]);

  useEffect(() => {
    const searchObj: TodoStatusSearchObjType = {
      searchOption: undefined,
      searchValue: undefined,
      orderBy: 'DESC',
    };

    getData(searchObj);
  }, [category, activeTab, toDoType]);

  const contentsOpenHandler = (url: string) => {
    const dataToSend = {
      type: 'todoView',
      contents: {
        url,
      },
    };
    openContentsView(dataToSend, setTodoView);
  };

  const openEditModal = (data: LmsTodoStatusByTodoType) => {
    dispatch(
      openModal({
        body: (
          <TodoEditDateModal
            classId={classId}
            type={toDoType}
            todoState={activeTab}
            data={data}
            onSubmit={() => getData(searchObj, pageInfo)}
          />
        ),
      })
    );
  };

  return (
    <div>
      <Tabs value={activeTab} className="w-full">
        <TabsHeader
          className="rounded-none border-b border-gray6 bg-transparent p-0 mx-5 xl:m-0"
          indicatorProps={{
            className:
              'bg-transparent border-b-2 border-purple5 shadow-none rounded-none',
          }}
        >
          {TABS_MENU.map(({ label, value }) => (
            <Tab
              key={value}
              value={value}
              onClick={() => setActiveTab(value)}
              className={`w-[142px] h-[48px]`}
            >
              <TextWinds
                type={`${activeTab === value ? 'button2' : 'content_body3'}`}
                color={`${activeTab === value ? 'purple5' : 'gray7'}`}
                xlType={`${activeTab === value ? 'button' : 'content_body2'}`}
              >
                {label}
              </TextWinds>
            </Tab>
          ))}
        </TabsHeader>
      </Tabs>
      <div className="flex gap-x-6 p-5">
        <Buttons
          type="text"
          className="gap-x-1 p-0"
          color="gray6"
          onClick={() => setCategory('BY_TODO')}
        >
          <img src={process.env.PUBLIC_URL + '/svg/icon-check-on.svg'} alt="" />
          <TextWinds
            type="button2"
            color={`${category === 'BY_TODO' ? 'purple5' : 'gray7'}`}
          >
            할일 별 보기
          </TextWinds>
        </Buttons>
        <Buttons
          type="text"
          className="gap-x-1 p-0"
          color="gray7"
          onClick={() => setCategory('BY_ID')}
        >
          <img src={process.env.PUBLIC_URL + '/svg/icon-users.svg'} alt="" />
          <TextWinds
            type="button2"
            color={`${category === 'BY_ID' ? 'purple5' : 'gray7'}`}
          >
            학생 별 보기
          </TextWinds>
        </Buttons>
      </div>
      <TodoStatusSearch
        category={category}
        subType={subType}
        searchObj={searchObj}
        activeTab={activeTab}
        onChange={(value) => setSearchObj(value)}
        onSubmit={getData}
      />
      {category === 'BY_TODO' ? (
        <Table
          expandable={{
            expandedRowRender: (record) => (
              <TodoExpandedRowByTodo
                classId={classId}
                params={{
                  toDoType,
                  toDoStatus: activeTab,
                  category: 'BY_TODO',
                }}
                txId={record.txId}
                subType={subType}
              />
            ),
          }}
          columns={stateColumnsByTodo({
            classId: classId,
            page: pageInfo.page,
            pageSize: pageInfo.pageSize,
            todoState: activeTab,
            editHandler: openEditModal,
            openPrev: contentsOpenHandler,
            reload: getData,
            subType: subType,
          })}
          dataSource={data}
          rowKey={(record) => record.txInfo.id}
          loading={isLoading}
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
      ) : (
        <Table
          expandable={{
            expandedRowRender: (record) => (
              <TodoExpandedRowById
                classId={classId}
                toDoType={toDoType}
                toDoStatus={activeTab}
                id={record.id}
                subType={subType}
              />
            ),
          }}
          columns={stateColumnsById()}
          dataSource={dataListById}
          rowKey={(record) => record.id}
          loading={isLoading}
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
      )}
      <Pagination
        current={pageInfo.page}
        pageSize={pageInfo.pageSize}
        total={pageInfo.total}
        onChange={(page, pageSize) => {
          setPageInfo({
            ...pageInfo,
            page,
            pageSize,
          });
        }}
        className="mt-5"
      />
    </div>
  );
};

export default TodoStatusPage;
