import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JSX } from 'react';
import ModalAlert, { ModalAlertProps } from '../../components/modal/ModalAlert';
import ModalConfirm, { ModalConfirmProps } from '../../components/modal/ModalConfirm';

type ModalOption = {
  removeBlur?: boolean;
}

type ModalState = {
  open: boolean;
  body: JSX.Element;
  option?: ModalOption;
};

const initialState: ModalState = {
  open: false,
  body: <></>,
  option: { removeBlur: true },
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal(state, action: PayloadAction<{body:JSX.Element,option?:ModalOption}>) {
      state.open = true;
      state.body = action.payload.body;
      state.option = action.payload.option
    },
    closeModal(state) {
      state.open = false;
      state.body = <></>;
    },
    openAlert(state, action: PayloadAction<ModalAlertProps>) {
      state.open = true;
      state.body = <ModalAlert {...action.payload} />;
    },
    openConfirm(state, action: PayloadAction<ModalConfirmProps>) {
      state.open = true;
      state.body = <ModalConfirm {...action.payload} />;
    },
  },
});

export const { openModal, closeModal, openAlert, openConfirm } = modalSlice.actions;
export default modalSlice.reducer;