import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../store/hook';
import { selectLmsLists } from '../../store/features/lmsSlice';
import { changeTimestamp } from '../../api/utils/util-func';
import { courseIdDetermination } from '../../api/utils/lms/ExtendSetBtnLink';
import { LoadingSpinner } from '../Loading/LoadingSpinner';
import { Menu } from 'antd';
import { SidebarItems } from './sidebarItem';

const Sidebar = ({ classId }: { classId: string }) => {
  const { pathname } = useLocation();
  const lmsLists = useAppSelector(selectLmsLists);
  const [isLoading, setIsloading] = useState(false);
  const [menuExposure, setMenuExposure] = useState('');

  useEffect(() => {
    setIsloading(true);
    if (lmsLists) {
      setMenuExposure(courseIdDetermination(classId, lmsLists));
      setIsloading(false);
    }
  }, [lmsLists]);

  const nowMenu = useMemo(() => {
    const urlArr = pathname.split('/');

    if (urlArr.length < 3 || urlArr[1] !== 'lms') {
      console.error('잘못된 접속입니다.', pathname);
      return;
    }
    const base = urlArr[2];
    switch (base) {
      case 'dashboard':
      case 'course-management':
      case 'take-class':
      case 'reference-room':
      case 'signup-management':
      case 'migration':
        return base;
      case 'todo-management':
        const subType = urlArr[3];
        if (['recitation', 'jebson', 'jebspl'].includes(subType)) {
          return subType;
        } else {
          return;
        }
      default:
        return;
    }
  }, [pathname]);

  return isLoading ? (
    <LoadingSpinner message="데이터 조회중" />
  ) : (
    <div>
      {lmsLists && (
        <div className="px-5 py-3 bg-gray0 xl:bg-white xl:px-7 xl:py-7 border-b">
          <Link to={`/lms/dashboard/${classId}`}>
            <div className="text-lg font-semibold">{lmsLists.course?.name}</div>
            <div className="text-sm">
              {changeTimestamp(lmsLists?.created_at)} 개설
            </div>
          </Link>
        </div>
      )}
      <Menu
        mode="inline"
        defaultOpenKeys={['management', 'todo', 'student']}
        selectedKeys={[nowMenu]}
        items={SidebarItems(classId, menuExposure)}
      />
    </div>
  );
};

export default Sidebar;
