import React from 'react';
import { useAppDispatch } from '../../../store/hook';
import { closeModal } from '../../../store/features/modalSlice';
import { LmsTodoStatusByTodoType } from '../../../constants/types/lms-type';
import { Calendar } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import HFbutton from '../../common/input/HFbutton';
import TextWinds from '../../common/TextWinds';
import {
  getTodoStateDetailByTodo,
  putTodoAssign,
} from '../../../api/utils/lms/lmsApi';
interface TodoEditDateModalProps {
  classId: string;
  type: string;
  todoState: string;
  data: LmsTodoStatusByTodoType;
  onSubmit: () => void;
}
const TodoEditDateModal = ({
  classId,
  type,
  todoState,
  data,
  onSubmit,
}: TodoEditDateModalProps) => {
  const dispatch = useAppDispatch();
  const [date, setDate] = React.useState<Dayjs>(dayjs(data.txInfo.toDate));

  const closeHandler = () => {
    dispatch(closeModal());
  };

  const onSelect = (value: Dayjs) => {
    const createDate = dayjs(data.txInfo.createdAt);
    const fromDate = dayjs(data.txInfo.fromDate);

    // 종료일은 생성일 이전으로 설정 불가
    if (value.isBefore(fromDate, 'day')) {
      alert('종료일은 시작일 이후로 설정해주세요.');

      return;
    }

    // 1년 이상 차이나면 에러
    if (value.diff(createDate, 'year') >= 1) {
      alert('종료일은 생성일로부터 1년 이내로 설정해주세요.');
      return;
    }

    setDate(value);
  };

  const handleSubmit = () => {
    getTodoStateDetailByTodo(classId, type, todoState, data.txId).then(
      (res) => {
        const students: number[] = res.data.map((item) => item.student.id);
        putTodoAssign(
          classId,
          data.txId,
          students,
          data.txInfo.fromDate,
          date.toDate()
        ).then(() => {
          onSubmit();
          closeHandler();
        });
      }
    );
  };

  return (
    <div className="bg-white max-w-[480px] rounded-lg p-5 mt-[130px]">
      <TextWinds type="title_h3" className="w-full text-center mb-5">
        마감일 변경
      </TextWinds>
      <div>
        <div>생성일 : {dayjs(data.txInfo.createdAt).format('YYYY-MM-DD')}</div>

        <div>
          시작일 : {dayjs(data.txInfo.fromDate).format('YYYY-MM-DD')} / 마감일 :{' '}
          {date.format('YYYY-MM-DD')}
        </div>
      </div>
      <Calendar fullscreen={false} onSelect={onSelect} value={dayjs(date)} />
      <div className="flex gap-3">
        <HFbutton type="Outlined" height={36} onClick={closeHandler}>
          취소
        </HFbutton>
        <HFbutton height={36} onClick={handleSubmit}>
          완료
        </HFbutton>
      </div>
    </div>
  );
};

export default TodoEditDateModal;
