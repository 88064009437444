import React, { useEffect, useState } from 'react';
import TextWinds from '../../../components/common/TextWinds';
import WhatIsCertification from './WhatIsCertification';
import IconAward from './IconAward';
import instance from '../../../api/axios/utils.ts/instance';
import { ApiResponse } from '../../../constants/types';
import {
  studentRecitationLevelType,
  studentRecitationType,
} from '../../../constants/types/student-type';

const StudentCertificatioinPage = () => {
  const [data, setData] = useState<studentRecitationLevelType[]>([]);

  const getRecitation = async () => {
    try {
      const response = await instance.get<
        ApiResponse<studentRecitationLevelType[]>
      >('v1/student/class/4uMfWRvh4rlVNSHY/recitation');
      return response.data.data;
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getRecitation().then((data) => {
      setData(data);
    });
  }, []);

  return (
    data.length > 0 && (
      <div className="bg-[#f3f0e7] py-16">
        <div className="w-full max-w-[1176px] m-auto px-5">
          <TextWinds type={'title_h1'} children={'암송인증제 북별 합격 현황'} />
          <WhatIsCertification />
          {data.map((level) => (
            <StudentCertificatioinLevel key={level.groupLevel} level={level} />
          ))}
        </div>
      </div>
    )
  );
};

const StudentCertificatioinLevel = ({
  level,
}: {
  level: studentRecitationLevelType;
}) => {
  return (
    <div className="mt-4">
      <TextWinds type={'title_h3'} children={`Level ${level.groupLevel}`} />
      <div className="grid grid-cols-4 gap-4 mt-2">
        {level.results.map((book) => (
          <StudentCertificationBook key={book.title} book={book} />
        ))}
      </div>
    </div>
  );
};

const StudentCertificationBook = ({
  book,
}: {
  book: studentRecitationType;
}) => {
  const onoff = book.passDate ? true : false;
  // const onoff = true;

  return (
    <div className="flex flex-col">
      <div
        className={`w-full text-center shadow-card rounded-lg py-3 text-lg font-bold ${
          onoff ? 'bg-spotLilac text-white' : 'bg-gray-100 text-gray-500'
        }`}
        // onClick={() => {
        //   alert('TODO : 버튼인지 보는 용도인지 확인 필요');
        // }}
      >
        <div
          className={`flex flex-col sm:flex-row items-center justify-center gap-2`}
        >
          {onoff && <IconAward />}
          {book.title}
        </div>
      </div>

      {/* 합격일 */}
      <div className="text-center mt-2">
        {onoff ? (
          <TextWinds
            type={'caption1'}
            children={`합격일 : ${new Date(
              book.passDate
            ).toLocaleDateString()}`}
          />
        ) : book.failDate ? (
          <TextWinds
            type={'caption1'}
            children={`최근 응시일 : ${new Date(
              book.failDate
            ).toLocaleDateString()}`}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default StudentCertificatioinPage;
