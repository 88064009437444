import React from 'react';
import { handleCopyClipBoard } from '../../../../api/utils/util-func';
import { useAppDispatch } from '../../../../store/hook';
import { closeModal } from '../../../../store/features/modalSlice';
import TextWinds from '../../../../components/common/TextWinds';
import IconEye from './IconEye';
import IconCopy from './IconCopy';
import HFbutton from '../../../../components/common/input/HFbutton';

const NewPasswordModal = ({ uid, pw }: { uid: string; pw: string }) => {
  const dispatch = useAppDispatch();
  const [pwhide, setPwhide] = React.useState(false);

  const copyClipboard = () => {
    const text = `아이디 : ${uid}\n패스워드 : ${pw}`;
    handleCopyClipBoard(text).then((result) => {
      if (result) {
        alert('복사되었습니다.');
      }
    });
  };

  const closeHandler = () => {
    dispatch(closeModal());
  };
  return (
    <div className="flex flex-col gap-3 bg-white p-5 text-center rounded shadow-card">
      <TextWinds type="title_h1">초기화가 완료되었습니다.</TextWinds>
      <div className="flex justify-center w-full">
        <TextWinds type="title_h2" color="purple5">
          아이디 : {uid}
        </TextWinds>
      </div>
      <div className="flex justify-center w-full">
        <TextWinds type="title_h2" color="purple5">
          패스워드 : {pwhide ? '*'.repeat(pw.length) : pw}
        </TextWinds>
        <TextWinds
          type="content"
          color="purple5"
          onClick={() => {
            setPwhide(!pwhide);
          }}
          className="cursor-pointer"
        >
          <IconEye />
        </TextWinds>
      </div>
      <div
        className="flex cursor-pointer justify-center items-center gap-2 py-2 bg-gray2 hover:bg-gray3 rounded"
        onClick={() => {
          copyClipboard();
        }}
      >
        <IconCopy />
        <TextWinds type="sub_title01">복사하기</TextWinds>
      </div>
      <HFbutton onClick={closeHandler}>닫기</HFbutton>
    </div>
  );
};

export default NewPasswordModal;
