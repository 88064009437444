import { Drawer } from '@material-tailwind/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ChosenVariantDto, PlanProps } from '../../../constants/types';
import Plans from '../../../components/options/plans';
import FullWidthPage from '../../../components/layout/FullWidthPage';
import TextWinds from '../../../components/common/TextWinds';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import {
  getCourseInfo,
  selectCourseInfo,
} from '../../../store/features/courseSlice';
import { Options } from '../../../components/options/options';
import { getOrderItem } from '../../../api/utils/common-purchase';
import { ChosenOptionsDto } from '../../../store/features/shopSlice';
import { addCart } from '../../../store/features/cartSlice';
import { excuteAxiosPost } from '../../../api/axios/call/testAxiosCall';
import useUserHook from '../../../controllers/useUserHook';
import LeftContents from '../../../components/course/LeftContents';
import instance from '../../../api/axios/utils.ts/instance';
import { CourseType } from '../../../enum';
import { extentionCategory } from '../../../api/utils/lms/ExtendSetBtnLink';

const CourseDetails = () => {
  const id = useParams().homeschoolId;
  const search = new URLSearchParams(useLocation().search);
  const optionVisible: boolean = search.get('option') === 'true';
  const path = '/v1/product/' + id;
  const dispatch = useAppDispatch();
  const courseInfo = useAppSelector(selectCourseInfo);
  const { checkWithCallback } = useUserHook();

  useEffect(() => {
    dispatch(getCourseInfo({ url: path }));
  }, [path]);

  const [hasCourse, setHasCourse] = useState<boolean>(false);
  useEffect(() => {
    const fetchCourseUsage = async () => {
      if (extentionCategory(id)) {
        const params = {
          courseId:
            courseInfo.courses.filter(
              (a: any) =>
                CourseType.TEACHING === a.course.type ||
                CourseType.TRAINING === a.course.type
            ).length > 0
              ? courseInfo.courses
                  .filter((a: any) => CourseType.TRAINING === a.course.type)
                  .map((x: any) => x.course.id)
              : courseInfo.courses
                  .filter((a: any) => CourseType.LEARNING === a.course.type)
                  .map((x: any) => x.course.id),
        };
        try {
          await instance
            .get(`/v1/product/course/usage`, { params: params })
            .then((res) => {
              if (200 === res.status) {
                const result = res.data.data;
                setHasCourse(result.hasCourse);
              }
            });
        } catch (error) {}
      } else {
        setHasCourse(false);
      }
    };
    if (id === courseInfo?.id) {
      // 로그인 상태에서만 조회
      const token = localStorage.getItem('accessToken');
      if (token) {
        fetchCourseUsage();
      }
    }
  }, [courseInfo]);

  // 팝업 공통 기능
  const [openBottom, setOpenBottom] = useState(false);
  const openDrawerBottom = () => {
    setActiveStep(0);
    setOpenBottom(true);
  };
  const redirectToJebsphoneLink = () => {
    const url = 'https://www.jebsphone.com/';
    window.open(url, '_blank', 'noopener, noreferrer');
  };
  const closeDrawerBottom = () => {
    setOpenBottom(false);
  };

  // PLAN
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = (selectPlanData: PlanProps) => {
    // itemDatas.plans = selectPlanData;
    // itemDatas.totalPrice += selectPlanData.price;
    setActiveStep((cur) => cur + 1);
  };

  const isLoading = (): boolean => {
    if (!courseInfo) return true;
    return courseInfo.id != id;
  };

  // OPTION
  // 1. 구매하기
  const navigate = useNavigate();
  const onPurchaseHandle = async (
    childId: string,
    selectOptionData: ChosenVariantDto[]
  ) => {
    const action = async () => {
      const path = '/v1/order/template/from-prod';
      const params = {
        order_item: getOrderItem({
          product_id: courseInfo.id,
          variants: [],
          // 옵션 상품
          options: selectOptionData.reduce<ChosenOptionsDto[]>((acc, cur) => {
            acc.push({
              optionProduct: courseInfo.option_products[0],
              chosenVariant: cur.variant,
              quantity: cur.quantity,
            });
            return acc;
          }, []),
          enrolls: [courseInfo.enrolls[0]],
        }),
      };
      await Promise.all([excuteAxiosPost(path, params)]).then((res) => {
        if (res) {
          navigate(
            { pathname: '/purchase' },
            { state: { purchaseId: res[0].data.id } }
          );
        }
      });
    };
    checkWithCallback(action);
  };
  // 2. 장바구니
  const onCartHandle = useCallback(
    async (childId: string, selectItem: ChosenVariantDto[]) => {
      if (selectItem.length > 0) {
        try {
          const res = await dispatch(
            addCart({
              url: '/v1/cart',
              data: {
                order_item: getOrderItem({
                  product_id: courseInfo.id,
                  // 안넣으면 오류나서 추가함.
                  variants: [
                    {
                      variant: selectItem[0].variant,
                      baseQuantity: 1,
                      quantity: 1,
                      totalPrice: courseInfo.current_price,
                    },
                  ],
                  // 옵션 상품
                  options: selectItem.reduce<ChosenOptionsDto[]>((acc, cur) => {
                    acc.push({
                      optionProduct: courseInfo.option_products[0],
                      chosenVariant:
                        courseInfo.option_products[0].option_product
                          .option_variants[0],
                      quantity: cur.quantity,
                    });
                    return acc;
                  }, []),
                  enrolls: [],
                }),
              },
            })
          ).unwrap();

          navigate('/cart');
        } catch (e) {
          console.error(
            '일시적인 장애로 상품을 구매할 수 없습니다. 나중에 다시 시도해주세요.'
          );
        }
      }
    },
    [courseInfo]
  );

  return isLoading() ? null : (
    <FullWidthPage>
      <div className="flex flex-col pt-10 gap-y-6">
        <TextWinds type="title_h2" xlType="title_h1" className="px-4">
          {/* 홈스쿨 어드벤스드 코드 */}
          {courseInfo.name}
        </TextWinds>

        <div className="flex flex-col lg:pt-[24px] gap-[32px] lg:flex-row">
          <div className="flex w-full shrink-0 lg:w-[275px]">
            {courseInfo && (
              <LeftContents
                hasCourse={hasCourse}
                courseInfo={courseInfo}
                redirectToJebsphoneLink={redirectToJebsphoneLink}
                openDrawerBottom={openDrawerBottom}
              />
            )}
          </div>
          <div className="flex flex-col basis-full gap-y-6">
            {/* <TextWinds type="title_h4">상품 상세 설명</TextWinds>*/}
            {
              // 이미지 순서가 이상할 때 확인해야함.
              // 뒤집는 것은 filter 뒤 map 앞에 .reverse() 했었음.
              //  vKPAt48Z82J--OQW
              courseInfo &&
                courseInfo.detail_imgs &&
                courseInfo.detail_imgs
                  .filter((url) => url)
                  .map((img_url: string, index: number) =>
                    courseInfo.product_sub_type === 'LEARN_ON_PHONE' ? (
                      <a
                        onClick={redirectToJebsphoneLink}
                        key={index}
                        className="cursor-pointer"
                      >
                        <img src={img_url} className="w-full" />
                      </a>
                    ) : (
                      <img src={img_url} className="w-full" key={index} />
                    )
                  )
            }
          </div>
          <div
            className={`w-full p-4 fixed bottom-0 left-0 ${
              openBottom ? 'block' : 'hidden'
            }`}
          >
            <Drawer
              placement="bottom"
              open={openBottom}
              onClose={closeDrawerBottom}
              overlay={false}
              size={500}
              className="items-center w-full m-auto px-5 py-4 right-1/4 drop-shadow-xl rounded-2xl relative lg:w-1/2"
            >
              {activeStep === 0 ? (
                <Plans
                  closeDrawerBottom={closeDrawerBottom}
                  handleNext={handleNext}
                  handleSubmit={onPurchaseHandle}
                  optionVisible={optionVisible}
                  data={courseInfo}
                />
              ) : (
                <Options
                  gubun="course"
                  shopInfo={courseInfo}
                  chosenVariants={[
                    ...courseInfo.option_products[0].option_product
                      .option_variants,
                  ].sort((a, b) => a.name.localeCompare(b.name))}
                  closeDrawerBottom={closeDrawerBottom}
                  onCartHandle={onCartHandle}
                  onPurchaseHandle={onPurchaseHandle}
                />
              )}
            </Drawer>
          </div>
        </div>
      </div>
    </FullWidthPage>
  );
};

export default CourseDetails;
