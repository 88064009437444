import React, { Fragment } from "react";
import TextWinds from "../../../../components/common/TextWinds";
import { openNewWindow } from "../../../../api/utils/util-func";

const ReferenceRoom = () => {
    const btnCSSBase = 'w-full bg-white rounded font-bold text-center border p-5 max-w-[300px] hover:bg-gray2 cursor-pointer'
    return (
        <Fragment>
            <div className="flex flex-col items-start justify-start mx-[40px] mt-[24px] mb-[67px]">
                <TextWinds type="title_h3">자료실</TextWinds>
                {/* <div className="text-[14px] leading-[22px] tracking-[-0em] font-medium text-gray7 whitespace-nowrap">
                    코스의 다양한 부가자료를 수료생 게시판에서 확인하세요.
                    <br/>
                    수료생 카페 별명을 ‘기수+이름'으로 설정한 후 등업 신청을 하여야 등업 완료됩니다.
                </div> */}
                <TextWinds type="content_body4">
                    코스의 다양한 부가자료를 수료생 게시판에서 확인하세요.
                    <br />
                    수료생 카페 별명을 ‘기수+이름'으로 설정한 후 등업 신청을 하여야 등업 완료됩니다.
                </TextWinds>
            </div>
            {/* <div className="text-[20px] leading-[30px] tracking-[-0em] font-bold text-gray9 whitespace-nowrap">
                수료생 카페
            </div> */}
            <div className="flex flex-col items-start justify-start mx-[40px]">
                <TextWinds type="title_h3">수료생 카페</TextWinds>
            </div>
            <div className="mx-10 mt-4 flex gap-5">
                <div className={`${btnCSSBase} border-orange5 text-orange5`} onClick={()=>openNewWindow('https://cafe.naver.com/jemm')}>홈스쿨 수료생 카페 자료실</div>
                <div className={`${btnCSSBase} border-spotViolet text-spotViolet`} onClick={()=>openNewWindow('https://cafe.naver.com/jcebs')}>주일학교 수료생 카페 자료실</div>
            </div>
        </Fragment>
    );
};

export default ReferenceRoom;
