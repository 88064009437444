import {
  ClipboardIcon,
  Cog8ToothIcon,
  HomeIcon,
  UserIcon,
  BookmarkIcon,
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

const SidebarTodoItem = (classId: string, subType: string) => {
  if (subType === 'home') {
    return [
      {
        label: (
          <Link to={`/lms/todo-management/recitation/${classId}`}>인증제</Link>
        ),
        key: 'recitation',
      },
      {
        label: (
          <Link to={`/lms/todo-management/jebson/${classId}`}>젭스온</Link>
        ),
        key: 'jebson',
      },
    ];
  } else if (subType === 'church') {
    return [
      {
        label: (
          <Link to={`/lms/todo-management/jebspl/${classId}`}>젭스플</Link>
        ),
        key: 'jebspl',
      },
    ];
  } else {
    return undefined;
  }
};

const SidebarTodoList = (classId: string, menuExposure: string) => {
  if (menuExposure === '') return undefined;

  return {
    label: '할 일 관리',
    key: 'todo',
    icon: <ClipboardIcon className="h-6 w-6" />,
    children: SidebarTodoItem(classId, menuExposure),
  };
};

export const SidebarItems = (classId: string, menuExposure: string) => [
  {
    label: <Link to={`/lms/dashboard/${classId}`}>클래스 메인</Link>,
    key: 'dashboard',
    icon: <HomeIcon className="h-6 w-6" />,
  },
  {
    label: '수업 관리',
    key: 'management',
    icon: <ClipboardIcon className="h-6 w-6" />,
    children: [
      {
        label: <Link to={`/lms/course-management/${classId}`}>코스관리</Link>,
        key: 'course-management',
      },
      {
        label: <Link to={`/lms/take-class/${classId}`}>수업하기</Link>,
        key: 'take-class',
      },
    ],
  },
  SidebarTodoList(classId, menuExposure),
  {
    label: <Link to={`/lms/reference-room/${classId}`}>자료실</Link>,
    key: 'reference-room',
    icon: <BookmarkIcon className="h-6 w-6" />,
  },
  {
    label: '학생 관리',
    key: 'student',
    icon: <UserIcon className="h-6 w-6" />,
    children: [
      {
        label: <Link to={`/lms/signup-management/${classId}`}>등록 관리</Link>,
        key: 'signup-management',
      },
      {
        label: <Link to={`/lms/migration/${classId}`}>학생 가져오기</Link>,
        key: 'migration',
      },
    ],
  },
  {
    label: '클래스 설정',
    key: '5',
    icon: <Cog8ToothIcon className="h-6 w-6" />,
    onClick: () => {
      alert('준비중인 기능입니다.');
    },
  },
];
