import { ArrowRightIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Link } from 'react-router-dom';
import { ProductSubType } from '../../enum';
import FullWidthPage from '../layout/FullWidthPage';
import TextWinds from '../common/TextWinds';
import CourseList from './CourseList';

interface CourseWidgetProps {
  product_sub_type: ProductSubType;
}
const CourseWidget = ({ product_sub_type }: CourseWidgetProps) => {
  const COURSE_TITLE = (type: ProductSubType) => {
    switch (type) {
      case ProductSubType.HOMESCHOOL:
        return '홈스쿨 코스';
      case ProductSubType.WORSHIP:
        return '주일학교 코스';
      case ProductSubType.SMALL_GROUP:
        return '소그룹 코스';
      case ProductSubType.LEARN_ON_PHONE:
        return '화상영어 코스';
      default:
        return '젭스 코스';
    }
  };
  const COURSE_DESC = (type: ProductSubType) => {
    switch (type) {
      case ProductSubType.HOMESCHOOL:
        return '말씀으로 끝장보는 우리 아이 영어교육 솔루션!';
      case ProductSubType.WORSHIP:
        return '땅끝복음 준비하는 우리 교회 부흥 솔루션!';
      case ProductSubType.SMALL_GROUP:
        return '전도를 위한 복음 준비부터 영어성경 일독에서 암송까지!';
      case ProductSubType.LEARN_ON_PHONE:
        return '바이블 중심 1:1 원어민 화상영어';
      default:
        return '지금 바로 시작할 수 있는 코스';
    }
  };
  return (
    <FullWidthPage>
      <div>
        <section className="px-[20px] mt-12 flex flex-col gap-2 pt-10">
          <TextWinds type="title_h2" xlType="title_h1">
            {COURSE_TITLE(product_sub_type)}
          </TextWinds>
          <TextWinds type="title_h4" color="purple5">
            {COURSE_DESC(product_sub_type)}
          </TextWinds>
        </section>

        <section className="pt-[25px] xl:pt-[48px]">
          {/* {
                        courseLists.result && (
                            <CourseList
                                datas={(courseLists.result as CourseInfoType[]).filter(
                                    (data) => data.product_sub_type == product_sub_type
                                )}
                            />
                            // <HomeSchools datas={(courseLists.result as HomeSchoolProps[]).filter((data)=>data.product_sub_type==product_sub_type)} />
                        )
                        // <HomeSchools datas={HOME_SCHOOLS} />
                    } */}
          <CourseList productSubType={product_sub_type} />
        </section>

        <section className="pb-6">
          <div className="relative flex items-center justify-between w-full px-[20px] py-[26px] rounded-none bg-gradient-to-r from-gradient_f to-gradient_t xl:bg-spotLilac xl:from-transparent xl:to-transparent xl:py[50px] xl:px-[72px] xl:mb-[100px] xl:justify-between">
            <div className="relative inline-flex flex-col items-start flex-auto hidden xl:block">
              <TextWinds type="title_h1" color="white">
                젭스의 전체 코스
              </TextWinds>
              <TextWinds
                type="content_body4"
                color="white"
                className="pt-[10px]"
              >
                홈스쿨부터 주일학교, NIV학습까지!
                <br />
                나에게 필요한 젭스 코스가 무엇인지 확인해 보세요.
              </TextWinds>
            </div>
            <div className="relative inline-flex flex-col items-end justify-end w-full xl:w-[280px] cursor-pointer">
              <div className="relative flex items-start self-stretch justify-between flex-auto  py-[14px] px-[12px] bg-white rounded-md xl:p-[24px] ">
                <div className="relative ">
                  <Link to={'/courses'}>
                    <TextWinds type="content_bold">
                      젭스의 다른 코스를 보고 싶다면?
                    </TextWinds>
                    <div className="inline-flex flex-auto items-center gap-[2px]]">
                      <TextWinds type="content_bold" color="purple5">
                        코스 찾기 이동
                      </TextWinds>
                      <ArrowRightIcon
                        className="w-[20px] h-[20px]"
                        color="purple"
                      />
                    </div>
                  </Link>
                </div>
                <Link to={'/courses'}>
                  <img
                    className="w-[52px] h-[52px]"
                    src={
                      process.env.PUBLIC_URL + '/images/course/findcourse.png'
                    }
                  />
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </FullWidthPage>
  );
};

export default CourseWidget;
