import React, { Fragment, cloneElement, useState } from 'react';
import {
  Button,
  IconButton,
  Option,
  Select,
  Typography,
} from '@material-tailwind/react';
import { MinusIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import {
  BoostUpTypes,
  ChosenVariant,
  ChosenVariantDto,
  CourseInfoType,
  ShopInfoProps,
} from '../../constants/types';
import { numberWithCommas } from '../../api/utils/format/numbers';

interface OptionsItemProps {
  gubun: string;
  shopInfo: ShopInfoProps | CourseInfoType | BoostUpTypes;
  chosenVariants: ChosenVariant[];
  closeDrawerBottom: any;
  onCartHandle: any;
  onPurchaseHandle: any;
}
export const Options = ({
  gubun,
  shopInfo,
  chosenVariants,
  closeDrawerBottom,
  onCartHandle,
  onPurchaseHandle,
}: OptionsItemProps) => {
  const [optionItem, setOptionItem] = useState<ChosenVariantDto[]>([]);

  const getMinQuantity = () => {
    try {
      return shopInfo.option_products[0].option_constraints[0].min_quantity;
    } catch {
      return 0;
    }
  };
  const minQuantity = getMinQuantity();

  // 옵션 선택
  const selectOption = (str: string) => {
    const items: ChosenVariant = chosenVariants.filter(
      (el) => el.id === str
    )[0];
    const coursePrice =
      gubun !== 'boostup' && findCourseOptionPrice(items.product_id);
    let newData: ChosenVariantDto = {
      baseQuantity: shopInfo.base_quantity,
      quantity: shopInfo.base_quantity,
      totalPrice:
        shopInfo.base_quantity *
        (gubun !== 'boostup'
          ? shopInfo.option_products
            ? coursePrice
            : items.additional_price + shopInfo.current_price
          : shopInfo.current_price + items.additional_price),
      variant: { ...items },
    };
    if (optionItem.length > 0) {
      if (optionItem.filter((el) => el.variant.id === str).length > 0) {
        let tmp = optionItem.map((list) => {
          if (list.variant.id === str && list.variant.id === items.id) {
            let changeData = { ...list };
            changeData.quantity += 1;
            changeData.totalPrice =
              changeData.quantity *
              (gubun !== 'boostup'
                ? shopInfo.option_products
                  ? coursePrice
                  : items.additional_price + shopInfo.current_price
                : items.additional_price);
            return changeData;
          } else {
            return list;
          }
        });
        setOptionItem([...tmp]);
      } else {
        // 추가한 옵션리스트에서 선택한 옵션이 없는 경우
        setOptionItem([...optionItem, newData]);
      }
    } else {
      // 최초 옵션 선택인 경우
      setOptionItem([newData]);
    }
  };

  // 선택한 옵션 값 감소
  const onDecrease = (idx: number) => {
    const copyOption = { ...optionItem[idx] };
    copyOption.quantity -= 1;
    copyOption.totalPrice =
      copyOption.quantity *
      (gubun !== 'boostup'
        ? shopInfo.option_products?.length > 0
          ? findCourseOptionPrice(copyOption.variant.product_id)
          : copyOption.variant.additional_price + shopInfo.current_price
        : shopInfo.current_price + copyOption.variant.additional_price);

    const result = [
      ...optionItem.slice(0, idx),
      copyOption,
      ...optionItem.slice(idx + 1),
    ];

    setOptionItem(result);
  };
  // 선택한 옵션 값 증가
  const onIncrease = (idx: number) => {
    const copyOption = { ...optionItem[idx] };
    copyOption.quantity += 1;
    copyOption.totalPrice =
      copyOption.quantity *
      (gubun !== 'boostup'
        ? shopInfo.option_products
          ? findCourseOptionPrice(copyOption.variant.product_id)
          : copyOption.variant.additional_price + shopInfo.current_price
        : shopInfo.current_price + copyOption.variant.additional_price);

    const result = [
      ...optionItem.slice(0, idx),
      copyOption,
      ...optionItem.slice(idx + 1),
    ];

    setOptionItem(result);
  };

  // 선택한 옵션 제거
  const onRemoveItem = (idx: number) => {
    const modifiedArray = [
      ...optionItem.slice(0, idx),
      ...optionItem.slice(idx + 1),
    ];
    setOptionItem(modifiedArray);
  };

  const eventCartHandle = () => {
    onCartHandle(shopInfo.id, optionItem);
  };
  const eventPurchaseHandle = () => {
    const totalOptionCount = optionItem.reduce(
      (acc, cur) => acc + cur.quantity,
      0
    );
    if (minQuantity > totalOptionCount) {
      alert('최소 옵션은 ' + minQuantity + '개 이상 선택해주세요.');
      return;
    }
    onPurchaseHandle(shopInfo.id, optionItem);
  };
  const findCourseOptionPrice = (product_id: string) =>
    shopInfo.option_products?.find((v1) => v1.option_product.id === product_id)
      .option_product.origin_price;

  const buttonDisabled = shopInfo.option_products
    ? false
    : optionItem.length > 0
    ? false
    : true;
  return (
    <Fragment>
      <section>
        <div className="flex flex-row items-center gap-8 mb-2.5">
          <div className="flex flex-col items-center w-full">
            <Typography variant="h5" className="font-bold self-start py-2.5">
              옵션 선택 {minQuantity > 0 && ` : 최소 수량 (${minQuantity}개)`}
            </Typography>
          </div>
          <div className="flex items-center ml-auto">
            <IconButton
              variant="text"
              color="blue-gray"
              onClick={() => closeDrawerBottom()}
            >
              <XMarkIcon className="!w-[30px] !h-[30px]" />
            </IconButton>
          </div>
        </div>
      </section>
      <section className="min-h-60 max-h-80 overflow-y-auto">
        {shopInfo && (
          <div className="flex flex-col mb-2.5 mt-1">
            <Select
              label="옵션 선택"
              selected={(element) =>
                element &&
                cloneElement(element, {
                  disabled: true,
                  className:
                    'flex items-center opacity-100 px-0 gap-2 pointer-events-none',
                })
              }
              onChange={(e) => selectOption(e)}
            >
              {chosenVariants &&
                chosenVariants.map((item) => (
                  <Option className="p-2" value={item.id} key={item.id}>
                    {/* {variant.isRequired && <Chip color="purple" value="필수" />} */}
                    <Typography>{item.name}</Typography>
                  </Option>
                ))}
            </Select>
            {gubun !== 'shop' && shopInfo.option_products.length > 0 && (
              <div className="mt-5 flex justify-between">
                <div>{shopInfo.name}</div>
                <div>{numberWithCommas(shopInfo.current_price)} 원</div>
              </div>
            )}
            {optionItem &&
              optionItem.map((item, index: number) => (
                <div key={index} className="flex flex-col mx-4 my-1">
                  <div className="flex flex-row items-center gap-4">
                    {/* {item.isRequired && <Chip color="purple" value="필수" />} */}
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {item.variant?.name}
                    </Typography>
                    <div className="flex items-center ml-auto">
                      <IconButton
                        variant="text"
                        color="blue-gray"
                        onClick={() => onRemoveItem(index)}
                      >
                        <XMarkIcon className="w-5 h-5" />
                      </IconButton>
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-2 px-2 py-2">
                    <Button
                      variant="outlined"
                      size="sm"
                      onClick={() => onDecrease(index)}
                      disabled={item.quantity <= item.baseQuantity}
                    >
                      <MinusIcon className="w-2 h-2" />
                    </Button>

                    <Typography>{item.quantity}</Typography>

                    <Button
                      variant="outlined"
                      size="sm"
                      onClick={() => onIncrease(index)}
                    >
                      <PlusIcon className="w-2 h-2" />
                    </Button>
                    <div className="flex items-center ml-auto gap-2">
                      <Typography className="items-center font-medium">
                        {numberWithCommas(item.totalPrice)}
                      </Typography>
                      <Typography className="items-center font-medium">
                        원
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
        <div className=" flex justify-between">
          <div>총가격</div>
          <div>
            {numberWithCommas(
              optionItem.reduce<number>((acc, cur) => acc + cur.totalPrice, 0) +
                (shopInfo.option_products?.length > 0
                  ? shopInfo.current_price
                  : 0)
            )}{' '}
            원
          </div>
        </div>
      </section>
      <section>
        {/* <div className="flex flex-col justify-center w-full bg-gray-100 rounded-[2px] overflow-hidden px-3 py-5 mb-2.5">
                    <Typography variant="h6">[유의 사항 영역]</Typography>
                    <Typography variant="h6">포인트는 적립 일로부터 3년간 유효합니다.</Typography>
                </div> */}
        <div className="flex  gap-4 w-full justify-center rounded-md">
          {gubun !== 'course' && (
            <Button
              disabled={buttonDisabled}
              className="w-full border border-purple5 bg-white text-purple5 hover:bg-purple0 shadow-none hover:shadow-none rounded-[4px]"
              onClick={eventCartHandle}
            >
              장바구니
            </Button>
          )}
          <Button
            disabled={buttonDisabled}
            className="w-full bg-purple5 hover:bg-purple6 shadow-none hover:shadow-none rounded-[4px]"
            onClick={eventPurchaseHandle}
          >
            구매하기
          </Button>
        </div>
      </section>
    </Fragment>
  );
};
