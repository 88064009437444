import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { studentType } from '../../../constants/types/lms-type';
import { getStudentDetail, putStudentDetail } from './func';
import TextWinds from '../../../components/common/TextWinds';
import HFinput from '../../../components/common/input/HFinput';
import HFinputPhone from '../../../components/common/input/HFinputPhone';
import HFbutton from '../../../components/common/input/HFbutton';
import { useFieldArray, useForm } from 'react-hook-form';
import HFselect from '../../../components/common/input/HFselect';
import { Button } from '@material-tailwind/react';
import { Address, useDaumPostcodePopup } from 'react-daum-postcode';
import { useAppDispatch } from '../../../store/hook';
import { openAlert } from '../../../store/features/modalSlice';

const StudentDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [oldStudnetData, setOldStudentData] = useState<studentType>();
  const { classId, studentId } = useParams<{
    classId: string;
    studentId: string;
  }>();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm<studentType>({
    defaultValues: {
      name: '',
      uid: '',
      parents: [
        {
          name: '',
          phone: '',
          parentType: '',
        },
      ],
    },
  });
  const uid = watch('uid');
  const phone = watch('phone');
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'parents',
  });

  const init = () => {
    getStudentDetail(classId, Number(studentId)).then((data) => {
      setOldStudentData(data);
      reset(data);
      setIsEdit(false);
    });
  };

  useEffect(() => {
    init();
  }, []);

  const daumPostHook = useDaumPostcodePopup();
  const daumPostHandleComplete = (data: Address) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setValue('address', fullAddress);
    setValue('zipCode', data.zonecode);
  };
  const daumPostHandleClick = () => {
    if (!isEdit) return;
    daumPostHook({ onComplete: daumPostHandleComplete });
  };

  const submitHandler = (data: studentType) => {
    putStudentDetail(classId, Number(studentId), data).then((res) => {
      if (res.statusCode === 200) {
        init();
        dispatch(openAlert({ message: '수정이 완료되었습니다.' }));
      } else {
        console.error(res);
      }
    });
  };

  const cancelHandler = () => {
    reset(oldStudnetData);
    setIsEdit(false);
  };

  return (
    uid && (
      <div>
        <header className="p-6">
          <TextWinds type="title_h2" xlType="title_h2">
            학생 상세 보기
          </TextWinds>
        </header>
        <form className="flex flex-col gap-5 p-5 bg-white shadow-card rounded-0 xl:rounded-lg m-0 xl:mx-5 max-w-[500px] mb-20">
          <section className='border-b border-gray2'>
            <TextWinds type="title_h3" xlType="title_h3">
              필수 정보
            </TextWinds>
            <div className="grid grid-cols-2 gap-2 mt-4">
              <HFinput
                type="text"
                disabled
                register={register('name', {
                  required: { value: true, message: '학생명을 입력해주세요.' },
                })}
                error={errors.name?.message}
                label="* 학생명"
                className={isEdit ? '' : 'text-gray6'}
              />
              <HFinput
                type="text"
                disabled
                register={register('uid', {
                  required: {
                    value: true,
                    message: '아이디를 입력해주세요.',
                  },
                })}
                error={errors.uid?.message}
                label="* 아이디"
                className="text-gray6"
              />
            </div>
          </section>
          <section className='border-b border-gray2'>
            <TextWinds type="title_h3" xlType="title_h3">
              상세정보
            </TextWinds>
            <div className="mt-4">
              <div className="flex gap-x-2">
                <div className="flex flex-col gap-y-2 w-full">
                  <HFinputPhone
                    label="학생 연락처"
                    placeholder="- 없이 번호만 입력"
                    name="phone"
                    disabled={!isEdit}
                    register={register('phone')}
                    defaultValue={phone}
                    className={isEdit ? '' : 'text-gray6'}
                  />
                </div>
                <div className="flex flex-col gap-y-2  w-full">
                  <HFinput
                    label="이메일"
                    placeholder="이메일 주소 입력"
                    name="email"
                    disabled={!isEdit}
                    register={register('email')}
                    className={isEdit ? '' : 'text-gray6'}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-y-2 w-full">
                <TextWinds type="content_body3" color="gray9">
                  주소
                </TextWinds>

                <div className="relative flex w-full">
                  <HFinput
                    placeholder="주소 입력"
                    name="address"
                    readOnly
                    onClick={daumPostHandleClick}
                    register={register('address')}
                    className={isEdit ? '' : 'text-gray6'}
                  />
                  {isEdit && (
                    <Button
                      size="sm"
                      className="!absolute h-[41px] right-1 top-1 rounded bg-purple5 text-white hover:bg-purple6"
                      onClick={daumPostHandleClick}
                    >
                      주소 검색
                    </Button>
                  )}
                </div>
                <HFinput
                  disabled
                  label="우편번호"
                  register={register('zipCode')}
                  className={isEdit ? '' : 'text-gray6'}
                />
              </div>
              <div className="flex gap-x-2">
                <div className="flex flex-col gap-y-2 w-full">
                  <HFinput
                    name="schoolName"
                    label="학교명"
                    placeholder="학교명 입력"
                    disabled={!isEdit}
                    register={register('schoolName')}
                    className={isEdit ? '' : 'text-gray6'}
                  />
                </div>
                <div className="flex flex-col gap-y-2  w-full">
                  <HFinput
                    name="schoolGroup"
                    label="반"
                    placeholder="반 입력"
                    disabled={!isEdit}
                    register={register('schoolGroup')}
                    className={isEdit ? '' : 'text-gray6'}
                  />
                </div>
              </div>
            </div>
          </section>
          <section>
            <TextWinds type="title_h3" xlType="title_h3">
              보호자 정보
            </TextWinds>
            <div className="flex flex-col gap-y-2.5 mt-5">
              {fields.map((item, index) => (
                <div key={index} className="bg-gray1 p-4 rounded">
                  <div className="text-right h-5">
                    {isEdit && (
                      <span
                        className="p-1 text-sm font-bold text-purple5 hover:text-purple6 cursor-pointer"
                        onClick={() => remove(index)}
                      >
                        삭제하기
                      </span>
                    )}
                  </div>

                  <div className="grid grid-cols-2 gap-x-2">
                    <HFinput
                      label="보호자명"
                      name="parents[0].name"
                      placeholder="보호자 성함 입력"
                      disabled={!isEdit}
                      register={register(`parents.${index}.name`)}
                      defaultValue={item.name}
                      className={isEdit ? '' : 'text-gray6'}
                    />

                    <HFinputPhone
                      label="보호자 연락처"
                      name="parents[0].phone"
                      placeholder="- 없이 번호만 입력"
                      disabled={!isEdit}
                      register={register(`parents.${index}.phone`)}
                      defaultValue={item.phone}
                      className={isEdit ? '' : 'text-gray6'}
                    />
                  </div>
                  <TextWinds type="content_body3">성별</TextWinds>
                  <HFselect
                    placeholder="성별 선택"
                    data={[
                      {
                        label: '부',
                        value: 'FATHER',
                      },
                      {
                        label: '모',
                        value: 'MOTHER',
                      },
                    ]}
                    register={register(`parents.${index}.parentType`)}
                    selected={item.parentType}
                    disabled={!isEdit}
                  />
                </div>
              ))}
              {isEdit && (
                <div
                  className="flex items-center gap-2 p-2.5 cursor-pointer"
                  onClick={() =>
                    append({
                      name: undefined,
                      phone: undefined,
                      parentType: undefined,
                    })
                  }
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/svg/icon-plus-circle-purple.svg'
                    }
                    alt="pluscircle"
                  />
                  <TextWinds type="button2" color="purple5">
                    보호자 추가하기
                  </TextWinds>
                </div>
              )}
            </div>
          </section>
          <section className="flex flex-col gap-5">
            {isEdit ? (
              <>
                <HFbutton
                  onClick={() => {
                    handleSubmit(submitHandler)();
                  }}
                >
                  저장
                </HFbutton>
                <HFbutton type="Outlined" onClick={() => cancelHandler()}>
                  취소
                </HFbutton>
              </>
            ) : (
              <>
                <HFbutton onClick={() => setIsEdit(true)}>수정하기</HFbutton>
                <HFbutton
                  type="Outlined"
                  onClick={() => navigate(`/lms/signup-management/${classId}`)}
                >
                  목록으로
                </HFbutton>
              </>
            )}
          </section>
        </form>
      </div>
    )
  );
};

export default StudentDetail;
