import { CheckIcon } from '@heroicons/react/24/outline';
import { Radio } from '@material-tailwind/react';
import TextWinds from '../../../../../components/common/TextWinds';
import { classGroupType } from '../../../../../constants/types/student-type';

interface ClassAssignItemProps {
  classItem: classGroupType;
  onSelectedList: (target: classGroupType) => void;
}
const ClassAssignItem = ({
  classItem,
  onSelectedList,
}: ClassAssignItemProps) => {
  return (
    <div className="m-0 p-1 bg-gray0">
      <label
        htmlFor={`vertical-list-item-${classItem.id}`}
        className="flex w-full cursor-pointer items-center justify-items-center gap-2 px-3"
      >
        <div>
          <Radio
            name="vertical-list"
            id={`vertical-list-item-${classItem.id}`}
            icon={
              <CheckIcon stroke="white" strokeWidth={2.5} className="w-4 h-4" />
            }
            className="w-6 h-6 border-0 opacity-50 checked:opacity-100 hover:before:opacity-0 bg-purple5"
            onChange={() => onSelectedList(classItem)}
          />
        </div>
        <TextWinds type={'content'}>{classItem.name}</TextWinds>
      </label>
    </div>
  );
};

export default ClassAssignItem;
