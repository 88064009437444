import React, { useEffect } from 'react';
import TextWinds from '../common/TextWinds';
import { useAppDispatch } from '../../store/hook';
import { closeModal } from '../../store/features/modalSlice';

export interface ModalConfirmProps {
  message: string;
  cancelText?: string;
  confirmText?: string;
  confirmColor?: string;
  confirmHoverColor?: string;
  onConfirm: () => void;
  onCancel?: () => void;
}
const ModalConfirm = ({
  message,
  cancelText = '취소',
  confirmText = '확인',
  confirmColor = 'purple5',
  confirmHoverColor = 'purple6',
  onConfirm: onclick,
  onCancel,
}: ModalConfirmProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    document.addEventListener('keydown', keyboardHandler);
    return () => {
      document.removeEventListener('keydown', keyboardHandler);
    };
  }, []);

  const keyboardHandler = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeBtnhandler();
    } else if (event.key === 'Enter') {
      confirmBtnhandler();
    }
  };

  const closeBtnhandler = () => {
    dispatch(closeModal());
    onCancel && onCancel();
  };
  const confirmBtnhandler = () => {
    dispatch(closeModal());
    onclick();
  };
  return (
    <div className="flex flex-col gap-4 w-[335px] p-4 bg-white shadow-card mb-40 rounded-md mountAnimation">
      <div className="flex flex-col">
        {message.split('\n').map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </div>

      <div className="grid grid-cols-2 gap-2">
        <TextWinds
          type="button2"
          color="purple5"
          className="cursor-pointer hover:bg-gray2 p-2 rounded-md border text-center"
          onClick={closeBtnhandler}
        >
          {cancelText}
        </TextWinds>
        <TextWinds
          type="button2"
          color="orange5"
          className={`cursor-pointer hover:bg-gray2 p-2 rounded-md border text-center bg-${confirmColor} text-white hover:bg-${confirmHoverColor}`}
          onClick={confirmBtnhandler}
        >
          {confirmText}
        </TextWinds>
      </div>
    </div>
  );
};

export default ModalConfirm;
