// import { Select, Option } from '@material-tailwind/react';
import { Select } from 'antd';
import React, { useEffect } from 'react';

export type LmsSelectOption = {
  key: string;
  value: string;
  label: string;
};

interface LmsSelectProps {
  label: string;
  options: LmsSelectOption[];
  className?: string;
  value?: string;
  notFoundContent?: string;
  clearVisible?: boolean;
}
const LmsSelect = ({
  label,
  options,
  className,
  value,
  notFoundContent = '검색 결과가 없습니다.',
  clearVisible = true,
  onChange,
}: LmsSelectProps & { onChange: (v: string) => void }) => {
  const [selected, setSelected] = React.useState<string | undefined>();

  useEffect(() => {
    handleChange(value);
  }, [value]);

  const handleChange = (value: string) => {
    setSelected(value);
    onChange(value);
  };
  return (
    <div className="flex gap-2 items-center">
      <div className="w-[100px] flex lg:hidden">{label}</div>
      <Select
        value={value && selected}
        onChange={handleChange}
        className={className}
        placeholder={label}
        notFoundContent={notFoundContent}
      >
        {options.map(({ key, value, label }) => (
          <Select.Option key={key} value={value}>
            {label}
          </Select.Option>
        ))}
      </Select>
      {selected && clearVisible && (
        <div className="cursor-pointer" onClick={() => handleChange(undefined)}>
          <img src={process.env.PUBLIC_URL + '/svg/icon/x-circle.svg'} />
        </div>
      )}
    </div>
  );
};

export const LmsMultiSelect = ({
  label,
  options,
  className,
  onChange,
}: LmsSelectProps & { onChange: (v: string[]) => void }) => {
  return (
    <div className="flex gap-2 items-center">
      <div className="w-[100px] flex lg:hidden">{label}</div>
      <Select
        mode="multiple"
        onChange={onChange}
        className={className}
        placeholder={label}
      >
        {options.map(({ key, value, label }) => (
          <Select.Option key={key} value={value}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export function toLmsSelectOptions<T>(
  items: T[],
  keyField: keyof T,
  valueField: keyof T,
  labelField: keyof T
): LmsSelectOption[] {
  if (!items) return [];
  return items.map((item) => ({
    key: String(item[keyField]),
    value: String(item[valueField]),
    label: String(item[labelField]),
  }));
}

export default LmsSelect;
