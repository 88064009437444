import { Carousel } from '@material-tailwind/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IMAGE_LIST } from '../../constants/bannerData';

const CarouselNavigation = () => {
  const navigate = useNavigate();
  const clickHandler = (link: string, type?: '_blank') => {
    if (type === '_blank') {
      window.open(link, type);
    } else {
      navigate(link);
    }
  };
  return (
    <Carousel
      autoplay
      loop
      navigation={({ setActiveIndex, activeIndex, length }) => (
        <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
          {new Array(length).fill('').map((_, i) => (
            <span
              key={i}
              className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                activeIndex === i ? 'w-8 bg-white' : 'w-4 bg-white/50'
              }`}
              onClick={() => setActiveIndex(i)}
            />
          ))}
        </div>
      )}
    >
      {IMAGE_LIST.map(({ img, mobile, title, link, type }, key) => (
        <div
          key={key}
          onClick={() => clickHandler(link, type)}
          className="cursor-pointer"
        >
          <img
            src={process.env.PUBLIC_URL + img}
            alt={title}
            className="h-full w-full max-h-[423px] object-cover hidden lg:block"
          />
          <img
            src={process.env.PUBLIC_URL + mobile}
            alt={title}
            className="h-full w-full max-h-[423px] object-cover block lg:hidden"
          />
        </div>
      ))}
    </Carousel>
  );
};

export default CarouselNavigation;
