import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
const cors = require('cors');

const instance = axios.create({
  baseURL: process.env.REACT_APP_JEBS_API_URL,
  withCredentials: true,
  timeout: 30000,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Origin': '*',
  },
});

const onRequest = (config: InternalAxiosRequestConfig) => {
  const { method, url } = config;

  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};
const onReqError = (error: AxiosError | Error) => {
  return Promise.reject(error);
};
const onResponse = (res: AxiosResponse) => {
  const { method, url } = res.config;
  return res;
};

let isAlertShown = false;

const onResError = (error: AxiosError | Error) => {
  if (axios.isAxiosError(error)) {
    const { method, url } = error.config as InternalAxiosRequestConfig;
    if (error.response) {
      const { statusCode, message } = error.response.data;
      if (isAlertShown) return;
      isAlertShown = true;
      switch (statusCode) {
        case 401:
          alert('로그인이 필요합니다.');
          if (!localStorage.getItem('redirect')) {
            localStorage.setItem('redirect', window.location.href);
          }
          window.location.href = '/signin';

          setTimeout(() => {
            isAlertShown = false;
          }, 1000);

          return error.response;
        case 403:
          alert('접근 권한이 없습니다.');
          window.location.href = '/';
          return error.response;
        default:
          return Promise.reject(error);
      }
    }
  } else {
    console.log(`🚨 [API] | Error ${error.message}`);
  }

  return Promise.reject(error);
};

instance.interceptors.request.use(onRequest, onReqError);
instance.interceptors.response.use(onResponse, onResError);

export default instance;
