import React, { useEffect, useMemo, useState } from 'react';
import { ListBulletIcon } from '@heroicons/react/24/outline';
import TextWinds from '../../../../components/common/TextWinds';
import { useParams } from 'react-router-dom';
import {
  Class,
  CourseChapter,
  CourseLesson,
  PaginationProps,
} from '../../../../constants/types';
import {
  getBookChapterList,
  getLessonList,
  getTakeClassList,
} from '../../../../api/utils/lms/lmsApi';
import LmsSelect, {
  LmsMultiSelect,
  toLmsSelectOptions,
} from '../../../../components/lms/LmsSelect';
import { Pagination, Table } from 'antd';
import { myLearningLessonInfoType } from '../../../../constants/types/learning-type';
import { columns, resourceTypeBase, resourceTypeForAdvanced } from './columns';
import { selectLmsLists } from '../../../../store/features/lmsSlice';
import { useAppSelector } from '../../../../store/hook';
import { openContentsView } from '../../../../api/utils/util-func';

const TakeClass = () => {
  const classId = useParams().classId;
  const lmsLists = useAppSelector(selectLmsLists);

  // 첫번째 필터 북/챕터 선택
  const [chapterId, setChapterId] = useState(undefined);
  // 두번째 필터 레슨 선택
  const [lessonId, setLessonId] = useState(undefined);
  // 세번째 필터 자료 유형 선택
  const [divisionArr, setDivisionArr] = useState(undefined);

  // 북/챕터 리스트
  const [chapterList, setChapterList] = useState<Class>();
  // 레슨 리스트
  const [lessonList, setLessonList] = useState<CourseLesson[]>();

  // 페이징 정보
  const [pageInfo, setPageInfo] = useState<PaginationProps>({
    page: 1,
    pageSize: 10,
    total: 0,
    lastPage: 0,
  });

  // 수업하기 리스트. 실제 화면에 출력되는 데이터
  const [tableDatas, setTableDatas] = useState<myLearningLessonInfoType[]>([]);

  const [contentView, setContentView] = useState(undefined);

  // 페이징 정보 변경
  const changePaginations = (pageSize: number, pageNumber: number) => {
    setPageInfo({ ...pageInfo, page: pageNumber, pageSize: pageSize });
  };

  const isAdvanced = useMemo(() => {
    if (!lmsLists) return false;

    return lmsLists.course.id === 'XoaJAz_Vg6fJ1QxQ';
  }, [lmsLists]);

  useEffect(() => {
    getBookChapterList(classId).then((res) => {
      setChapterList(res);
    });
  }, [classId]);

  useEffect(() => {
    const params = {
      page: pageInfo.page,
      pageSize: pageInfo.pageSize,
      chapterId: chapterId,
      lessonId: lessonId,
      type: divisionArr,
    };

    getTakeClassList(classId, params).then((res) => {
      setPageInfo((prev) => ({
        ...prev,
        total: res.page.total,
        lastPage: res.page.lastPage,
      }));
      setTableDatas(res.result);
    });
  }, [
    classId,
    pageInfo.page,
    pageInfo.pageSize,
    chapterId,
    lessonId,
    divisionArr,
  ]);

  const contentsOpenHandler = (id: string) => {
    const dataToSend = {
      type: 'contentsView',
      contents: {
        classId,
        contentId: id,
      },
    };
    openContentsView(dataToSend, setContentView);
  };

  return !chapterList ? null : (
    <div className="flex flex-col gap-5 m-0 md:m-5 p-5 bg-white rounded-lg">
      <div className="flex gap-2 items-center">
        <ListBulletIcon className="w-6 h-6 text-purple5" />
        <TextWinds type="title_h2" xlType="title_h3" color="purple5">
          수업하기
        </TextWinds>
      </div>

      <div className="p-4 bg-gray1 rounded">
        <TextWinds type="caption1" color="gray8">
          수업에 필요한 자료를 선택한 후 ‘수업하기’ 버튼을 클릭하여 새창에서
          자료를 이용하세요.
        </TextWinds>
      </div>
      <div className="flex flex-col lg:flex-row gap-2 justify-start">
        <LmsSelect
          label="북/챕터 선택"
          options={toLmsSelectOptions<CourseChapter>(
            chapterList.course?.chapters,
            'id',
            'id',
            'name'
          )}
          value={chapterId}
          onChange={(book) => {
            setChapterId(book);
            setLessonId(undefined);
            setLessonList(undefined);
            setPageInfo({ ...pageInfo, page: 1 });
            book &&
              getLessonList(classId, book).then((res) => {
                setLessonList(res);
              });
          }}
          className="w-[200px]"
        />

        <LmsSelect
          label="레슨 선택"
          options={toLmsSelectOptions<CourseLesson>(
            lessonList,
            'id',
            'id',
            'title'
          )}
          value={lessonId}
          onChange={(v) => {
            setLessonId(v);
            setPageInfo({ ...pageInfo, page: 1 });
          }}
          notFoundContent="북/챕터를 먼저 선택해주세요."
          className="w-[200px]"
        />
        <LmsMultiSelect
          label="자료 유형"
          options={toLmsSelectOptions(
            isAdvanced ? resourceTypeForAdvanced : resourceTypeBase,
            'key',
            'key',
            'name'
          )}
          onChange={(v) => {
            setDivisionArr(v);
          }}
          className="w-[200px]"
        />
      </div>
      <div className="flex flex-col gap-5">
        <Table
          dataSource={tableDatas}
          columns={columns(pageInfo, isAdvanced, contentsOpenHandler)}
          rowKey={(row) => row.id}
          scroll={{ x: 'max-content' }}
          size="small"
          pagination={false}
        />
        <Pagination
          current={pageInfo.page}
          pageSize={pageInfo.pageSize}
          total={pageInfo.total}
          onChange={(page, pageSize) => {
            changePaginations(pageSize, page);
          }}
        />
      </div>
    </div>
  );
};

export default TakeClass;
