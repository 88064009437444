import { Input, Button } from '@material-tailwind/react';
import { useState } from 'react';
import { phoneFormat } from '../../../api/utils/format/numbers';
import Inputs from '../../../components/common/Inputs';
import TextWinds from '../../../components/common/TextWinds';
import { openAlert } from '../../../store/features/modalSlice';
import { useAppDispatch } from '../../../store/hook';
import { studentGuardianType } from '../../../constants/types/student-type';
import { Gender } from '../../../enum';
import { AgreementType } from '../../../components/common/Agreement';
import { AgreementBox } from '../../../components/common/AgreementBox';
import useSmsHook from '../../../controllers/useSmsHook';
import { timeLeftCheck, timeLeftToString } from '../../../controllers/useTimer';

export type ParentInfo = {
  name: string;
  birth: Date;
  gender: string;
  phone: string;
};

type GuardianType = Omit<studentGuardianType, 'id'>;

interface GuardianInputProps {
  guadian: GuardianType;
  setGuadian: React.Dispatch<React.SetStateAction<GuardianType>>;
}
const GuardianInput = ({ guadian, setGuadian }: GuardianInputProps) => {
  const dispatch = useAppDispatch();

  const [verificationCode, setVerificationCode] = useState<string>('');
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const { sendVerifyNumber, checkVerifyNumber, timeLeft } = useSmsHook();

  const agreement = [
    { agree: AgreementType.PERSONAL_POLICY, essential: true },
    { agree: AgreementType.PERSONAL_COLLECTION, essential: true },
  ];

  // 전화번호 입력시 '-' 제거 및 인증 초기화
  const handlePhoneChange = (value: string) => {
    setGuadian({ ...guadian, phone: value.replace(/-/g, '') });
    setIsConfirm(false);
    setVerificationCode('');
  };

  const postSms = async () => {
    if (!guadian.phone) {
      dispatch(openAlert({ message: '전화번호를 입력해주세요.' }));
      return;
    }
    sendVerifyNumber(guadian.phone).then((msg) => {
      dispatch(openAlert({ message: msg }));
    });
  };

  // 인증코드 검증
  const putSms = async () => {
    if (!verificationCode) {
      dispatch(openAlert({ message: '인증번호를 입력해주세요.' }));
      return;
    }
    checkVerifyNumber(guadian.phone, verificationCode).then((res) => {
      if (res.confirm) {
        setIsConfirm(true);
      }
      dispatch(openAlert({ message: res.msg }));
    });
  };

  return (
    <>
      <TextWinds type={'title_h5'}>보호자 성함</TextWinds>
      <Input
        type={'text'}
        placeholder="보호자 성함"
        className="!border-t-blue-gray-200 focus:!border-t-gray-900 h-[50px] border border-solid border-gray6 rounded-[4px]"
        containerProps={{
          className: 'min-w-full h-[50px]',
        }}
        onChange={(e) => setGuadian({ ...guadian, name: e.target.value })}
      />

      <Inputs
        title="보호자 생년월일 및 성별"
        type="birthday"
        placeholder="배송지명을 입력해주세요"
        value="birthday2"
        value2="gender2"
        onChange={(e) => setGuadian({ ...guadian, birthday: new Date(e) })}
        onChange2={(e) => setGuadian({ ...guadian, gender: e as Gender })}
      />
      <section className="flex flex-col gap-2 mt-3">
        <div className="relative flex w-full">
          <Input
            type="text"
            label="전화번호"
            className="pr-20"
            containerProps={{
              className: 'min-w-0',
            }}
            value={phoneFormat(guadian.phone)}
            onChange={(e) => handlePhoneChange(e.target.value)}
            disabled={isConfirm}
          />
          <Button
            size="sm"
            className="!absolute right-1 top-1 rounded bg-purple5"
            onClick={() => postSms()}
            disabled={isConfirm}
          >
            {timeLeftCheck(timeLeft) ? timeLeftToString(timeLeft) : '인증번호'}
          </Button>
        </div>
        <div className="relative flex w-full">
          <Input
            type="text"
            label="인증번호"
            className="pr-20"
            containerProps={{
              className: 'min-w-0',
            }}
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            disabled={isConfirm}
          />
          <Button
            size="sm"
            className="!absolute right-1 top-1 rounded bg-purple5"
            onClick={() => putSms()}
            disabled={isConfirm}
          >
            인증하기
          </Button>
        </div>

        <AgreementBox
          agreementList={agreement}
          essentialChecked={(flag) => {
            setGuadian({ ...guadian, agreementPersonalInfo: flag });
          }}
        />
      </section>
    </>
  );
};

export default GuardianInput;
