import { Button, Checkbox, Typography } from "@material-tailwind/react";
import React from "react";
import { CartItem, CartProps } from "../../constants/types";
import CheckBoxes from "../common/CheckBoxes";
import Buttons from "../common/Buttons";
import { cartCheckedProps } from "../../store/features/cartSlice";

const ProductAllSelect = ({
    selectAll,
    productState,
    selectedProducts,
    handleSelectAllChange,
    handleRemoveAllItem,
}: {
    selectAll: boolean;
    productState: CartItem[];
    selectedProducts: cartCheckedProps[];
    handleSelectAllChange:() => void;
    handleRemoveAllItem: () => void;
}) => {
    return (
        <div className="flex flex-row items-center justify-between px-0 ">
            <CheckBoxes
                type="full"
                checked={selectAll}
                onChange={handleSelectAllChange}
                labelValue={`전체 선택 (
                    ${selectedProducts.length > 0 ? selectedProducts.filter((e) => e.isChecked).length : 0
                    } / ${productState?.length}
                )`}
                disabled={selectedProducts.length === 0 ? true : false}
            />
            <Buttons
                type="text"
                color="purple5"
                className="justify-end bg-transparent text-purple5"
                onClick={handleRemoveAllItem}
            >
                전체 삭제하기
            </Buttons>
        </div>
    );
};

export default ProductAllSelect;
