import { ColumnsType } from 'antd/es/table';
import { studentType } from '../../../constants/types/student-type';
import { PaginationProps } from '../../../constants/types';
import { dateToString } from '../../../api/utils/format/numbers';
import { Link } from 'react-router-dom';

const columns = (
  classId: string,
  pageInfo: PaginationProps,
  resetPassword: (id: number) => void
): ColumnsType<studentType> => [
  {
    title: 'No.',
    key: 'index',
    render: (_, __, index) =>
      (pageInfo.page - 1) * pageInfo.pageSize + index + 1,
    align: 'center',
  },
  {
    title: '학생명',
    key: 'name',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '아이디',
    key: 'uid',
    dataIndex: 'uid',
    align: 'center',
  },
  {
    title: '반',
    key: 'classGroup',
    render: (_, row) =>
      row.classInfos.map((classInfo) => classInfo.classGroup?.name).join(', '),
    align: 'center',
  },
  {
    title: '아이디',
    key: 'uid',
    dataIndex: 'uid',
    align: 'center',
  },
  {
    title: '등록일',
    key: 'createdAt',
    render: (_, row) => dateToString(row.createdAt),
    align: 'center',
  },
  {
    title: '상세',
    key: 'detail',
    render: (_, row) => (
      <Link
        to={`/lms/signup-management/${classId}/${row.id}`}
        className="text-purple5 text-sm font-bold"
      >
        상세보기
      </Link>
    ),
    align: 'center',
  },
  {
    title: '비밀번호 초기화',
    key: 'reset',
    render: (_, row) => (
      <div
        className="text-purple5 text-sm font-bold cursor-pointer"
        onClick={() => resetPassword(row.id)}
      >
        초기화
      </div>
    ),
    align: 'center',
  },
];

export default columns;
