import { Button } from '@material-tailwind/react';
import React from 'react';
import { openNewWindow } from '../../../api/utils/util-func';

const WhatIsCertification = () => {
  return (
    <Button
      className="w-full shadow-card bg-white text-center py-8 mt-8 border-purple5 border rounded-lg text-purple5 text-xl font-bold"
      onClick={() =>
        openNewWindow(
          'https://alluring-viper-412.notion.site/58dc51de03a848889ee3904f3e941271?pvs=4'
        )
      }
    >
      🗣️ 암송인증제란?
    </Button>
  );
};

export default WhatIsCertification;
