export type carouselItem = {
  img: string;
  mobile: string;
  title: string;
  link: string;
  type?: '_blank';
};

export const IMAGE_LIST: carouselItem[] = [
  {
    img: 'images/home/banner/bannerD-24.08.19.jpg',
    mobile: 'images/home/banner/bannerM-24.08.19.jpg',
    title: '리뉴얼 홈페이지 배너- 영어로 그림전도 출간',
    link: '/shop/details/U9NHpfT92l_A4zTE',
  },
  {
    img: 'images/home/banner/bannerD-24.07.25-2.jpg',
    mobile: 'images/home/banner/bannerM-24.07.25-2.jpg',
    title: '리뉴얼 홈페이지 배너- 8월 홈스쿨 설명회',
    link: 'https://forms.gle/rjPNz9mtKWLkKE7n9',
    type: '_blank',
  },
  {
    img: 'images/home/banner/bannerD-24.08.01.jpg',
    mobile: 'images/home/banner/bannerM-24.08.01.jpg',
    title: '리뉴얼 홈페이지 배너- 8월 홈페이지 가입 이벤트',
    link: '/signup',
  },
  {
    img: 'images/home/banner/bannerD-24.07.25-1.jpg',
    mobile: 'images/home/banner/bannerM-24.07.25-1.jpg',
    title: '리뉴얼 홈페이지 배너- 사도행전(변경)',
    link: 'shop/details/CoV_Dm3uYHgZR8x3',
  },
  {
    img: 'images/home/banner/bannerD1-24.06.11.jpg',
    mobile: 'images/home/banner/bannerM1-24.06.11.jpg',
    title: '리뉴얼 홈페이지 배너- 로마서',
    link: '/shop/details/bHYsQSuuInn9fG3b',
  },
];
