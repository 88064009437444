import { PencilIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useEffect, useState } from 'react';
import Buttons from '../../../../components/common/Buttons';
import TextWinds from '../../../../components/common/TextWinds';
import MoLayout from '../../../../components/lms/MoLayout';
import { useParams, useSearchParams } from 'react-router-dom';
import { ToDoMgmtSubType, TodoMgmtSubTypeName } from '../../../../enum';
import RegisterTodoPage from './RegisterTodoPage';
import RegisterTodoDetail from './RegisterTodoDetail';
import TodoStatusPage from './TodoStatusPage';
import HFbutton from '../../../../components/common/input/HFbutton';
import { openNewWindow } from '../../../../api/utils/util-func';

const TodoMgmt = () => {
  const subType = useParams().subType.toUpperCase();
  const [params, setParams] = useSearchParams();
  type StageType = 'todo' | 'state' | 'regist';
  const [selectComponent, setSelectComponent] = useState<StageType>('todo');
  const [subTypeName, setSubTypeName] = useState(undefined);

  useEffect(() => {
    const stage = params.get('stage');
    setSelectComponent((stage as StageType) || 'todo');
  }, [params]);

  useEffect(() => {
    switch (subType) {
      case ToDoMgmtSubType.RECITATION:
        return setSubTypeName(TodoMgmtSubTypeName.RECITATION);
      case ToDoMgmtSubType.JEBSON:
        return setSubTypeName(TodoMgmtSubTypeName.JEBSON);
      case ToDoMgmtSubType.JEBSPL:
        return setSubTypeName(TodoMgmtSubTypeName.JEBSPL);
      default:
        return;
    }
  }, [subType]);

  const setSearchParams = (key: string, value: string) => {
    params.set(key, value);
    setParams(params);
  };

  const getDescription = (
    subType: string
  ): {
    desc1: string;
    desc2: string;
  } => {
    switch (subType) {
      case ToDoMgmtSubType.RECITATION:
        return {
          desc1:
            '국내최초! AI로 평가되는 암송인증제! 응시권을 사용하여 학생에게 인증제 할 일을 등록해주세요.',
          desc2: '학생은 본인 계정으로 접속하여 인증제를 이용할 수 있습니다. ',
        };
      case ToDoMgmtSubType.JEBSON:
        return {
          desc1: '온라인 복습 과제, 젭스온!',
          desc2:
            '학생에게 젭스온 할 일을 등록해 주세요. 학생은 본인 계정으로 접속하여 젭스온을 이용할 수 있습니다.',
        };
      case ToDoMgmtSubType.JEBSPL:
        return {
          desc1: '주일학교용 온라인 과제, 젭스플!',
          desc2:
            '이용권을 사용하여 학생에게 젭스플 할 일을 등록해주세요. 학생은 본인 계정으로 접속하여 젭스플을 이용할 수 있습니다.',
        };
      default:
        return {
          desc1: '',
          desc2: '',
        };
    }
  };

  return (
    <Fragment>
      {/* <MoLayout
        type="todo"
        title={`할 일(과제) 관리`}
        text1={getDescription(subType).desc1}
        text2={getDescription(subType).desc2}
      /> */}
      <section className="pt-6 xl:px-5">
        <div className="flex flex-col gap-3">
          <div className="px-5 xl:px-0">
            <TextWinds type="title_h3" className="mb-[2px]">
              {subTypeName} 관리
            </TextWinds>
            <TextWinds type="content_body3">
              {getDescription(subType).desc1}
              <br />
              {getDescription(subType).desc2}
            </TextWinds>
            {subType === ToDoMgmtSubType.RECITATION && (
              <div className="w-full xl:max-w-[160px] mt-3">
                <HFbutton
                  height={36}
                  onClick={() =>
                    openNewWindow(
                      'https://jebs-notice.oopy.io/95f38ff5-d145-41c3-b1fd-aee71fefda44'
                    )
                  }
                >
                  와펜 신청하기
                </HFbutton>
              </div>
            )}
          </div>
          {selectComponent === 'regist' ? (
            <RegisterTodoDetail />
          ) : (
            <div className="bg-white py-4 xl:p-4 xl:pt-6 xl:shadow-card xl:rounded-lg">
              <div className="flex gap-x-4 mx-5 pb-[18px] border-b border-gray4 xl:mx-0">
                <Buttons
                  type="text"
                  color={selectComponent === 'todo' ? 'purple5' : 'gray7'}
                  onClick={() => setSearchParams('stage', 'todo')}
                  className="gap-x-1"
                >
                  <PencilIcon className="w-6 h-6" />
                  <TextWinds
                    type="title_h2"
                    color={selectComponent === 'todo' ? 'purple5' : 'gray7'}
                  >
                    할 일 등록
                  </TextWinds>
                </Buttons>
                <Buttons
                  type="text"
                  color={selectComponent === 'state' ? 'purple5' : 'gray7'}
                  onClick={() => setSearchParams('stage', 'state')}
                  className="gap-x-1"
                >
                  {selectComponent === 'state' ? (
                    <img
                      src={process.env.PUBLIC_URL + '/svg/icon-chart-on.svg'}
                      alt=""
                    />
                  ) : (
                    <img
                      src={process.env.PUBLIC_URL + '/svg/icon-chart.svg'}
                      alt=""
                    />
                  )}

                  <TextWinds
                    type="title_h2"
                    color={selectComponent === 'state' ? 'purple5' : 'gray7'}
                  >
                    할 일 현황
                  </TextWinds>
                </Buttons>
              </div>
              {selectComponent === 'todo' ? (
                <RegisterTodoPage />
              ) : selectComponent === 'state' ? (
                <TodoStatusPage />
              ) : null}
            </div>
          )}
        </div>
      </section>
    </Fragment>
  );
};

export default TodoMgmt;
