import { Table, TableColumnsType } from 'antd';
import React from 'react';
import { assignedLmsTodosById } from '../../../constants/types/lms-type';
import { getTodoStateDetailById } from '../../../api/utils/lms/lmsApi';
import { TODO_SUBTYPE } from '../../../data/statics-datas';

interface TodoExpandedRowByIdProps {
  classId: string;
  toDoType: string;
  toDoStatus: string;
  id: number;
  subType: string;
}
const TodoExpandedRowById = ({
  classId,
  toDoType,
  toDoStatus,
  id,
  subType,
}: TodoExpandedRowByIdProps) => {
  const [data, setData] = React.useState<assignedLmsTodosById[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoading(true);
    getTodoStateDetailById(classId, toDoType, toDoStatus, id).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, []);

  const selectCol = () => {
    // 데이터가 있을 때만 컬럼을 선택하도록
    // 할일 종류에 따라서 컬럼을 선택
    switch (subType.toUpperCase()) {
      case 'RECITATION':
        return columnsRECITATION;
      case 'JEBSPL':
        return columns;
      case 'JEBSON':
        return columnsWithLesson;
      default:
        console.error('SubType Error', subType);
        return columns;
    }
  };

  return (
    <Table
      columns={selectCol()}
      dataSource={data}
      rowKey={(row) => row.id}
      pagination={false}
      size="small"
      loading={loading}
    />
  );
};

export default TodoExpandedRowById;

const columnsRECITATION: TableColumnsType<assignedLmsTodosById> = [
  {
    title: '할일명',
    align: 'center',
    render: (row) =>
      TODO_SUBTYPE.find((item) => item.subType === row.type)?.name || row.type,
  },
  {
    title: '레벨',
    dataIndex: 'book',
    align: 'center',
  },
  {
    title: '북',
    dataIndex: 'title',
    align: 'center',
  },
  {
    title: '완료일',
    render: (row) =>
      row.completedDate
        ? new Date(row.completedDate).toLocaleDateString()
        : '-',
    align: 'center',
  },
];

const columnsWithLesson: TableColumnsType<assignedLmsTodosById> = [
  {
    title: '할일명',
    dataIndex: 'type',
    align: 'center',
  },
  {
    title: '북/챕터',
    dataIndex: 'book',
    align: 'center',
  },
  {
    title: '레슨',
    dataIndex: 'lesson',
    align: 'center',
  },
  {
    title: '제목',
    dataIndex: 'title',
    align: 'center',
  },
  {
    title: '완료일',
    render: (row) =>
      row.completedDate
        ? new Date(row.completedDate).toLocaleDateString()
        : '-',
    align: 'center',
  },
];
const columns: TableColumnsType<assignedLmsTodosById> = [
  {
    title: '할일명',
    dataIndex: 'type',
    align: 'center',
  },
  {
    title: '북/챕터',
    dataIndex: 'book',
    align: 'center',
  },
  {
    title: '제목',
    dataIndex: 'title',
    align: 'center',
  },
  {
    title: '완료일',
    render: (row) =>
      row.completedDate
        ? new Date(row.completedDate).toLocaleDateString()
        : '-',
    align: 'center',
  },
];
