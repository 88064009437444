import React, { useEffect, useState } from 'react';
import NormalPage from '../../../components/layout/NormalPage';
import TextWinds from '../../../components/common/TextWinds';
import { Input } from '@material-tailwind/react';
import Buttons from '../../../components/common/Buttons';
import IconInfo from './IconInfo';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import instance from '../../../api/axios/utils.ts/instance';
import { useNavigate } from 'react-router-dom';
import { AgreementType } from '../../../components/common/Agreement';
import HFinput from '../../../components/common/input/HFinput';
import HFselect from '../../../components/common/input/HFselect';
import { useAppDispatch } from '../../../store/hook';
import { openAlert } from '../../../store/features/modalSlice';
import GuardianInput from './GuardianInput';
import { studentGuardianType } from '../../../constants/types/student-type';
import {
  AgreementBox,
  AgreementObjType,
} from '../../../components/common/AgreementBox';
import { logout } from '../../../api/utils/util-func';

interface studentInfo {
  password: string;
  password2: string;
  birthday: Date;
  englishName: string;
  gender: string;
  agreementServiceUsage: boolean;
  agreementPersonalInfo: boolean;
  guardianInfo?: Omit<studentGuardianType, 'id'>;
}
const StudentFirstLogin = () => {
  const dispatch = useAppDispatch();

  const methods = useForm<studentInfo>();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = methods;
  const navigate = useNavigate();

  const password = watch('password');
  const birthday = watch('birthday');
  const [agreement, setAgreement] = useState<boolean>();
  const [isMinor, setIsMinor] = useState<boolean>(false);
  const [guardianInfo, setGuadianInfo] = useState<
    Omit<studentGuardianType, 'id'>
  >({
    name: undefined,
    birthday: undefined,
    gender: undefined,
    countryCode: undefined,
    phone: undefined,
    agreementPersonalInfo: undefined,
  });

  const agreementList: AgreementObjType[] = [
    { agree: AgreementType.TERAMS_CONDITION, essential: true },
    { agree: AgreementType.PERSONAL_COLLECTION, essential: true },
    { agree: AgreementType.PROMOTION, essential: false },
  ];

  useEffect(() => {
    if (birthday) {
      const today = new Date();
      const birthDate = new Date(birthday);

      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      const dayDiff = today.getDate() - birthDate.getDate();

      if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--;
      }

      setIsMinor(age < 14);
    }
  }, [birthday]);

  const putStudentFirstSetup = async (data: studentInfo) => {
    try {
      const res = await instance.put('/v1/user/student/first-login', data);
      return res;
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmit: SubmitHandler<studentInfo> = (data) => {
    if (!agreement) {
      dispatch(openAlert({ message: '필수 약관에 동의해주세요' }));
      return;
    }

    const params: studentInfo = {
      ...data,
      englishName: data.englishName.toUpperCase(),
      agreementPersonalInfo: agreement,
      agreementServiceUsage: agreement,
    };

    if (isMinor) {
      if (
        !guardianInfo.name ||
        !guardianInfo.birthday ||
        !guardianInfo.gender ||
        !guardianInfo.phone
      ) {
        dispatch(
          openAlert({
            message: '만 14세 미만의 어린이는 보호자의 동의가 필요합니다.',
          })
        );
        return;
      } else {
        params.guardianInfo = {
          ...guardianInfo,
          countryCode: '82',
        };
      }
    }

    putStudentFirstSetup(params).then((res) => {
      if (res.status === 200) {
        dispatch(
          openAlert({
            message: '입력이 완료 되었습니다. 다시 로그인 해주세요.',
          })
        );
        logout();
      } else {
        dispatch(
          openAlert({ message: '입력이 실패했습니다. 다시 시도해주세요' })
        );
      }
    });
  };

  // const logout = () => {
  //   localStorage.clear();
  //   navigate('/');
  // };

  const checkBoxChange = (id: string, check: boolean) => {};

  return (
    <NormalPage bgColor="white" xlBgColor="gray0">
      <div className="p-0 place-items-center pb-[120px] xl:w-[600px] xl:mx-auto xl:pt-[30px]">
        <div className="bg-white xl:p-[40px]">
          <FormProvider {...methods}>
            <form>
              <section>
                <TextWinds type="title_h1">학생 정보 입력 (최초 1회)</TextWinds>
                <TextWinds type="content" className="mt-4">
                  학생 정보는 이후 내 정보에서 수정 가능합니다.
                </TextWinds>
              </section>

              <section className="flex flex-col gap-4 mt-8">
                <TextWinds type={'title_h5'}>새 비밀번호 설정</TextWinds>
                <HFinput
                  label="새 비밀번호 설정"
                  name="password"
                  type="password"
                  register={register('password', {
                    minLength: {
                      value: 6,
                      message: '6글자 이상 입력해주세요.',
                    },
                    required: '비밀번호를 입력해주세요',
                    pattern: {
                      value:
                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$/,
                      message:
                        '영문 대소문자, 숫자, 특수문자를 포함하여 8~20자로 입력해주세요. 특수문자는 !@#$%^&* 만 사용 가능합니다.',
                    },
                  })}
                  error={errors.password?.message}
                  placeholder="영문 대소문자, 숫자, 특수문자를 포함하여 8~20자로 입력해주세요. 특수문자는 !@#$%^&* 만 사용 가능합니다."
                />
                <HFinput
                  label="새 비밀번호 확인"
                  name="password2"
                  type="password"
                  register={register('password2', {
                    minLength: {
                      value: 6,
                      message: '6글자 이상 입력해주세요.',
                    },
                    required: '비밀번호를 입력해주세요',
                    pattern: {
                      value:
                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$/,
                      message:
                        '영문 대소문자, 숫자, 특수문자를 포함하여 8~20자로 입력해주세요. 특수문자는 !@#$%^&* 만 사용 가능합니다.',
                    },
                    validate: (value) => {
                      return (
                        value === password || '비밀번호가 일치하지 않습니다.'
                      );
                    },
                  })}
                  error={errors.password2?.message}
                  placeholder="영문 대소문자, 숫자, 특수문자를 포함하여 8~20자로 입력해주세요. 특수문자는 !@#$%^&* 만 사용 가능합니다."
                />

                <HFinput
                  label="영문명"
                  name="englishName"
                  className="uppercase"
                  register={register('englishName', {
                    required: '영문명을 입력해주세요',
                    pattern: {
                      value: /^[a-zA-Z ]+$/,
                      message: '영문 대문자만 입력해주세요',
                    },
                  })}
                  error={errors.englishName?.message}
                  placeholder="영문명을 입력해주세요"
                />

                <div>
                  <div>
                    <TextWinds type="title_h5">생년월일 및 성별</TextWinds>
                    <div className={`w-full flex gap-[10px] mt-[8px]`}>
                      <div className="relative flex-initial w-2/3">
                        <Input
                          type="date"
                          className={`!border-gray5 focus:!border-gray5 h-[50px] border border-solid border-gray6 rounded-[4px]`}
                          labelProps={{
                            className: 'before:content-none after:content-none',
                          }}
                          containerProps={{
                            className: 'min-w-full h-[50px]',
                          }}
                          {...register('birthday', {
                            required: '생년월일을 입력해주세요',
                          })} // react-hook-form 적용
                        />
                      </div>
                      <div className="flex-initial w-1/3">
                        <HFselect
                          register={register('gender', {
                            required: '성별을 선택해주세요',
                          })}
                          onChange={(value) => {
                            setValue('gender', value);
                            trigger('gender');
                          }}
                          placeholder="성별"
                          data={[
                            { value: 'MALE', label: '남' },
                            { value: 'FEMALE', label: '여' },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  {errors.birthday && (
                    <div className="text-red-500 text-xs">
                      {errors.birthday.message.toString()}
                    </div>
                  )}
                  {errors.gender && (
                    <div className="text-red-500 text-xs">
                      {errors.gender.message.toString()}
                    </div>
                  )}
                </div>
                {/* {errors.birthday && (
                  <ErrorMessage message={errors.birthday.message} />
                )} */}
                <div className="w-[326px] h-6 justify-start items-center gap-2 inline-flex">
                  <div className="w-6 h-6 relative">
                    <IconInfo />
                  </div>
                  <div className="text-blue-500 text-sm font-medium font-['Pretendard'] leading-snug">
                    만 14세 미만의 어린이는 보호자의 동의가 필요합니다.
                  </div>
                </div>
              </section>

              <section className="flex flex-col gap-2 py-5">
                <AgreementBox
                  agreementList={agreementList}
                  essentialChecked={setAgreement}
                />
              </section>

              {isMinor && (
                <section>
                  <GuardianInput
                    guadian={guardianInfo}
                    setGuadian={setGuadianInfo}
                  />
                </section>
              )}

              <section className="flex flex-col gap-[8px] mt-8">
                <div className="">
                  <Buttons
                    // onClick={handleNext}
                    // disabled={isLastStep || (activeStep === 1 && !requiredChecked)}
                    onClick={handleSubmit(onSubmit)}
                    type={'filled'}
                    color={'purple5'}
                  >
                    저장
                  </Buttons>
                </div>
                <div>
                  <Buttons
                    // onClick={handlePrev}
                    // disabled={isFirstStep}
                    type={'outlined'}
                    color={'purple5'}
                    onClick={() => logout()}
                  >
                    취소
                  </Buttons>
                </div>
              </section>
            </form>
          </FormProvider>
        </div>
      </div>
    </NormalPage>
  );
};

export default StudentFirstLogin;
